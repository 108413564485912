<template>
  <div :class="['scroll-container', { 'no-background': isAuthenticated }]">
    <!-- Logo at the top left -->
    <div class="logo-wrapper">
      <img src="../assets/logo1.png" alt="Logo" class="logo" />
    </div>

    <!-- Optional header content when authenticated -->
    <v-row v-if="isAuthenticated" justify="center" align="center">
      <v-col cols="12" md="7"></v-col>
      <v-col cols="12" md="4">
        <!-- Additional header content can go here -->
      </v-col>
    </v-row>

    <div v-if="isAuthenticated">
      <!-- Empty state if watchlist is empty -->
      <div v-if="isWatchlistEmpty" class="empty-state">
        <img src="../assets/logo1.png" alt="Empty Wishlist" class="empty-image" />
        <p class="white--text">Your watchlist is empty!</p>
        <p class="white--text">Go to a show or movie and add it to your list.</p>
      </div>
      <div v-else>
        <!-- Scroll arrows -->
        <v-icon large @click="scrollLeft" class="scroll-arrow left-arrow">mdi-chevron-left</v-icon>

        <!-- Profile row -->
        <v-row justify="center" align="center" class="profile-row">
          <v-avatar size="100" class="mr-3">
            <v-img :src="userProfile.profilePicture || defaultAvatar" />
          </v-avatar>
          <span class="white--text username">{{ userProfile.username }}</span>
          <v-btn icon @click="openProfilePicker">
            <v-icon color="#23ffe5">mdi-pencil</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center">
          <h2 class="white--text my-5">MY LIST</h2>
        </v-row>

        <!-- Watchlist cards using the same structure as search cards -->
        <div class="cards-row scroll-row my-2" ref="scrollRow">
          <div
            v-for="(movie, index) in paginatedWatchlist"
            :key="getUniqueKey(movie, index)"
            class="card-wrapper"
          >
            <div
              class="custom-hover-card"
              :class="{ hovered: activeHoverCard === getUniqueKey(movie, index) }"
              @mouseenter="setActiveHoverCard(getUniqueKey(movie, index))"
              @mouseleave="setActiveHoverCard(null)"
              @click="viewMovieInfo(movie.imdbId)"
            >
              <!-- Default state: show poster image -->
              <v-img
                v-if="activeHoverCard !== getUniqueKey(movie, index)"
                :src="getImageUrl(movie.imageUrl)"
                class="poster-image"
                cover
              ></v-img>

              <!-- Hover state: expanded overlay -->
              <div v-else class="hover-container">
                <div class="backdrop-wrapper">
                  <!-- Using the same image as poster; if a backdrop exists, replace accordingly -->
                  <v-img
                    :src="getImageUrl(movie.imageUrl)"
                    class="backdrop-image"
                    cover
                  ></v-img>
                  <div class="backdrop-gradient"></div>
                  <div class="backdrop-title">
                    <div class="white--text">{{ movie.title }}</div>
                  </div>
                </div>
                <div class="hover-description" @click.stop>
                  <!-- You can adjust meta info as needed -->
                  <p class="meta">Watchlist Item</p>
                  <p class="desc">{{ truncatedText(movie.description, 120) }}</p>
                  <div class="info-actions">
                    <v-btn class="watch-now-btn" @click.stop="viewMovieInfo(movie.imdbId)">
                      <v-icon left>mdi-play</v-icon>
                      View
                    </v-btn>
                    <v-btn icon class="plus-btn" @click.stop="removeFromWatchlist(movie.imdbId)">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-icon large @click="scrollRight" class="scroll-arrow right-arrow">mdi-chevron-right</v-icon>
      </div>
    </div>

    <!-- Login/Signup Card for non-authenticated users -->
    <v-row justify="center" align="center" v-if="!isAuthenticated">
      <v-col cols="12" md="7" lg="4">
        <v-card class="elevation-12 login-card">
          <v-card-title class="title-container">
            <span class="headline">{{ isSignup ? 'Signup' : 'Login' }}</span>
            <v-spacer></v-spacer>
            <span class="signup" @click="toggleForm">{{ isSignup ? 'Login' : 'Signup' }}</span>
          </v-card-title>
          <v-card-text class="login-card-text">
            <v-form @submit.prevent="isSignup ? signup() : login()">
              <v-text-field
                v-if="isSignup"
                v-model="username"
                label="Username"
                prepend-inner-icon="mdi-account"
                type="text"
                dark
                outlined
                hide-details
                class="login-input my-4"
                required
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Email Address"
                prepend-inner-icon="mdi-email"
                type="email"
                dark
                outlined
                hide-details
                class="login-input my-4"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="togglePasswordVisibility"
                dark
                outlined
                hide-details
                class="login-input"
                required
              ></v-text-field>
              <v-text-field
                v-if="isSignup"
                v-model="confirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm Password"
                prepend-inner-icon="mdi-lock"
                :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="toggleConfirmPasswordVisibility"
                dark
                outlined
                hide-details
                class="login-input my-4"
                required
              ></v-text-field>
              <v-checkbox
                v-if="!isSignup"
                v-model="rememberMe"
                dark
                label="Remember Me"
                class="remember-me"
              ></v-checkbox>
              <v-btn type="submit" color="#23ffe5" block>
                {{ isSignup ? 'SIGNUP' : 'LOGIN' }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="profilePickerDialog" max-width="600px">
      <v-card style="background: rgba(0, 0, 0, 0.3); backdrop-filter: blur(10px);">
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="icon in profilePictures" :key="icon.path" cols="4" md="3">
                <v-avatar size="90" class="pointer" @click="updateProfilePicture(icon.path)">
                  <v-img :src="icon.path" />
                </v-avatar>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut
} from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
  updateDoc
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { debounce } from 'lodash';

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

export default {
  name: 'MyList',
  data() {
    return {
      isSignup: false,
      email: '',
      password: '',
      confirmPassword: '',
      username: '',
      selectedProfilePicture: '',
      userProfile: {},
      profilePictures: [
        { path: 'https://i.pinimg.com/236x/e7/92/b1/e792b149ac7956b1cec92f1c5f8e428a.jpg' },
        { path: 'https://i.pinimg.com/236x/f8/87/2d/f8872dacef2cfc49244d9b2e7f036834.jpg' },
        { path: 'https://i.pinimg.com/236x/0c/84/fd/0c84fd50890bc6d1cabdd20015752198.jpg' },
        { path: 'https://i.pinimg.com/236x/73/1b/3e/731b3ed2f4f2ee33f1cdb5cf9754029d.jpg' },
        { path: 'https://i.pinimg.com/236x/6c/7c/4c/6c7c4ce87bde963267a60c38bd45cb89.jpg' },
        { path: 'https://i.pinimg.com/236x/11/3b/0d/113b0d871ca78c2833e8e22da64b66c4.jpg' },
        { path: 'https://i.pinimg.com/236x/5c/b5/b2/5cb5b2699e73cade1b97d577678392c1.jpg' },
        { path: 'https://i.pinimg.com/236x/f5/48/8c/f5488cc868f7fae5b3d5c5f25cf97ca3.jpg' },
        { path: 'https://i.pinimg.com/236x/8f/83/c4/8f83c49f67cdf8d7e612bd296beeb4d3.jpg' },
        { path: 'https://i.pinimg.com/236x/07/6c/d5/076cd5cf4fea350b01c5d43db9070faf.jpg' },
        { path: 'https://i.pinimg.com/236x/b7/f8/98/b7f898958ec7034e03975129c74aef21.jpg' },
        { path: 'https://i.pinimg.com/236x/19/ac/32/19ac32740303594e08fc67e9dbce6fa2.jpg' },
        { path: 'https://i.pinimg.com/474x/67/80/67/6780671534127a59bb2dc1203c62c186.jpg' },
        { path: 'https://i.pinimg.com/236x/59/07/1f/59071ff1d1c93cde5cde7d83b40551df.jpg' },
        { path: 'https://i.pinimg.com/236x/3a/1b/27/3a1b271e0a7cc6190f582d6b1d435e80.jpg' },
        { path: 'https://i.pinimg.com/236x/e9/0a/cb/e90acba9c9b6de1f20c6bf4c00630450.jpg' },
        { path: 'https://i.pinimg.com/236x/c0/76/2f/c0762ffa1ee99e2a220e4038030e98c4.jpg' },
        { path: 'https://i.pinimg.com/236x/d2/2d/97/d22d97508470451e1a6d196a5cddb510.jpg' },
        { path: 'https://i.pinimg.com/236x/73/82/7f/73827f3e3bf66f798716f6e4dce8ffa6.jpg' },
        { path: 'https://i.pinimg.com/236x/d8/d5/d1/d8d5d1f2ed67a61262abc6dab253ecf6.jpg' },
        { path: 'https://c4.wallpaperflare.com/wallpaper/8/230/576/flynn-tangled-wallpaper-preview.jpg' },
        { path: 'https://c4.wallpaperflare.com/wallpaper/365/692/48/disney-moana-4k-8k-wallpaper-preview.jpg' },
        { path: 'https://images8.alphacoders.com/130/thumb-1920-1307233.png' },
        { path: 'https://c4.wallpaperflare.com/wallpaper/293/879/668/monsters-university-disney-pixar-cartoon-movie-wallpaper-preview.jpg' }
      ],
      profileSearchQuery: '',
      rememberMe: false,
      isAuthenticated: false,
      watchlist: [],
      isLoading: false,
      showPassword: false,
      showConfirmPassword: false,
      profilePickerDialog: false,
      currentPage: 1,
      itemsPerPage: 10,
      defaultAvatar: 'https://i.pinimg.com/564x/83/bc/8b/83bc8b88cf6bc4b4e04d153a418cde62.jpg',
      activeHoverCard: null
    };
  },
  computed: {
    isWatchlistEmpty() {
      return this.watchlist.length === 0;
    },
    paginatedWatchlist() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.watchlist.slice(start, end);
    }
  },
  created() {
    onAuthStateChanged(auth, async (user) => {
      this.isAuthenticated = !!user;
      if (user) {
        localStorage.setItem('authId', user.uid);
        await this.fetchUserProfile();
        await this.fetchWatchlist();
      }
    });
  },
  methods: {
    getUniqueKey(item, index) {
      // Use the imdbId plus index to ensure uniqueness.
      return `${item.imdbId}-${index}`;
    },
    setActiveHoverCard(key) {
      this.activeHoverCard = key;
    },
    toggleForm() {
      this.isSignup = !this.isSignup;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    openProfilePicker() {
      this.profilePickerDialog = true;
    },
    async signup() {
      if (this.password !== this.confirmPassword) {
        alert('Passwords do not match!');
        return;
      }
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        const profileUrl = this.selectedProfilePicture || this.defaultAvatar;
        await setDoc(doc(db, "users", user.uid), {
          username: this.username,
          email: this.email,
          profilePicture: profileUrl,
          createdAt: new Date(),
        });
        localStorage.setItem('authId', user.uid);
        localStorage.setItem('log', '1');
        this.isAuthenticated = true;
        await this.fetchUserProfile();
        await this.fetchWatchlist();
      } catch (error) {
        console.error("Signup Error:", error);
        alert(`Signup failed: ${error.message}`);
      }
    },
    async login() {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const user = userCredential.user;
        localStorage.setItem('authId', user.uid);
        localStorage.setItem('log', '1');
        this.isAuthenticated = true;
        await this.fetchUserProfile();
        await this.fetchWatchlist();
      } catch (error) {
        console.error("Login Error:", error);
        alert(`Login failed: ${error.message}`);
      }
    },
    async logout() {
      try {
        await signOut(auth);
        localStorage.clear();
        this.isAuthenticated = false;
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    },
    async fetchUserProfile() {
      const authId = localStorage.getItem('authId');
      if (!authId) {
        console.error("No authId found in localStorage");
        return;
      }
      try {
        const docRef = doc(db, "users", authId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          this.userProfile = docSnap.data();
        } else {
          this.userProfile = {
            username: '',
            email: '',
            profilePicture: this.defaultAvatar,
          };
          await setDoc(docRef, this.userProfile);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        alert("Failed to fetch user profile. Please try again.");
      }
    },
    async updateProfilePicture(path) {
      const authId = localStorage.getItem('authId');
      if (!authId) {
        console.error("No authId found in localStorage");
        return;
      }
      try {
        const docRef = doc(db, "users", authId);
        await updateDoc(docRef, { profilePicture: path });
        this.userProfile.profilePicture = path;
        this.profilePickerDialog = false;
      } catch (error) {
        console.error("Error updating profile picture:", error);
        alert("Failed to update profile picture. Please try again.");
      }
    },
    async fetchWatchlist() {
      const authId = localStorage.getItem('authId');
      if (!authId) {
        console.error("No authId found in localStorage");
        return;
      }
      this.isLoading = true;
      try {
        const q = query(collection(db, "watchlist"), where("authId", "==", authId));
        const querySnapshot = await getDocs(q);
        this.watchlist = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      } catch (error) {
        console.error("Error fetching watchlist:", error);
        alert("Failed to fetch watchlist. Please try again.");
      } finally {
        this.isLoading = false;
      }
    },
    async removeFromWatchlist(imdbId) {
      const authId = localStorage.getItem('authId');
      try {
        const q = query(
          collection(db, "watchlist"),
          where("authId", "==", authId),
          where("imdbId", "==", imdbId)
        );
        const querySnapshot = await getDocs(q);
        for (const docSnap of querySnapshot.docs) {
          await deleteDoc(docSnap.ref);
        }
        await this.fetchWatchlist();
      } catch (error) {
        console.error("Error removing from watchlist:", error);
        alert("Failed to remove from watchlist. Please try again.");
      }
    },
    viewMovieInfo(imdbId) {
      localStorage.setItem('imdbId', imdbId);
      this.$router.push('/viewinfo');
    },
    getImageUrl(path) {
      return path ? path : 'default_image.jpg';
    },
    truncatedText(text, maxLength = 50) {
      return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
    scrollLeft: debounce(function () {
      this.$refs.scrollRow.scrollBy({ left: -300, behavior: 'smooth' });
    }, 100),
    scrollRight: debounce(function () {
      this.$refs.scrollRow.scrollBy({ left: 300, behavior: 'smooth' });
    }, 100)
  }
};
</script>

<style scoped>
/* Main container */
.scroll-container {
  position: relative;
  height: 100vh;
  z-index: 1 !important;
  padding: 48px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../assets/log.jpg') no-repeat center center;
  background-size: cover;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.no-background {
  background: none;
}

/* Logo styling */
.logo-wrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.logo {
  max-width: 150px;
  height: auto;
}

/* Empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}
.empty-image {
  max-width: 200px;
  margin-bottom: 20px;
}

/* Profile section */
.profile-row {
  margin-top: 100px;
}
.username {
  font-size: 1.2rem;
  margin: 0 10px;
}

/* Cards row – using flex (no v-row/v-col) */
.cards-row {
  display: flex;
  flex-wrap: wrap;
  overflow: visible;
  margin-top: 1rem;
}
.card-wrapper {
  position: relative;
  width: 160px;
  height: 240px;
  margin: 0.5rem;
  transition: all 0.3s ease;
}

/* Custom hover card (like search cards) */
.custom-hover-card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.custom-hover-card.hovered {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 350px;
  z-index: 999;
}

/* Poster image */
.poster-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hover container */
.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.backdrop-wrapper {
  position: relative;
  width: 100%;
  height: 60%;
}
.backdrop-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.backdrop-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 5;
}
.backdrop-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  padding: 5px;
}

/* Hover description */
.hover-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  box-sizing: border-box;
}
.hover-description .meta {
  font-size: 0.85rem;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}
.hover-description .desc {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}
.info-actions {
  display: flex;
  gap: 0.5rem;
}
.watch-now-btn {
  background-color: #0CDFE5 !important;
  width: 200px;
  text-transform: none;
  font-weight: bold;
}
.plus-btn {
  background-color: rgba(255,255,255,0.2) !important;
  color: #fff !important;
}

/* Scroll arrows */
.scroll-arrow {
  cursor: pointer;
  color: white;
  margin: 0 10px;
  z-index: 1;
  visibility: hidden;
}
.scroll-controls:hover .scroll-arrow {
  visibility: visible;
}

/* Fade-in animation */
.fade-in-left {
  animation: fade-in-left 1.5s cubic-bezier(.39,.575,.565,1.000) both;
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Login/Signup card styling */
.login-card {
  background-color: rgba(0,0,0,0.85);
  color: white;
  padding: 60px 40px;
  border-radius: 10px;
  margin: 150px auto 50px;
  max-width: 600px;
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.headline {
  font-size: 24px;
  font-weight: 600;
  color: white;
}
.signup {
  font-size: 18px;
  color: #888;
  cursor: pointer;
}
.login-input .v-input__control {
  background: #333;
  border-radius: 5px;
  color: white;
}
.login-input .v-label {
  color: #bbb !important;
}
.login-input .v-input__slot {
  color: white;
}
.remember-me {
  color: #bbb;
}
.pointer {
  cursor: pointer;
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .scroll-container {
    padding: 16px;
  }
  .logo {
    max-width: 100px;
  }
  .profile-row {
    margin-top: 50px;
  }
  .username {
    font-size: 1rem;
  }
  .login-card {
    margin-top: 30%;
    padding: 20px;
  }
}
</style>
