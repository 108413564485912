<template>
  <v-container fluid class="pa-10">
    <!-- =======================
         SEARCH + FILTER BUTTON
         ======================= -->
    <div class="d-flex align-center justify-space-between">
      <!-- Search area -->
      <div class="flex-grow-1 mr-2">
        <div class="autocomplete-container">
          <div class="ghost-text" v-if="ghostSuggestion">
            {{ ghostSuggestion }}
          </div>
          <v-text-field
            v-model="searchQuery"
            :loading="isLoading"
            dark
            solo
            width="100%"
            height="70px"
            append-icon="mdi-magnify"
            @input="onSearchInput"
            @keydown.enter.prevent="onEnter"
          >
            <template v-slot:label>
              <span style="font-size: 20px;">Type to search...</span>
            </template>
          </v-text-field>
        </div>
      </div>
      <!-- Filter icon -->
      <div class="filter-icon d-flex align-center">
        <v-icon
          @click="showFilterDialog"
          class="mt-5 mb-12"
          color="#23ffe5"
          large
        >
          mdi-filter
        </v-icon>
      </div>
    </div>

    <!-- ==================
         FILTER DIALOG
       ================== -->
    <v-dialog v-model="filterDialog" max-width="500px">
      <v-card class="custom-card" style="background-color:black;">
        <v-img
          src="../assets/logo1.png"
          alt="Logo"
          height="200px"
          contain
          class="image my-5"
        ></v-img>
        <v-card-text>
          <div class="d-flex justify-center">
            <span style="margin-top:-65px" class="headline white--text">Filter</span>
          </div>

          <!-- Genre Select -->
          <div class="d-flex justify-center my-3">
            <v-select
              dark
              solo
              dense
              v-model="selectedGenres"
              :items="genres"
              item-value="value"
              item-text="text"
              label="Genres"
              multiple
              class="custom-select"
            ></v-select>
          </div>

          <!-- Sort Select -->
          <div class="d-flex justify-center my-3">
            <v-select
              dark
              solo
              dense
              v-model="selectedSort"
              :items="sortOptions"
              item-value="value"
              item-text="text"
              label="Sort By"
              class="custom-select"
            ></v-select>
          </div>

          <!-- Year Select -->
          <div class="d-flex justify-center my-3">
            <v-select
              dark
              dense
              solo
              v-model="selectedYear"
              :items="yearOptions"
              item-value="value"
              item-text="text"
              label="Year"
              class="custom-select"
            ></v-select>
          </div>

          <!-- Type (Movies/TV) -->
          <div class="d-flex justify-center my-3">
            <v-radio-group v-model="selectedType" row>
              <v-radio dark label="Movies" value="movie"></v-radio>
              <v-radio dark label="TV Shows" value="tv"></v-radio>
            </v-radio-group>
          </div>

          <!-- Rating -->
          <div class="d-flex justify-center my-3">
            <v-rating
              dark
              v-model="selectedRating"
              label="Minimum Rating"
              color="#23ffe5"
              class="custom-rating"
            ></v-rating>
          </div>

          <!-- Apply Button -->
          <div class="d-flex justify-center mt-5">
            <v-btn
              color="#23ffe5"
              width="150px"
              class="custom-button my-5"
              @click="applyFilters"
            >
              Apply
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- =======================
         MEDIA ITEMS DISPLAY
         ======================= -->
    <div class="cards-row d-flex flex-wrap" style="overflow: visible;">
      <!-- Skeleton loaders if loading -->
      <template v-if="isLoading">
        <div
          v-for="n in 8"
          :key="n"
          class="loading-wrapper"
        >
          <v-skeleton-loader
            type="image"
            height="300px"
          ></v-skeleton-loader>
        </div>
      </template>

      <!-- Display media items -->
      <template v-else>
        <div
          v-for="(item, index) in paginatedResults"
          :key="getUniqueKey(item, index)"
          class="card-wrapper"
          v-if="item.poster_path && (item.type === 'movie' || item.type === 'tv')"
        >
          <!-- Use mouse events to track active hover card -->
          <div
            class="custom-hover-card"
            :class="{ hovered: activeHoverCard === getUniqueKey(item, index) }"
            @mouseenter="setActiveHoverCard(getUniqueKey(item, index))"
            @mouseleave="setActiveHoverCard(null)"
          >
            <!-- Default state: Poster image -->
            <v-img
              v-if="activeHoverCard !== getUniqueKey(item, index)"
              :src="getImageUrl(item.poster_path)"
              class="poster-image"
              cover
              @click="redirectToViewInfo(item)"
            ></v-img>

            <!-- Hover state: Backdrop with Fanart logo overlay and description -->
            <div
              v-else
              class="hover-container"
              @click="redirectToViewInfo(item)"
            >
              <div class="backdrop-wrapper">
                <v-img
                  :src="getBackdropUrl(item.backdrop_path)"
                  class="backdrop-image"
                  cover
                ></v-img>
                <!-- Gradient overlay -->
                <div class="backdrop-gradient"></div>
                <!-- Fanart logo overlay at bottom left -->
                <div class="backdrop-title">
                  <template v-if="item.logo">
                    <v-img
                      :src="item.logo"
                      class="logo-image"
                      contain
                      height="80px"
                    ></v-img>
                  </template>
                  <template v-else>
                    <div class="white--text"> {{ item.title }}</div>
                  </template>
                </div>
              </div>
              <div class="hover-description" @click.stop>
                <p class="meta">2025 • 6 Seasons • STAR • 13 Languages</p>
                <p class="desc">
                  {{ truncatedText(item.overview, 120) }}
                </p>
                <div class="info-actions">
                  <v-btn
                    class="watch-now-btn"
                    @click.stop="redirectToViewInfo(item)"
                  >
                    <v-icon left>mdi-play</v-icon>
                    Watch Now
                  </v-btn>
                  <v-btn
                    icon
                    class="plus-btn"
                    v-if="isUserLoggedIn"
                    @click.stop="addToWatchlist(item)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- =========================
         CUSTOM PAGINATION
         ========================= -->
    <div v-if="!isLoading && totalCustomPages > 1" class="text-center my-5">
      <div class="pagination">
        <v-btn dark icon @click="prevPage" :disabled="currentPage === 1">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          v-for="page in visiblePages"
          :key="page"
          :color="currentPage === page ? '#0CDFE5' : ''"
          class="mx-1"
          @click="goToPage(page)"
        >
          {{ page }}
        </v-btn>
        <v-btn dark icon @click="nextPage" :disabled="currentPage === totalCustomPages">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';
import Fuse from 'fuse.js';
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { VImg, VCard, VRating, VSkeletonLoader } from 'vuetify/lib';

const db = getFirestore(firebaseApp);
const API_KEY = process.env.VUE_APP_TMDB_API_KEY || 'a6a07bdb1ae12672ae2d301063d83c40';

export default {
  name: 'MediaDisplay',
  components: {
    VImg,
    VCard,
    VRating,
    VSkeletonLoader
  },
  data() {
    return {
      searchQuery: '',
      suggestions: [],
      allResults: [],
      fetchedAPIPages: new Set(),
      totalResults: 0,
      customPageSize: 24,
      currentPage: 1,
      totalCustomPages: 1,
      isLoading: false,
      filterDialog: false,
      selectedGenres: [],
      selectedType: 'movie',
      selectedYear: null,
      selectedLanguage: null,
      selectedRating: 4,
      selectedSort: null,
      genres: [
        { text: 'Action', value: 28 },
        { text: 'Comedy', value: 35 },
        { text: 'Drama', value: 18 },
        { text: 'Horror', value: 27 },
        { text: 'Romance', value: 10749 },
        { text: 'Science Fiction', value: 878 },
        { text: 'Documentary', value: 99 },
        { text: 'Fantasy', value: 14 },
        { text: 'Mystery', value: 9648 },
        { text: 'Animation', value: 16 },
        { text: 'Adventure', value: 12 },
        { text: 'Thriller', value: 53 },
        { text: 'Crime', value: 80 },
        { text: 'Family', value: 10751 },
        { text: 'War', value: 10752 },
        { text: 'Romantic Comedy', value: 10749 }
      ],
      sortOptions: [
        { text: 'Popularity', value: 'popularity.desc' },
        { text: 'Release Date', value: 'release_date.desc' },
        { text: 'Rating', value: 'vote_average.desc' }
      ],
      yearOptions: [],
      isUserLoggedIn: !!localStorage.getItem("authId"),
      genreName: '',
      activeHoverCard: null, // Tracks the active card in hover state
      fanartApiKey: '77f845990d4d0b6bfe8472508b664c3b'
    };
  },
  computed: {
    paginatedResults() {
      const start = (this.currentPage - 1) * this.customPageSize;
      return this.allResults.slice(start, start + this.customPageSize);
    },
    visiblePages() {
      const windowSize = 5;
      let start = Math.max(1, this.currentPage - Math.floor(windowSize / 2));
      let end = start + windowSize - 1;
      if (end > this.totalCustomPages) {
        end = this.totalCustomPages;
        start = Math.max(1, end - windowSize + 1);
      }
      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
    ghostSuggestion() {
      if (!this.searchQuery || !this.suggestions.length) return '';
      const options = {
        includeScore: true,
        threshold: 0.4,
      };
      const fuse = new Fuse(this.suggestions, options);
      const result = fuse.search(this.searchQuery);
      return (result.length > 0) ? result[0].item : '';
    }
  },
  mounted() {
    this.yearOptions = this.generateYearOptions(1900, new Date().getFullYear());
    this.initializeFilters();
    this.refreshMedia();
  },
  watch: {
    '$route.query.genre'(newGenre) {
      if (newGenre) {
        this.selectedGenres = [Number(newGenre)];
        this.genreName = this.getGenreNameById(Number(newGenre));
        this.refreshMedia();
      }
    },
    '$route.query.genreName'(newGenreName) {
      this.genreName = newGenreName;
    }
  },
  methods: {
    setActiveHoverCard(key) {
      this.activeHoverCard = key;
    },
    showFilterDialog() {
      this.filterDialog = true;
    },
    generateYearOptions(startYear, endYear) {
      const years = [];
      for (let year = endYear; year >= startYear; year--) {
        years.push({ text: year.toString(), value: year });
      }
      return years;
    },
    initializeFilters() {
      const storedGenre = localStorage.getItem('selectedGenre');
      if (storedGenre) {
        const { id, name } = JSON.parse(storedGenre);
        this.selectedGenres = [id];
        this.genreName = name;
      }
      const storedCategory = localStorage.getItem('selectedCategory');
      if (storedCategory && storedCategory.toLowerCase() === 'tvshows') {
        this.selectedType = 'tv';
      } else {
        this.selectedType = 'movie';
      }
    },
    onSearchInput() {
      if (this.searchQuery) {
        this.clearFilters();
      }
      this.debouncedFetchSuggestions();
      this.debouncedRefresh();
    },
    onEnter() {
      if (this.ghostSuggestion) {
        this.searchQuery = this.ghostSuggestion;
      }
      this.refreshMedia();
    },
    clearFilters() {
      this.selectedGenres = [];
      this.selectedSort = null;
      this.selectedYear = null;
      this.selectedRating = 4;
      this.selectedType = 'movie';
    },
    debouncedRefresh: debounce(function() {
      this.refreshMedia();
    }, 500),
    debouncedFetchSuggestions: debounce(function () {
      if (this.searchQuery.length < 3) {
        this.suggestions = [];
        return;
      }
      const url = `https://api.themoviedb.org/3/search/${this.selectedType || 'movie'}?api_key=${API_KEY}&query=${encodeURIComponent(this.searchQuery)}&page=1`;
      axios
        .get(url)
        .then(response => {
          this.suggestions = response.data.results.map(item => item.title || item.name);
        })
        .catch(error => {
          console.error("Error fetching suggestions:", error.response ? error.response.data : error.message);
          this.suggestions = [];
        });
    }, 500),
    refreshMedia() {
      this.currentPage = 1;
      this.allResults = [];
      this.fetchedAPIPages = new Set();
      this.totalResults = 0;
      this.totalCustomPages = 1;
      this.fetchAPIPage(1);
    },
    buildApiUrl(page) {
      let url = '';
      if (this.searchQuery) {
        url = `https://api.themoviedb.org/3/search/${this.selectedType || 'movie'}?api_key=${API_KEY}&query=${encodeURIComponent(this.searchQuery)}&page=${page}`;
      } else {
        const type = this.selectedType || 'movie';
        const yearParam = this.selectedYear ? `&${type === 'movie' ? 'primary_release_year' : 'first_air_date_year'}=${this.selectedYear}` : '';
        const languageParam = `&with_original_language=en`;
        const countryParam = type === 'tv' ? `&with_origin_country=US` : '';
        const ratingParam = this.selectedRating ? `&vote_average.gte=${this.selectedRating * 2}` : '';
        const sortParam = this.selectedSort ? `&sort_by=${this.selectedSort}` : '&sort_by=popularity.desc';
        const genresParam = this.selectedGenres.length ? `&with_genres=${this.selectedGenres.join(',')}` : '';
        url = `https://api.themoviedb.org/3/discover/${type}?api_key=${API_KEY}${genresParam}${yearParam}${languageParam}${countryParam}${ratingParam}${sortParam}&page=${page}`;
      }
      return url;
    },
    async fetchAPIPage(page) {
      if (this.fetchedAPIPages.has(page)) return;
      try {
        this.isLoading = true;
        const url = this.buildApiUrl(page);
        const response = await axios.get(url);
        const data = response.data;
        if (page === 1) {
          this.totalResults = data.total_results;
          const calculatedPages = Math.ceil(this.totalResults / this.customPageSize);
          // Hard limit: 250 pages
          this.totalCustomPages = Math.min(calculatedPages, 250);
        }
        // Map basic fields from TMDB results
        const pageResults = data.results
          .map(item => ({
            ...item,
            type: item.media_type || this.selectedType,
            title: item.title || item.name,
            poster_path: item.poster_path,
            backdrop_path: item.backdrop_path,
            release_date: item.release_date || item.first_air_date,
            rating: item.vote_average ? item.vote_average / 2 : 0
          }))
          .filter(item => item.type === 'movie' || item.type === 'tv');
        // Process each item to fetch additional details and logo via Fanart API
        const processedResults = await Promise.all(
          pageResults.map(item => this.processItem(item))
        );
        this.allResults = this.allResults.concat(processedResults);
        this.fetchedAPIPages.add(page);
      } catch (error) {
        console.error("Error fetching API page:", error.response ? error.response.data : error.message);
        this.$toast.error("Failed to fetch media. Please try again later.", {
          position: "bottom-right",
          timeout: 5000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async processItem(item) {
      try {
        if (item.type === 'movie') {
          const details = await this.fetchMovieDetails(item.id);
          item.imdb_id = details.imdb_id;
          item.trailer_url = details.trailer_url;
          item.certification = details.certification;
          const logo = await this.fetchMovieLogo(details.imdb_id);
          item.logo = logo;
        } else if (item.type === 'tv') {
          const details = await this.fetchTVShowDetails(item.id);
          item.imdb_id = details.external_ids ? details.external_ids.imdb_id : null;
          item.trailer_url = details.trailer_url;
          item.certification = details.certification;
          if (details.external_ids && details.external_ids.tvdb_id) {
            const logo = await this.fetchTVShowLogo(details.external_ids.tvdb_id);
            item.logo = logo;
          }
        }
      } catch (e) {
        console.error("Error processing item", item.id, e);
      }
      return item;
    },
    async fetchMovieDetails(movieId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/${movieId}?api_key=${API_KEY}&language=en-US&append_to_response=release_dates,videos,external_ids`
        );
        const data = response.data;
        const certification = this.getCertification(data.release_dates);
        const trailerObj = data.videos.results.find(
          (v) => v.type === 'Trailer' && v.site === 'YouTube'
        );
        return {
          ...data,
          trailer_url: trailerObj
            ? `https://www.youtube.com/embed/${trailerObj.key}`
            : null,
          certification,
        };
      } catch (error) {
        console.error(`Failed to fetch details for movie ID ${movieId}:`, error);
        return {};
      }
    },
    async fetchTVShowDetails(tvId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/tv/${tvId}?api_key=${API_KEY}&language=en-US&append_to_response=content_ratings,videos,external_ids`
        );
        const data = response.data;
        const certification = this.getTVCertification(data.content_ratings.results);
        const runtime = data.episode_run_time && data.episode_run_time.length
          ? data.episode_run_time[0]
          : 0;
        const trailerObj = data.videos.results.find(
          (v) => v.type === 'Trailer' && v.site === 'YouTube'
        );
        return {
          ...data,
          trailer_url: trailerObj
            ? `https://www.youtube.com/embed/${trailerObj.key}`
            : null,
          certification,
          runtime,
        };
      } catch (error) {
        console.error(`Failed to fetch details for TV show ID ${tvId}:`, error);
        return {};
      }
    },
    getCertification(releaseDates) {
      const usRelease = releaseDates.results.find(
        (rel) => rel.iso_3166_1 === 'US'
      );
      if (usRelease && usRelease.release_dates.length > 0) {
        return usRelease.release_dates[0].certification || 'NR';
      }
      return 'NR';
    },
    getTVCertification(contentRatings) {
      const usRating = contentRatings.find((c) => c.iso_3166_1 === 'US');
      return usRating ? usRating.rating : 'NR';
    },
    async fetchMovieLogo(imdbId) {
      if (!imdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/movies/${imdbId}?api_key=${this.fanartApiKey}`
        );
        const logos = response.data.hdmovielogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.error('Failed to fetch movie logo:', error);
        return null;
      }
    },
    async fetchTVShowLogo(tvdbId) {
      if (!tvdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/tv/${tvdbId}?api_key=${this.fanartApiKey}`
        );
        const logos = response.data.hdtvlogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.error('Failed to fetch TV show logo:', error);
        return null;
      }
    },
    async onPageChange(newPage) {
      const requiredAPIpages = Math.ceil((newPage * this.customPageSize) / 20);
      const fetchPromises = [];
      for (let p = 1; p <= requiredAPIpages; p++) {
        if (!this.fetchedAPIPages.has(p)) {
          fetchPromises.push(this.fetchAPIPage(p));
        }
      }
      if (fetchPromises.length > 0) {
        await Promise.all(fetchPromises);
      }
      this.currentPage = Math.min(newPage, this.totalCustomPages);
    },
    goToPage(page) {
      this.currentPage = page;
      this.onPageChange(page);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.onPageChange(this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalCustomPages) {
        this.currentPage++;
        this.onPageChange(this.currentPage);
      }
    },
    applyFilters() {
      this.filterDialog = false;
      localStorage.setItem('selectedCategory', this.selectedType === 'tv' ? 'tvShows' : 'movies');
      this.refreshMedia();
    },
    getImageUrl(path) {
      return path ? `https://image.tmdb.org/t/p/w500${path}` : 'default_image.jpg';
    },
    getBackdropUrl(path) {
      return path ? `https://image.tmdb.org/t/p/w1280${path}` : 'default_image.jpg';
    },
    async redirectToViewInfo(item) {
      this.isLoading = true;
      try {
        const detailsUrl = `https://api.themoviedb.org/3/${item.type}/${item.id}?api_key=${API_KEY}&append_to_response=external_ids`;
        const response = await axios.get(detailsUrl);
        let imdbId;
        if (item.type === 'movie') {
          imdbId = response.data.imdb_id;
        } else if (item.type === 'tv') {
          imdbId = response.data.external_ids ? response.data.external_ids.imdb_id : null;
        }
        if (imdbId) {
          localStorage.setItem('imdbId', imdbId);
          this.$router.push(`/viewInfo?id=${item.id}&type=${item.type}`);
        } else {
          console.error("IMDb ID not found for the item.");
        }
      } catch (error) {
        console.error("Error fetching item details:", error.response ? error.response.data : error.message);
      } finally {
        this.isLoading = false;
      }
    },
    truncatedText(text, maxLength = 120) {
      return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    },
   async addToWatchlist(item) {
  const authId = localStorage.getItem("authId");
  if (!authId) {
    console.error("No authId found in localStorage");
    this.$toast.warning("Please log in to add items to your watchlist.", {
      position: "bottom-right",
      timeout: 5000,
    });
    return;
  }
  try {
    let imdbId;
    let detailsUrl;
    if (item.type === 'tv') {
      detailsUrl = `https://api.themoviedb.org/3/tv/${item.id}?api_key=${API_KEY}&append_to_response=external_ids`;
      const response = await axios.get(detailsUrl);
      imdbId = response.data.external_ids ? response.data.external_ids.imdb_id : null;
    } else {
      detailsUrl = `https://api.themoviedb.org/3/movie/${item.id}?api_key=${API_KEY}`;
      const response = await axios.get(detailsUrl);
      imdbId = response.data.imdb_id;
    }
    if (!imdbId) {
      console.error("IMDb ID not found for the item.");
      this.$toast.error("Unable to add this item to watchlist.", {
        position: "bottom-right",
        timeout: 5000,
      });
      return;
    }
    const watchlistRef = collection(db, "watchlist");
    const q = query(
      watchlistRef,
      where("authId", "==", authId),
      where("imdbId", "==", imdbId)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      await addDoc(watchlistRef, {
        authId: authId,
        imdbId: imdbId,
        title: item.title,
        rating: item.vote_average || '',
        imageUrl: this.getImageUrl(item.poster_path),
        release_date: item.release_date,
        description: item.overview,
        type: item.type,
        addedAt: new Date()
      });
      this.$toast.success("Added to watchlist successfully", {
        position: "bottom-right",
        timeout: 5000,
      });
      console.log("Item added to watchlist successfully!");
    } else {
      this.$toast.warning("Already added to watchlist", {
        position: "bottom-right",
        timeout: 5000,
      });
      console.log("Item is already in the watchlist.");
    }
  } catch (error) {
    console.error("Error adding item to watchlist:", error.response ? error.response.data : error.message);
    this.$toast.error("Failed to add item to watchlist. Please try again later.", {
      position: "bottom-right",
      timeout: 5000,
    });
  }
}
,
    getUniqueKey(item, index) {
      return `${item.id}-${item.media_type || this.selectedType}-${index}`;
    },
    getGenreNameById(id) {
      const genre = this.genres.find(g => g.value === id);
      return genre ? genre.text : '';
    }
  }
};
</script>

<style scoped>
/* =========================
   GHOST TEXT FOR SEARCH
   ========================= */
.autocomplete-container {
  position: relative;
}
.ghost-text {
  position: absolute;
  top: 0;
  left: 14px;
  height: 100%;
  line-height: 70px;
  color: lightgrey;
  pointer-events: none;
  font-size: 20px;
  font-family: inherit;
  white-space: nowrap;
  overflow: hidden;
}

/* ===================
   FILTER DIALOG ETC.
   =================== */
.custom-card {
  background-color: #111 !important;
}
.custom-select {
  width: 80%;
}
.custom-button {
  color: black;
  font-weight: bold;
}
.custom-rating {
  margin-top: 10px;
}

/* 
  We'll define a custom width for the 
  "cards" to emulate columns. Adjust as needed. 
*/
.cards-row {
  overflow: visible !important;
  margin-top: 1rem;
}

.loading-wrapper {
  width: 160px;
  height: 240px;
  margin: 0.5rem;
}

/* 
  This will cause 6 items per row at large screens,
  fewer on smaller screens. Adjust breakpoints as needed.
*/
.card-wrapper {
  position: relative;
  overflow: visible !important;
  width: 160px; /* fixed width for each card */
  margin: 0.5rem;
}

/* ==============================================
   HOVER SWAP: POSTER -> BACKDROP WITH DESCRIPTION
   ============================================== */
.custom-hover-card {
  position: relative; /* normal flow initially */
  width: 100%;
  height: 240px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 
  On hover, absolutely position it 
  so it doesn't push the row height.
*/
.custom-hover-card.hovered {
  position: absolute;
  top: 0;    
  left: 0;   
  width: 300px;
  height: 350px;
  z-index: 999;
}

/* Default state: Poster image */
.poster-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Hover state container */
.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Backdrop wrapper to control positioning */
.backdrop-wrapper {
  position: relative;
  width: 100%;
  height: 60%; /* height of the backdrop area */
}

/* Backdrop image occupies full wrapper */
.backdrop-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Gradient overlay: from opaque black at bottom to transparent at top */
.backdrop-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 5;
}

/* Fanart logo overlay at bottom left of backdrop */
.backdrop-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  padding: 5px;
}
.backdrop-title .logo-image {
  max-width: 200px;
  max-height: 80px;
}

/* Description area below backdrop */
.hover-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  box-sizing: border-box;
}
.hover-description .meta {
  font-size: 0.85rem;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}
.hover-description .desc {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}
.info-actions {
  display: flex;
  gap: 0.5rem;
}
.watch-now-btn {
  background-color: #0CDFE5 !important;
width:200px;
  text-transform: none;
  font-weight: bold;
}
.plus-btn {
  background-color: rgba(255,255,255,0.2) !important;
  color: #fff !important;
}

/* ====================
   PAGINATION & ANIMATION
   ==================== */
.pagination {
  display: inline-flex;
  align-items: center;
}
.fade-in-left {
  animation: fade-in-left 1.5s cubic-bezier(.39,.575,.565,1.000) both;
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
