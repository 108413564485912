<template>
  <div style="overflow-x:hidden">
    <!-- Alerts for success / error messages -->
    <v-row justify="center">
      <v-alert
        v-model="showSuccessAlert"
        type="success"
        style="position: absolute; z-index: 99; margin-top: 20px;"
        dismissible
        width="500px"
        transition="scale-transition"
      >
        {{ successMessage }}
      </v-alert>
      <v-alert
        v-model="showErrorAlert"
        type="error"
        style="position: absolute; z-index: 99; margin-top: 20px;"
        dismissible
        width="500px"
        transition="scale-transition"
      >
        {{ errorMessage }}
      </v-alert>
    </v-row>

    <!-- Banner / Background -->
    <v-row
      class="movie-banner"
      :style="!isMediaLoading && movieBackdropUrl ? { backgroundImage: 'url(' + movieBackdropUrl + ')' } : {}"
      justify="center"
      align="center"
      no-gutters
    >
      <v-col cols="12">
        <!-- Overlay -->
        <div class="overlay">
          <v-row justify="center" align="center" no-gutters class="close-together-row">
            <!-- Poster Column -->
            <v-col cols="12" md="4" class="d-flex align-center justify-center poster-col">
              <template v-if="!isMediaLoading">
                <v-img
                  :src="moviePosterUrl"
                  alt="Media Poster"
                  height="450"
                  width="auto"
                  class="rounded-contain"
                  contain
                ></v-img>
              </template>
              <template v-else>
                <v-skeleton-loader type="image" height="450" width="300"></v-skeleton-loader>
              </template>
            </v-col>

            <!-- Info Column -->
            <v-col cols="12" md="4" class="info-col" style="position: relative; z-index: 1;">
              <div v-if="!isMediaLoading">
                <div v-if="movieLogo" class="logo-wrapper">
                  <v-img
                    :src="movieLogo"
                    alt="Media Logo"
                    height="180px"
                    width="auto"
                    contain
                  ></v-img>
                </div>
                <div v-else class="movie-title animate-title">
                  {{ movieTitle }}
                </div>
              </div>
              <v-skeleton-loader v-else type="heading" width="80%" class="mb-3"></v-skeleton-loader>

              <div class="rating-reviews">
                <template v-if="!isMediaLoading">
                  {{ movieRating }}
                  <span class="reviews-count">{{ reviewsCount }} Reviews</span>
                </template>
                <template v-else>
                  <v-skeleton-loader type="text" width="60%"></v-skeleton-loader>
                </template>
              </div>

              <div v-if="!isMediaLoading && movieGenres.length" class="genres-row">
                <span v-for="(genre, index) in movieGenres" :key="index" class="genre-pill">
                  {{ genre }}<span v-if="index < movieGenres.length - 1">|</span>
                </span>
              </div>
              <div v-else-if="isMediaLoading">
                <v-skeleton-loader type="text" width="80%" class="mb-2"></v-skeleton-loader>
                <v-skeleton-loader type="text" width="50%"></v-skeleton-loader>
              </div>

              <div class="description-container">
                <template v-if="!isMediaLoading">
                  <p class="movie-description animate-description">
                    {{ movieDescription }}
                  </p>
                </template>
                <template v-else>
                  <v-skeleton-loader type="text" width="100%" class="mb-2"></v-skeleton-loader>
                  <v-skeleton-loader type="text" width="90%" class="mb-2"></v-skeleton-loader>
                  <v-skeleton-loader type="text" width="80%"></v-skeleton-loader>
                </template>
              </div>

              <!-- Action Buttons -->
              <div class="btn-container">
                <template v-if="!isMediaLoading">
                  <v-btn rounded width="10px" height="46px" color="#23ffe5" @click="playContent">
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                  <v-btn height="46px" class="mx-2" outlined rounded dark @click="fetchTrailer">
                    <v-icon>mdi-movie-play</v-icon>
                  </v-btn>
                  <v-btn v-if="isAuthenticated" rounded outlined height="46px" dark @click="addToWatchlist">
                    Add To Watchlist
                  </v-btn>
                  <v-btn
                    v-if="!isTvShow"
                    class="mx-2"
                    height="46px"
                    outlined
                    dark
                    rounded
                    @click="fetchMagnetLink"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="isTvShow"
                    class="mx-2"
                    height="46px"
                    outlined
                    rounded
                    dark
                    @click="openDownloadDialog"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <template v-else>
                  <v-skeleton-loader type="button" width="90px" class="mx-1"></v-skeleton-loader>
                  <v-skeleton-loader type="button" width="90px" class="mx-1"></v-skeleton-loader>
                  <v-skeleton-loader type="button" width="130px" class="mx-1"></v-skeleton-loader>
                </template>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- Tabbed interface for TV shows -->
    <v-tabs v-if="isTvShow" v-model="selectedTab" background-color="black" dark centered>
      <v-tab>Seasons</v-tab>
      <v-tab>Cast</v-tab>
    </v-tabs>
    <v-tabs-items style="background-color: black" v-model="selectedTab" v-if="isTvShow">
      <v-tab-item>
        <!-- Season Selector -->
        <v-row v-if="isTvShow" justify="center" class="my-7">
          <v-col cols="3"></v-col>
          <v-col class="episode-dropdown">
            <template v-if="!isMediaLoading">
              <v-select
                outlined
                dark
                class="mx-2"
                v-model="selectedSeason"
                :items="seasons"
                label="Season"
                @change="fetchEpisodes"
                dense
              ></v-select>
            </template>
            <template v-else>
              <v-skeleton-loader type="input" width="200"></v-skeleton-loader>
            </template>
          </v-col>
          <v-col cols="3"></v-col>
        </v-row>
        <!-- Player Selector for TV Shows -->
        <v-row justify="center" class="mb-12">
          <v-col cols="12" sm="6" md="4">
            <v-select
              v-if="!isAnime"
              v-model="selectedPlayer"
              :items="availablePlayers"
              label="Select Player"
              dense
              dark
              outlined
              item-text="name"
              item-value="value"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#23ffe5">mdi-play-circle</v-icon>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <!-- Episodes List -->
        <v-row v-if="isTvShow" justify="center" class="my-7">
          <v-col cols="12" sm="10">
            <div class="scroll-container" style="background-color: black;">
              <v-list dark style="background-color: black; overflow: hidden;">
                <v-row>
                  <template v-if="!isEpisodesLoading && episodes.length">
                    <v-col cols="12" sm="6" v-for="episode in episodes" :key="episode.id">
                      <v-list-item @click="selectEpisode(episode); playContent()" class="episode-item">
                        <v-list-item-avatar size="120">
                          <v-img :src="`https://image.tmdb.org/t/p/w300${episode.still_path}`"></v-img>
                          <div class="play-button-overlay">
                            <v-icon>mdi-play-circle</v-icon>
                          </div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 1.2rem; font-weight: bold;">
                            {{ episode.episode_number }}. {{ episode.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 0.95rem;">
                            {{ episode.overview }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <span style="font-size: 0.95rem; font-weight: 600;">{{ episode.runtime }}m</span>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="12" sm="6" v-for="n in 4" :key="n">
                      <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
                      <v-skeleton-loader type="text"></v-skeleton-loader>
                      <v-skeleton-loader type="text" width="60%"></v-skeleton-loader>
                    </v-col>
                  </template>
                </v-row>
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item>
        <template v-if="!isMediaLoading">
          <Cast />
        </template>
        <template v-else>
          <v-skeleton-loader type="avatar" class="mb-2" v-for="n in 4" :key="n" />
        </template>
      </v-tab-item>
    </v-tabs-items>

    <!-- Player Selector for Movies -->
    <v-row v-if="!isTvShow" justify="center" class="mb-12">
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-if="!isAnime"
          class="player-select"
          v-model="selectedPlayer"
          :items="availablePlayers"
          label="Select Player"
          dense
          dark
          outlined
          item-text="name"
          item-value="value"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#23ffe5">mdi-play-circle</v-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <!-- For movies, show cast -->
    <div v-if="!isTvShow">
      <template v-if="!isMediaLoading">
        <Cast />
      </template>
      <template v-else>
        <v-skeleton-loader type="avatar" class="mb-2" v-for="n in 4" :key="n" />
      </template>
    </div>

    <!-- "More Like This" section -->
    <template v-if="!isMediaLoading">
      <LikeThis />
    </template>
    <template v-else>
      <v-skeleton-loader type="image" height="200" class="mb-2"></v-skeleton-loader>
    </template>

    <!-- Play Content Dialog -->
    <v-dialog style="overflow:hidden;" v-model="showVideoDialog" width="800">
      <v-card style="overflow:hidden;" color="black" class="dialog-content">
        <v-row justify="end" class="pa-3">
          <v-btn text style="position: absolute" dark @click="closeVideoDialog">
            <v-icon style="font-size: 30px">mdi-close</v-icon>
          </v-btn>
        </v-row>
        <div v-if="embedUrl" class="scroll-container" style="overflow-y: hidden; overflow-x: hidden;">
          <iframe
            ref="videoIframe"
            :src="embedUrl"
            width="100%"
            height="450"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            referrerpolicy="no-referrer"
            @load="handleIframeLoad"
            @error="handleIframeError"
          ></iframe>
        </div>
        <v-row v-if="isTvShow && embedUrl">
          <v-col>
            <v-btn color="#23ffe5" width="100%" @click="playNextEpisode">
              Next Episode
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Trailer Dialog -->
    <v-dialog v-model="showDialog" style="overflow-x: hidden;" width="800">
      <v-card color="black">
        <v-row justify="end" class="pa-3">
          <v-btn color="black" style="position: absolute" dark @click="showDialog = false">
            <v-icon style="font-size: 30px">mdi-close</v-icon>
          </v-btn>
        </v-row>
        <iframe
          v-if="trailerKey"
          width="100%"
          height="450"
          :src="'https://www.youtube.com/embed/' + trailerKey"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </v-card>
    </v-dialog>

    <!-- Download Season Dialog (for TV shows) -->
    <v-dialog v-model="showDownloadDialog" width="400" height="800px">
      <v-card class="pa-12" style="overflow-x:hidden;overflow-y: hidden;" color="black">
        <v-card-title style="color:white">Select Season to Download</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedSeasonToDownload"
            :items="seasons"
            filled
            dark
            label="Season"
            outlined
            dense
          ></v-select>
        </v-card-text>
        <v-row justify="center">
          <v-btn color="#23ffe5" @click="downloadSeason(selectedSeasonToDownload)">
            Download
          </v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getFirestore, collection, addDoc, query, where, getDocs, updateDoc, doc } from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import axios from "axios";
import debounce from "lodash/debounce";
import Details from "../components/Details.vue";
import Cast from "../components/Cast.vue";
import LikeThis from "../components/LikeThis.vue";

const db = getFirestore(firebaseApp);

export default {
  name: "MediaPlayer",
  data() {
    return {
      movieTitle: "",
      movieRating: "",
      movieBackdropUrl: "",
      moviePosterUrl: "",
      movieRuntime: "",
      movieDescription: "",
      reviewsCount: "",
      movieGenres: [],
      isTvShow: false,
      seasons: [],
      selectedSeason: 1,
      selectedEpisode: 1,
      episodes: [],
      initialFetchDone: false,
      episodeFetchRetries: 0,
      maxEpisodeFetchRetries: 3,
      showDialog: false,
      trailerKey: null,
      showVideoDialog: false,
      embedUrl: "",
      isDubbed: true,
      isMuted: false,
      autoPlay: true,
      autoSkipIntro: true,
      showSuccessAlert: false,
      successMessage: "",
      showErrorAlert: false,
      errorMessage: "",
      selectedPlayer: "vidlink",
      availablePlayers: [
        { name: "Vidlink", value: "vidlink" },
        { name: "Vidsrc", value: "vidsrc" }
      ],
      isMovie: false,
      fanartMovieBaseUrl: "https://webservice.fanart.tv/v3/movies",
      fanartTvBaseUrl: "https://webservice.fanart.tv/v3/tv",
      fanartApiKey: "77f845990d4d0b6bfe8472508b664c3b",
      movieLogo: null,
      mediaType: "",
      originalLanguage: "",
      tmdbId: "",
      storedMalId: "",
      iframeLoaded: false,
      isMediaLoading: true,
      isEpisodesLoading: true,
      storedImdbId: "",
      storedAuthId: "",
      debouncedUpdateEmbedUrl: null,
      selectedSeasonToDownload: null,
      selectedTab: 0,
    };
  },
  components: { Details, Cast, LikeThis },
  computed: {
    isAnime() {
      return this.originalLanguage === "ja";
    },
    episodeOptions() {
      return this.episodes.map(episode => episode.episode_number);
    },
    isAuthenticated() {
      return !!this.storedAuthId;
    },
  },
  mounted() {
    this.storedImdbId = localStorage.getItem("imdbId") || "";
    this.storedAuthId = localStorage.getItem("authId") || "";
    this.storedMalId = localStorage.getItem("malid") || this.storedImdbId;
    const storedPlayer = localStorage.getItem("selectedPlayer");
    if (storedPlayer) this.selectedPlayer = storedPlayer;
    this.debouncedUpdateEmbedUrl = debounce(this._updateEmbedUrl, 300);
    window.addEventListener("message", this.handlePlayerEvent);
    if (this.storedImdbId) {
      this.fetchMovieDetails(this.storedImdbId).then(() => {
        this.isMediaLoading = false;
      });
      this.fetchSeasonsAndEpisodes(this.storedImdbId).then(() => {
        if (this.episodes.length === 0 && !this.initialFetchDone) {
          this.initialFetchDone = true;
          this.fetchEpisodes().then(() => {
            this.isEpisodesLoading = false;
          });
        }
      });
      this.checkIfTvShow(this.storedImdbId);
      if (this.storedAuthId) this.fetchContinueWatching(this.storedAuthId, this.storedImdbId);
    } else {
      console.error("No IMDb/MAL ID found in localStorage");
    }
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handlePlayerEvent);
  },
  methods: {
    async checkIfTvShow(id) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const isTvShow = response.data.tv_results.length > 0;
        this.isTvShow = isTvShow;
        this.mediaType = isTvShow ? "tv" : "movie";
      } catch (error) {
        console.error("Failed to check if media is TV show:", error);
      }
    },
    async fetchMovieDetails(id) {
      try {
        const findResponse = await axios.get(
          `https://api.themoviedb.org/3/find/${id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        let media = null;
        let isTv = false;
        if (findResponse.data.tv_results && findResponse.data.tv_results.length > 0) {
          media = findResponse.data.tv_results[0];
          isTv = true;
        } else if (findResponse.data.movie_results && findResponse.data.movie_results.length > 0) {
          media = findResponse.data.movie_results[0];
        }
        if (!media) {
          console.error("No media found for this ID");
          return;
        }
        this.tmdbId = media.id;
        let tvdbId = null;
        if (isTv) {
          const tvDetailsResponse = await axios.get(
            `https://api.themoviedb.org/3/tv/${media.id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&append_to_response=external_ids`
          );
          tvdbId = tvDetailsResponse.data.external_ids.tvdb_id;
          if (tvDetailsResponse.data.genres) {
            this.movieGenres = tvDetailsResponse.data.genres.map(g => g.name);
          }
        } else {
          const movieDetailsResponse = await axios.get(
            `https://api.themoviedb.org/3/movie/${media.id}?api_key=a6a07bdb1ae12672ae2d301063d83c40`
          );
          if (movieDetailsResponse.data.genres) {
            this.movieGenres = movieDetailsResponse.data.genres.map(g => g.name);
          }
        }
        const logoUrl = await this.fetchMediaLogo(isTv, id, tvdbId);
        this.movieLogo = logoUrl;
        this.isTvShow = isTv;
        this.movieTitle = media.title || media.name;
        this.movieRating = media.vote_average;
        this.movieBackdropUrl = media.backdrop_path
          ? `https://image.tmdb.org/t/p/original${media.backdrop_path}`
          : "";
        this.moviePosterUrl = media.poster_path
          ? `https://image.tmdb.org/t/p/original${media.poster_path}`
          : "";
        this.movieDescription = media.overview;
        this.reviewsCount = media.vote_count || "";
        this.originalLanguage = media.original_language || "";
        if (this.isAnime) this.selectedPlayer = "vidsrc";
        if (!isTv) {
          const movieDetailsResponse = await axios.get(
            `https://api.themoviedb.org/3/movie/${media.id}?api_key=a6a07bdb1ae12672ae2d301063d83c40`
          );
          this.movieRuntime = movieDetailsResponse.data.runtime
            ? `${movieDetailsResponse.data.runtime} min`
            : "";
        }
        this.scrollToTop();
      } catch (error) {
        console.error("Failed to fetch media details:", error);
      }
    },
    async fetchMediaLogo(isTv, id, tvdbId) {
      try {
        if (isTv) {
          if (!tvdbId) return null;
          const response = await axios.get(`${this.fanartTvBaseUrl}/${tvdbId}?api_key=${this.fanartApiKey}`);
          const logos = response.data.hdtvlogo || [];
          return logos.length > 0 ? logos[0].url : null;
        } else {
          const response = await axios.get(`${this.fanartMovieBaseUrl}/${id}?api_key=${this.fanartApiKey}`);
          const logos = response.data.hdmovielogo || [];
          return logos.length > 0 ? logos[0].url : null;
        }
      } catch (error) {
        console.error("Failed to fetch media logo:", error);
        return null;
      }
    },
    async fetchSeasonsAndEpisodes(id) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const tvShow = response.data.tv_results[0];
        if (tvShow) {
          const tvShowId = tvShow.id;
          const seasonsResponse = await axios.get(
            `https://api.themoviedb.org/3/tv/${tvShowId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
          );
          const tvShowData = { tvShow, seasons: seasonsResponse.data.seasons };
          localStorage.setItem("tvShowData", JSON.stringify(tvShowData));
          this.seasons = tvShowData.seasons.map(season => season.season_number);
          this.scrollToTop();
        } else {
          console.error("TV show details not found");
        }
      } catch (error) {
        console.error("Failed to fetch TV show details:", error);
      }
    },
    async fetchEpisodes() {
      if (!this.storedImdbId) {
        console.error("No IMDb ID found in cached value");
        return;
      }
      const tvShowData = JSON.parse(localStorage.getItem("tvShowData"));
      if (!tvShowData) {
        console.error("TV show data not found in localStorage");
        return;
      }
      const selectedSeasonData = tvShowData.seasons.find(season => season.season_number === this.selectedSeason);
      if (!selectedSeasonData) {
        console.error("Season data not found");
        return;
      }
      const seasonNumber = selectedSeasonData.season_number;
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/tv/${tvShowData.tvShow.id}/season/${seasonNumber}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
        );
        this.episodes = response.data.episodes;
        if (!this.initialFetchDone && this.episodes.length === 0) {
          this.episodeFetchRetries++;
          if (this.episodeFetchRetries < this.maxEpisodeFetchRetries) {
            this.fetchEpisodes();
          } else {
            this.initialFetchDone = true;
          }
        }
        this.scrollToTop();
      } catch (error) {
        console.error("Failed to fetch episodes:", error);
      }
    },
    constructAnimeEmbedUrlFromVidsrc() {
      const id = this.constructId();
      const episode = this.selectedEpisode || 1;
      const type = this.isDubbed ? "dub" : "sub";
      const autoPlayParam = this.autoPlay ? "1" : "0";
      const autoSkipIntroParam = this.autoSkipIntro ? "1" : "0";
      return `https://vidsrc.cc/v2/embed/anime/${id}/${episode}/${type}?autoPlay=${autoPlayParam}&autoSkipIntro=${autoSkipIntroParam}`;
    },
    constructId() {
      return this.storedMalId || this.storedImdbId;
    },
    _updateEmbedUrl() {
      this.iframeLoaded = false;
      if (this.selectedPlayer === "vidsrc") {
        if (this.isAnime) {
          this.embedUrl = this.constructAnimeEmbedUrlFromVidsrc();
        } else if (this.mediaType === "movie") {
          this.embedUrl = `https://vidsrc.cc/v2/embed/movie/${this.tmdbId}`;
        } else if (this.mediaType === "tv") {
          this.embedUrl = `https://vidsrc.cc/v2/embed/tv/${this.tmdbId}/${this.selectedSeason}/${this.selectedEpisode}`;
        }
      } else {
        if (this.isAnime) {
          this.embedUrl = `https://vidlink.pro/anime/${this.storedMalId}/${this.selectedEpisode}/${this.isDubbed ? "dub" : "sub"}?fallback=true`;
        } else if (this.mediaType === "movie") {
          this.embedUrl = `https://vidlink.pro/movie/${this.tmdbId}?primaryColor=24bac2&secondaryColor=000000&iconColor=ffffff&icons=default&player=jw&title=true&poster=true&autoplay=true&nextbutton=false`;
        } else if (this.mediaType === "tv") {
          this.embedUrl = `https://vidlink.pro/tv/${this.tmdbId}/${this.selectedSeason}/${this.selectedEpisode}?primaryColor=24bac2&secondaryColor=000000&iconColor=ffffff&icons=default&player=jw&title=true&poster=true&autoplay=true&nextbutton=false`;
        }
      }
    },
    updateEmbedUrl() {
      this.debouncedUpdateEmbedUrl();
    },
    handleIframeLoad() {
      this.iframeLoaded = true;
    },
    handleIframeError() {
      console.warn("Iframe error triggered. The embed may be blocked or invalid.");
      this.errorMessage = "The video player failed to load. Please try a different player or refresh the page.";
      this.showErrorAlert = true;
    },
    selectEpisode(episode) {
      this.selectedEpisode = episode.episode_number;
      this.debouncedUpdateEmbedUrl();
      this.showVideoDialog = true;
    },
    playNextEpisode() {
      const currentIndex = this.episodes.findIndex(ep => ep.episode_number === this.selectedEpisode);
      if (currentIndex >= 0 && currentIndex < this.episodes.length - 1) {
        this.selectedEpisode = this.episodes[currentIndex + 1].episode_number;
        this.$nextTick(() => {
          this.debouncedUpdateEmbedUrl();
        });
        this.updateSeasonEpisodeInContinueWatching();
      } else {
        console.warn("No more episodes available.");
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleDubbed() {
      this.isDubbed = !this.isDubbed;
      this.debouncedUpdateEmbedUrl();
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      const iframe = this.$refs.videoIframe;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ type: "toggleMute", mute: this.isMuted }, "*");
      }
      this.debouncedUpdateEmbedUrl();
    },
    closeVideoDialog() {
      this.showVideoDialog = false;
      this.embedUrl = "";
    },
    async fetchContinueWatching(authId, id) {
      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(continueWatchingRef, where("authId", "==", authId), where("imdbId", "==", id));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach(docSnapshot => {
            const data = docSnapshot.data();
            this.selectedSeason = data.season || 1;
            this.selectedEpisode = data.episode || 1;
            this.updateEmbedUrl();
          });
        }
      } catch (error) {
        console.error("Failed to fetch continue watching data:", error);
      }
    },
    async isAlreadyWatched(authId, id) {
      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", authId),
          where("imdbId", "==", id),
          where("season", "==", this.selectedSeason),
          where("episode", "==", this.selectedEpisode)
        );
        const querySnapshot = await getDocs(q);
        return !querySnapshot.empty;
      } catch (error) {
        console.error("Error checking watch history:", error);
        return false;
      }
    },
    async addToContinueWatching(authId, id) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        const media = response.data.movie_results[0] || response.data.tv_results[0];
        if (!media) {
          console.error("Media details not found");
          return;
        }
        const mediaData = {
          authId,
          imdbId: id,
          title: media.title || media.name,
          rating: media.vote_average,
          imageUrl: `https://image.tmdb.org/t/p/original${media.poster_path}`,
          runtime: media.runtime ? `${media.runtime} min` : `Seasons: ${media.number_of_seasons}`,
          description: media.overview,
          lastWatched: new Date(),
          lastEdited: new Date(),
          season: this.selectedSeason,
          episode: this.selectedEpisode,
        };
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(continueWatchingRef, where("authId", "==", authId), where("imdbId", "==", id));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          await addDoc(collection(db, "continue_watching"), mediaData);
          console.log("Added to continue watching");
        } else {
          querySnapshot.forEach(async docSnapshot => {
            const docRef = doc(db, "continue_watching", docSnapshot.id);
            await updateDoc(docRef, {
              season: this.selectedSeason,
              episode: this.selectedEpisode,
              lastWatched: new Date(),
              lastEdited: new Date(),
            });
            console.log("Updated continue watching with new season/episode");
          });
        }
      } catch (error) {
        console.error("Error adding to continue watching:", error);
      }
    },
    async updateSeasonEpisodeInContinueWatching() {
      if (!this.storedImdbId) {
        console.error("No IMDb ID found in cached value");
        return;
      }
      if (!this.storedAuthId) {
        console.warn("No authId found. Skipping update to continue watching.");
        return;
      }
      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", this.storedAuthId),
          where("imdbId", "==", this.storedImdbId)
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach(async docSnapshot => {
            const docRef = doc(db, "continue_watching", docSnapshot.id);
            await updateDoc(docRef, {
              season: this.selectedSeason,
              episode: this.selectedEpisode,
              lastWatched: new Date(),
              lastEdited: new Date(),
            });
            console.log("Updated continue watching with new season/episode");
          });
        }
      } catch (error) {
        console.error("Error updating continue watching:", error);
      }
    },
    async addToWatchlist() {
      if (!this.storedAuthId) {
        console.error("No authId found in cached value");
        return;
      }
      if (!this.storedImdbId) {
        console.error("No IMDb ID found in cached value");
        return;
      }
      const movieData = {
        authId: this.storedAuthId,
        imdbId: this.storedImdbId,
        title: this.movieTitle,
        rating: this.movieRating,
        imageUrl: this.moviePosterUrl,
        runtime: this.movieRuntime,
        description: this.movieDescription,
      };
      try {
        await addDoc(collection(db, "watchlist"), movieData);
        this.successMessage = "Added to watchlist successfully!";
        this.showSuccessAlert = true;
        setTimeout(() => {
          this.showSuccessAlert = false;
        }, 3000);
        this.scrollToTop();
      } catch (error) {
        console.error("Error adding to watchlist:", error);
      }
    },
    async playContent() {
      if (!this.storedImdbId) {
        console.error("No IMDb ID found in cached value");
        return;
      }
      if (!this.storedAuthId) {
        console.warn("No authId found. Skipping 'add to continue watching'.");
        this.checkIfMovie(this.storedImdbId);
        return;
      }
      const alreadyWatched = await this.isAlreadyWatched(this.storedAuthId, this.storedImdbId);
      if (alreadyWatched) {
        console.warn("Episode/season already in watch history.");
        this.checkIfMovie(this.storedImdbId);
        return;
      }
      await this.addToContinueWatching(this.storedAuthId, this.storedImdbId);
      this.checkIfMovie(this.storedImdbId);
    },
    async checkIfMovie(id) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/find/${id}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        this.isMovie = response.data.movie_results.length > 0;
        this.debouncedUpdateEmbedUrl();
        this.showVideoDialog = true;
      } catch (error) {
        console.error("Failed to check media type:", error);
      }
    },
    async fetchTrailer() {
      if (!this.storedImdbId) {
        console.error("No IMDb ID found in cached value");
        return;
      }
      try {
        const findResponse = await axios.get(
          `https://api.themoviedb.org/3/find/${this.storedImdbId}?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US&external_source=imdb_id`
        );
        let mediaType = null;
        let mediaId = null;
        if (findResponse.data.movie_results[0]) {
          mediaType = "movie";
          mediaId = findResponse.data.movie_results[0].id;
        } else if (findResponse.data.tv_results[0]) {
          mediaType = "tv";
          mediaId = findResponse.data.tv_results[0].id;
        }
        if (!mediaId) {
          console.error("Media ID not found");
          return;
        }
        const videosResponse = await axios.get(
          `https://api.themoviedb.org/3/${mediaType}/${mediaId}/videos?api_key=a6a07bdb1ae12672ae2d301063d83c40&language=en-US`
        );
        const trailer = videosResponse.data.results.find(video => video.type === "Trailer");
        if (trailer) {
          this.trailerKey = trailer.key;
          this.showDialog = true;
        } else {
          this.errorMessage = "No trailer found for this media.";
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Failed to fetch trailer:", error);
      }
    },
    async fetchMagnetLink() {
      if (!this.storedImdbId) {
        console.error("No IMDb ID found in cached value");
        return;
      }
      try {
        const response = await axios.get(
          `https://yts.mx/api/v2/list_movies.json?query_term=${this.storedImdbId}`
        );
        if (response.data && response.data.data.movies && response.data.data.movies.length > 0) {
          const movieData = response.data.data.movies[0];
          const torrentInfo = movieData.torrents[0];
          this.constructMagnetLink(torrentInfo.hash, movieData.title);
        } else {
          this.errorMessage = "No torrents found for this media.";
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Error fetching magnet link:", error);
      }
    },
    constructMagnetLink(torrentHash, movieTitle) {
      const encodedMovieTitle = encodeURIComponent(movieTitle);
      const trackers = [
        "udp://open.demonii.com:1337/announce",
        "udp://tracker.openbittorrent.com:80",
        "udp://tracker.coppersurfer.tk:6969",
        "udp://glotorrents.pw:6969/announce",
        "udp://tracker.opentrackr.org:1337/announce",
        "udp://torrent.gresille.org:80/announce",
        "udp://p4p.arenabg.com:1337",
        "udp://tracker.leechers-paradise.org:6969",
      ];
      let magnetLink = `magnet:?xt=urn:btih:${torrentHash}&dn=${encodedMovieTitle}`;
      trackers.forEach(tracker => {
        magnetLink += `&tr=${encodeURIComponent(tracker)}`;
      });
      window.open(magnetLink, "_blank");
    },
    openDownloadDialog() {
      this.showDownloadDialog = true;
    },
    async downloadSeason(season) {
      if (!this.storedImdbId) {
        console.error("IMDb ID not available");
        return;
      }
      const imdbIdWithoutTT = this.storedImdbId.replace("tt", "");
      let seasonMagnetLinks = [];
      try {
        const response = await axios.get(
          `https://eztvx.to/api/get-torrents?imdb_id=${imdbIdWithoutTT}`
        );
        if (response.data && response.data.torrents) {
          const filteredTorrents = response.data.torrents
            .filter(torrent => {
              const seasonRegex = new RegExp(`S${String(season).padStart(2, "0")}`);
              return seasonRegex.test(torrent.filename);
            })
            .map(torrent => ({
              filename: torrent.filename,
              magnetLink: torrent.magnet_url,
            }));
          seasonMagnetLinks = filteredTorrents.map(torrent => torrent.magnetLink);
        }
        if (seasonMagnetLinks.length > 0) {
          seasonMagnetLinks.forEach(link => {
            window.open(link, "_blank");
          });
        } else {
          this.errorMessage = `No torrents found for Season ${season}.`;
          this.showErrorAlert = true;
        }
      } catch (error) {
        console.error("Error fetching torrents:", error);
      }
    },
    handlePlayerEvent(event) {
      const allowedOrigins = [
        "https://vidsrc.cc",
        "https://www.vidsrc.cc",
        "https://vidlink.pro",
        "https://www.vidlink.pro"
      ];
      if (!allowedOrigins.includes(event.origin)) return;
      if (event.data?.type === "PLAYER_EVENT") {
        const { event: eventType, currentTime, duration } = event.data.data;
        console.log(`Player ${eventType} at ${currentTime}s of ${duration}s`);
        if (eventType === "ended" || eventType === "complete") {
          this.advanceToNextEpisode();
        }
      }
      if (event.data?.type === "MEDIA_DATA") {
        const mediaData = event.data.data;
        localStorage.setItem("vidLinkProgress", JSON.stringify(mediaData));
      }
    },
    advanceToNextEpisode() {
      const currentIndex = this.episodes.findIndex(ep => ep.episode_number === this.selectedEpisode);
      if (currentIndex >= 0 && currentIndex < this.episodes.length - 1) {
        this.selectedEpisode = this.episodes[currentIndex + 1].episode_number;
        this.$nextTick(() => {
          this.debouncedUpdateEmbedUrl();
        });
        this.updateSeasonEpisodeInContinueWatching();
      } else {
        console.warn("No more episodes available.");
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggleDubbed() {
      this.isDubbed = !this.isDubbed;
      this.debouncedUpdateEmbedUrl();
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      const iframe = this.$refs.videoIframe;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage({ type: "toggleMute", mute: this.isMuted }, "*");
      }
      this.debouncedUpdateEmbedUrl();
    },
    closeVideoDialog() {
      this.showVideoDialog = false;
      this.embedUrl = "";
    },
    updateEmbedUrl() {
      this.debouncedUpdateEmbedUrl();
    },
  },
  watch: {
    selectedSeason() {
      this.fetchEpisodes();
      this.debouncedUpdateEmbedUrl();
    },
    selectedEpisode() {
      this.debouncedUpdateEmbedUrl();
    },
    selectedPlayer(newVal) {
      localStorage.setItem("selectedPlayer", newVal);
      this.debouncedUpdateEmbedUrl();
    },
  },
};
</script>

<style scoped>
.movie-banner {
  position: relative;
  width: 100%;
  height: 106vh;
  background-size: cover;
  background-position: center;
  color: white;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,1) 90%, rgba(0,0,0,1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.close-together-row {
  gap: 5px;
}
.poster-col {
  text-align: center;
}
.info-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
}
.movie-title {
  font-size: 3em;
  font-weight: 700;
  color: white;
  margin: 0.2em 0;
  animation: fadeInUp 1s ease-in-out;
}
.rating-reviews {
  margin: 0.2em 0;
}
.reviews-count {
  margin-left: 5px;
}
.genres-row {
  margin: 0.2em 0;
}
.description-container {
  max-width: 500px;
  margin: 0.2em auto;
}
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}
.animate-title {
  animation: fadeInUp 1s ease-in-out;
}
.animate-description {
  animation: fadeInUp 1.5s ease-in-out;
}
.genre-pill {
  display: inline-block;
  padding: 0.3em 0.6em;
  margin: 0.2em;
  background: rgba(255,255,255,0.1);
  border-radius: 4px;
}
.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
.v-btn {
  transition: background-color 0.3s;
  font-size: 1rem;
  height: 46px;
}
.v-btn:hover {
  background-color: rgba(35,255,229,0.8);
}
@media (max-width: 600px) {
  .movie-banner { height: auto; }
  .movie-title { font-size: 1.5rem; }
  .movie-description { font-size: 0.875rem; }
  .v-btn { font-size: 0.75rem; height: 26px; }
}
.scroll-container {
  overflow-y: auto;
  max-height: 650px;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
::-webkit-scrollbar { width: 8px; }
::-webkit-scrollbar-thumb { background-color: #23ffe5; border-radius: 10px; }
::-webkit-scrollbar-track { background-color: #2c2c2c; border-radius: 10px; }
.player-select input {
  color: white !important;
}
</style>
