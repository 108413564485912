<template>
  <v-container fluid class="pa-10" v-if="isAuthIdPresent">
    <v-row>
  <h3 class="styled-heading">CONTINUE WATCHING</h3>
</v-row>

    <div class="carousel-container">
      <!-- Left Arrow -->
      <v-btn class="arrow-btn left-arrow" icon @click="scrollLeft">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- Carousel Row -->
      <div ref="carousel" class="carousel-row">
        <div
          v-for="(media, index) in continueWatching"
          :key="media.id"
          class="card-wrapper"
        >
          <v-hover v-slot:default="{ hover }">
            <div
              class="custom-hover-card"
              :class="{ hovered: activeHoverCard === media.id }"
              @mouseenter="setActiveHoverCard(media.id)"
              @mouseleave="setActiveHoverCard(null)"
            >
              <!-- Default view: Poster image -->
              <v-img
                v-if="activeHoverCard !== media.id"
                :src="getImageUrl(media.imageUrl)"
                class="poster-image"
                height="240px"
                cover
                @click="redirectToViewInfo(media)"
              ></v-img>
              <!-- Hover view: Backdrop with logo (or title) and overlay details -->
              <div
                v-else
                class="hover-container"
                @click="redirectToViewInfo(media)"
              >
                <div class="backdrop-wrapper">
                  <v-img
                    :src="getImageUrl(media.imageUrl)"
                    class="backdrop-image"
                    cover
                  ></v-img>
                  <div class="backdrop-gradient"></div>
                  <div class="backdrop-title">
                    <template v-if="media.logo">
                      <v-img
                        :src="media.logo"
                        class="logo-image"
                        contain
                        height="80px"
                      ></v-img>
                    </template>
                    <template v-else>
                      <div class="white--text">{{ media.title }}</div>
                    </template>
                  </div>
                </div>
                <div class="hover-description" @click.stop>
                  <p class="meta">{{ media.release_date | formatDate }}</p>
                  <p class="desc">{{ truncatedText(media.description, 120) }}</p>
                  <div class="info-actions">
                    <v-btn
                      class="watch-now-btn"
                      @click.stop="redirectToViewInfo(media)"
                    >
                      <v-icon left>mdi-play</v-icon>
                      Watch Now
                    </v-btn>
                    <v-btn
                      icon
                      class="plus-btn"
                      v-if="isUserLoggedIn"
                      @click.stop="addToWatchlist(media)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-hover>
          <!-- Ranking number overlay -->
        </div>
      </div>

      <!-- Right Arrow -->
      <v-btn class="arrow-btn right-arrow" icon @click="scrollRight">
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";
import { VImg, VBtn, VIcon, VHover } from "vuetify/lib";
import { debounce } from "lodash";
import axios from "axios";

const db = getFirestore(firebaseApp);

export default {
  components: {
    VImg,
    VBtn,
    VIcon,
    VHover
  },
  data() {
    return {
      continueWatching: [],
      isLoading: false,
      isAuthIdPresent: false,
      isUserLoggedIn: !!localStorage.getItem("authId"),
      activeHoverCard: null,
      // Fanart API key for logos
      fanartApiKey: "77f845990d4d0b6bfe8472508b664c3b"
    };
  },
  created() {
    const authId = localStorage.getItem("authId");
    this.isAuthIdPresent = !!authId;
    if (this.isAuthIdPresent) {
      this.fetchContinueWatching();
    }
  },
  methods: {
    async fetchContinueWatching() {
      const authId = localStorage.getItem("authId");
      if (!authId) return;
      this.isLoading = true;
      try {
        const continueWatchingRef = collection(db, "continue_watching");
        const q = query(
          continueWatchingRef,
          where("authId", "==", authId),
          orderBy("lastEdited", "desc")
        );
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach(doc => {
          data.push({ id: doc.id, ...doc.data() });
        });
        this.continueWatching = data;
        // Process logos for each media item using its IMDb ID
        await this.processLogos();
      } catch (error) {
        console.error("Error fetching continue watching data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async processLogos() {
      // Use TMDb's /find endpoint to determine if the IMDb ID corresponds to a movie or TV show.
      for (let media of this.continueWatching) {
        if (media.imdbId) {
          try {
            const details = await this.fetchMediaDetailsUsingImdb(media.imdbId);
            if (
              details.movie_results &&
              details.movie_results.length > 0
            ) {
              // It's a movie – use the movie logo endpoint.
              const logoUrl = await this.fetchMovieLogo(media.imdbId);
              this.$set(media, "logo", logoUrl);
            } else if (
              details.tv_results &&
              details.tv_results.length > 0
            ) {
              // It's a TV show – fetch TV details and then the logo.
              const tvId = details.tv_results[0].id;
              const tvDetails = await this.fetchTVShowDetails(tvId);
              if (
                tvDetails.external_ids &&
                tvDetails.external_ids.tvdb_id
              ) {
                const logoUrl = await this.fetchTVShowLogo(
                  tvDetails.external_ids.tvdb_id
                );
                this.$set(media, "logo", logoUrl);
              } else {
                this.$set(media, "logo", null);
              }
            } else {
              this.$set(media, "logo", null);
            }
          } catch (error) {
            console.error("Error processing logo for media:", error);
            this.$set(media, "logo", null);
          }
        } else {
          this.$set(media, "logo", null);
        }
      }
    },
    // New helper: determine media details using the IMDb ID.
    async fetchMediaDetailsUsingImdb(imdbId) {
      const apiKey =
        process.env.VUE_APP_TMDB_API_KEY ||
        "a6a07bdb1ae12672ae2d301063d83c40";
      const response = await axios.get(
        `https://api.themoviedb.org/3/find/${imdbId}?api_key=${apiKey}&external_source=imdb_id`
      );
      return response.data;
    },
    async fetchMovieLogo(imdbId) {
      if (!imdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/movies/${imdbId}?api_key=${this.fanartApiKey}`
        );
        // Check multiple possible keys for movie logos.
        const possibleKeys = ["hdmovielogo", "movielogo"];
        for (const key of possibleKeys) {
          if (response.data[key] && response.data[key].length > 0) {
            return response.data[key][0].url;
          }
        }
        return null;
      } catch (error) {
        console.error("Failed to fetch movie logo:", error);
        return null;
      }
    },
    async fetchTVShowLogo(tvdbId) {
      if (!tvdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/tv/${tvdbId}?api_key=${this.fanartApiKey}`
        );
        const possibleKeys = [
          "hdtvlogo",
          "clearlogo",
          "hdclearlogo",
          "tvthumb"
        ];
        for (const key of possibleKeys) {
          if (response.data[key] && response.data[key].length > 0) {
            return response.data[key][0].url;
          }
        }
        return null;
      } catch (error) {
        console.error("Failed to fetch TV show logo:", error);
        return null;
      }
    },
    // Fetch detailed TV show data (including external IDs) from TMDb.
    async fetchTVShowDetails(tvId) {
      try {
        const apiKey =
          process.env.VUE_APP_TMDB_API_KEY ||
          "a6a07bdb1ae12672ae2d301063d83c40";
        const response = await axios.get(
          `https://api.themoviedb.org/3/tv/${tvId}?api_key=${apiKey}&append_to_response=external_ids`
        );
        return response.data;
      } catch (error) {
        console.error("Failed to fetch TV show details:", error);
        return {};
      }
    },
    getImageUrl(path) {
      return path ? path : "default_image.jpg";
    },
    async redirectToViewInfo(media) {
      this.isLoading = true;
      try {
        localStorage.setItem("imdbId", media.imdbId);
        this.$router.push(`/viewInfo`);
      } catch (error) {
        console.error("Error redirecting to view info:", error);
      } finally {
        this.isLoading = false;
      }
    },
    truncatedText(text, maxLength = 120) {
      return text && text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    setActiveHoverCard(id) {
      this.activeHoverCard = id;
    },
    addToWatchlist(media) {
      // Implement your addToWatchlist logic here.
    },
    scrollLeft: debounce(function() {
      this.$refs.carousel.scrollBy({ left: -300, behavior: "smooth" });
    }, 100),
    scrollRight: debounce(function() {
      this.$refs.carousel.scrollBy({ left: 300, behavior: "smooth" });
    }, 100)
  },
  filters: {
    formatDate(dateString) {
      if (!dateString) return "";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    }
  }
};
</script>

<style scoped>
/* -------- Fixed Y-axis Carousel Container -------- */
.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: 300px;
  overflow: hidden;
}

.carousel-row {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding: 1rem 3rem;
  height: 300px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel-row::-webkit-scrollbar {
  display: none;
}

.card-wrapper {
  position: relative;
  flex: 0 0 auto;
  width: 160px;
  margin: 0 0.5rem;
}

.arrow-btn {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: #fff;
}
.left-arrow {
  left: 10px;
}
.right-arrow {
  right: 10px;
}

/* ----- Hover Swap: Poster -> Backdrop with Logo & Description ----- */
.custom-hover-card {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.custom-hover-card.hovered {
  position: absolute;
  top: -50px;
  left: 0;
  width: 300px;
  height: 350px;
  z-index: 999;
}
.poster-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.backdrop-wrapper {
  position: relative;
  width: 100%;
  height: 60%;
}
.backdrop-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.backdrop-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 5;
}
.backdrop-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  padding: 5px;
}
.logo-image {
  max-width: 200px;
  max-height: 80px;
}

.hover-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 1.2rem;
  box-sizing: border-box;
}
.hover-description .meta {
  font-size: 0.85rem;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}
.hover-description .desc {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}
.info-actions {
  display: flex;
  gap: 0.5rem;
}
.watch-now-btn {
  background-color: #0CDFE5 !important;
  width: 200px;
  text-transform: none;
  font-weight: bold;
}
.plus-btn {
  background-color: rgba(255,255,255,0.2) !important;
  color: #fff !important;
}

.styled-heading {
  position: relative;
  color: white;
  font-weight: 600;
  margin-left: 100px; /* If you want the same left margin you had before */
  padding-left: 12px; /* Make room for the bar */
}

/* Create the vertical bar using a pseudo-element */
.styled-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;        /* Thickness of the bar */
  height: 100%;      /* Matches the height of the H3 */
  background-color: #0CDFE5; /* Color of the bar */
}

</style>
