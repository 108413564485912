<template>
  <div v-if="media" style="margin-top:-5%" class="random-comedy-container">
    <!-- LEFT SIDE: Show backdrop by default -->
    <div
      class="media-container pa-4"
      @mouseover="showTrailer = true"
      @mouseleave="showTrailer = false"
    >
      <!-- On hover, show the autoplaying trailer if available -->
      <div v-if="showTrailer && trailerUrl" class="video-iframe-wrapper">
        <iframe
          :src="trailerUrl"
          frameborder="0"
          allowfullscreen
          allow="autoplay; encrypted-media"
        ></iframe>
      </div>

      <!-- Otherwise, show the backdrop image -->
      <div v-else class="fallback-image pa-10">
        <v-img
          :src="getBackdropUrl(media.backdrop_path)"
          alt="Backdrop"
          cover
        />
      </div>
    </div>

    <!-- RIGHT SIDE: Content + logo -->
    <div class="info-container">
      <div class="content-and-logo">
        <div class="text-content pa-7">
          <!-- If a logo is available, display it -->
          <div v-if="media.logo" class="logo-container" style="margin-top:-20px">
            <v-img
              :src="media.logo"
              alt="Media Logo"
              height="200px"
              contain
            />
          </div>
          <!-- Otherwise, show the title (or name for TV shows) -->
          <div v-else class="movie-title">
            {{ isTvShow ? media.name : media.title }}
          </div>

          <!-- Rating / year / runtime / certification -->
          <div class="movie-subinfo">
            <span class="rating">
              <v-icon color="#23ffe5" small>mdi-star</v-icon>
              {{ media.vote_average.toFixed(1) }} (IMDb)
            </span>
            <span class="separator">·</span>
            <span>
              <!-- For TV shows use first_air_date, for movies use year -->
              {{ isTvShow ? (media.first_air_date ? media.first_air_date.slice(0, 4) : '') : media.year }}
            </span>
            <span class="separator">·</span>
            <span>{{ formattedRuntime }}</span>
            <span class="separator">·</span>
            <span class="certification">{{ media.certification }}</span>
          </div>

          <!-- Overview -->
          <p class="overview">{{ media.overview }}</p>

          <!-- Buttons -->
          <div class="button-group">
            <v-btn large color="#23ffe5" @click="handleWatchNow">
              Watch Now
            </v-btn>
            <v-btn large outlined color="white" class="mx-3" @click="handleDetails">
              Details
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VImg, VIcon, VBtn } from "vuetify/lib";

const TMDB_API_KEY = "a6a07bdb1ae12672ae2d301063d83c40";
const FANART_API_KEY = "77f845990d4d0b6bfe8472508b664c3b";

export default {
  name: "RandomComedyMedia",
  components: {
    VImg,
    VIcon,
    VBtn,
  },
  data() {
    return {
      showTrailer: false,
      media: null,       // Will hold either a movie or a TV show object
      trailerUrl: null,
      // We'll store the currently detected category locally:
      currentCategory: localStorage.getItem("selectedCategory") || "movies",
      categoryInterval: null,
    };
  },
  computed: {
    // Determine if the current category is TV shows
    isTvShow() {
      return this.currentCategory === "tvShows";
    },
    formattedRuntime() {
      if (!this.media) return "";
      if (this.isTvShow) {
        // TV shows have episode_run_time (an array) – use the first value if available
        if (this.media.episode_run_time && this.media.episode_run_time.length) {
          return `${this.media.episode_run_time[0]}m`;
        }
        return "";
      } else {
        if (!this.media.runtime) return "";
        const hours = Math.floor(this.media.runtime / 60);
        const minutes = this.media.runtime % 60;
        return hours ? `${hours}h ${minutes}m` : `${minutes}m`;
      }
    },
  },
  methods: {
    // Common method to build a backdrop image URL
    getBackdropUrl(path) {
      if (!path) {
        return "https://via.placeholder.com/1280x720?text=No+Image";
      }
      return `https://image.tmdb.org/t/p/original${path}`;
    },

    // ---------------------------
    // FETCH RANDOM MOVIE (Comedy)
    // ---------------------------
    async fetchRandomComedy() {
      try {
        const randomPage = Math.floor(Math.random() * 50) + 1;
        const discoverUrl = `https://api.themoviedb.org/3/discover/movie?api_key=${TMDB_API_KEY}&with_genres=35&language=en-US&page=${randomPage}&sort_by=popularity.desc&vote_average.gte=7&include_adult=false`;
        const discoverRes = await axios.get(discoverUrl);
        const results = discoverRes.data.results || [];
        if (!results.length) return;
        const randomIndex = Math.floor(Math.random() * results.length);
        const randomMovieBasic = results[randomIndex];

        const detailsUrl = `https://api.themoviedb.org/3/movie/${randomMovieBasic.id}?api_key=${TMDB_API_KEY}&append_to_response=videos,release_dates`;
        const detailsRes = await axios.get(detailsUrl);
        const data = detailsRes.data;

        const trailer = data.videos.results.find(
          (v) => v.site === "YouTube" && v.type === "Trailer"
        );
        const officialTrailerKey = trailer ? trailer.key : null;

        const usRelease = data.release_dates?.results?.find(
          (rel) => rel.iso_3166_1 === "US"
        );
        let certification = "NR";
        if (usRelease && usRelease.release_dates.length > 0) {
          certification = usRelease.release_dates[0].certification || "NR";
        }

        this.media = {
          id: data.id,
          title: data.title,
          backdrop_path: data.backdrop_path,
          overview: data.overview,
          vote_average: data.vote_average || 0,
          runtime: data.runtime,
          year: (data.release_date || "").slice(0, 4),
          certification,
          imdbId: data.imdb_id,
          logo: null,
        };

        if (officialTrailerKey) {
          this.trailerUrl = `https://www.youtube.com/embed/${officialTrailerKey}?autoplay=1&mute=1&controls=0&showinfo=0&modestbranding=1&loop=1&rel=0`;
        } else {
          this.trailerUrl = null;
        }

        if (this.media.imdbId) {
          const logoUrl = await this.fetchMovieLogo(this.media.imdbId);
          if (logoUrl) {
            this.media.logo = logoUrl;
          }
        }
      } catch (err) {
        console.error("Error fetching random comedy movie:", err);
      }
    },

    // ---------------------------
    // FETCH RANDOM TV SHOW (Comedy)
    // ---------------------------
    async fetchRandomTvShow() {
      try {
        const randomPage = Math.floor(Math.random() * 50) + 1;
        const discoverUrl = `https://api.themoviedb.org/3/discover/tv?api_key=${TMDB_API_KEY}&with_genres=35&language=en-US&page=${randomPage}&sort_by=popularity.desc&vote_average.gte=7`;
        const discoverRes = await axios.get(discoverUrl);
        const results = discoverRes.data.results || [];
        if (!results.length) return;
        const randomIndex = Math.floor(Math.random() * results.length);
        const randomTvBasic = results[randomIndex];

        const detailsUrl = `https://api.themoviedb.org/3/tv/${randomTvBasic.id}?api_key=${TMDB_API_KEY}&append_to_response=videos,content_ratings,external_ids`;
        const detailsRes = await axios.get(detailsUrl);
        const data = detailsRes.data;

        const trailer = data.videos.results.find(
          (v) => v.site === "YouTube" && v.type === "Trailer"
        );
        const officialTrailerKey = trailer ? trailer.key : null;

        let certification = "NR";
        if (data.content_ratings && data.content_ratings.results) {
          const usRating = data.content_ratings.results.find(
            (c) => c.iso_3166_1 === "US"
          );
          if (usRating) {
            certification = usRating.rating || "NR";
          }
        }

        this.media = {
          id: data.id,
          name: data.name,
          backdrop_path: data.backdrop_path,
          overview: data.overview,
          vote_average: data.vote_average || 0,
          episode_run_time: data.episode_run_time,
          first_air_date: data.first_air_date,
          certification,
          imdbId: data.external_ids ? data.external_ids.imdb_id : null,
          logo: null,
        };

        if (officialTrailerKey) {
          this.trailerUrl = `https://www.youtube.com/embed/${officialTrailerKey}?autoplay=1&mute=1&controls=0&showinfo=0&modestbranding=1&loop=1&rel=0`;
        } else {
          this.trailerUrl = null;
        }

        if (data.external_ids && data.external_ids.tvdb_id) {
          const logoUrl = await this.fetchTvShowLogo(data.external_ids.tvdb_id);
          if (logoUrl) {
            this.media.logo = logoUrl;
          }
        }
      } catch (err) {
        console.error("Error fetching random TV show:", err);
      }
    },

    // ---------------------------
    // FETCH MOVIE LOGO (fanart.tv/movies)
    // ---------------------------
    async fetchMovieLogo(imdbId) {
      try {
        const fanartUrl = `https://webservice.fanart.tv/v3/movies/${imdbId}?api_key=${FANART_API_KEY}`;
        const response = await axios.get(fanartUrl);
        const logos = response.data.hdmovielogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.warn("Failed to fetch movie logo from fanart:", error);
        return null;
      }
    },

    // ---------------------------
    // FETCH TV SHOW LOGO (fanart.tv/tv)
    // ---------------------------
    async fetchTvShowLogo(tvdbId) {
      try {
        const fanartTvUrl = `https://webservice.fanart.tv/v3/tv/${tvdbId}?api_key=${FANART_API_KEY}`;
        const response = await axios.get(fanartTvUrl);
        const logos = response.data.hdtvlogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.warn("Failed to fetch TV show logo from fanart:", error);
        return null;
      }
    },

    // ---------------------------
    // HANDLE WATCH NOW BUTTON
    // ---------------------------
    handleWatchNow() {
      const selectedCategory = this.currentCategory;
      if (selectedCategory === "tvShows") {
        const tvId = this.media.imdbId || this.media.id;
        localStorage.setItem("imdbId", tvId);
        localStorage.setItem("mediaType", "tv");
      } else {
        if (!this.media.imdbId) {
          console.error("No IMDb ID found for this movie.");
          return;
        }
        localStorage.setItem("imdbId", this.media.imdbId);
        localStorage.setItem("mediaType", "movie");
      }
      this.$router.push("/viewInfo");
    },

    // ---------------------------
    // HANDLE DETAILS BUTTON (placeholder)
    // ---------------------------
    handleDetails() {
      alert("You clicked 'Details' – integrate your route or modal logic here!");
    },

    // ---------------------------
    // CHECK FOR CATEGORY CHANGE
    // ---------------------------
    checkCategory() {
      const storedCategory = localStorage.getItem("selectedCategory") || "movies";
      if (storedCategory !== this.currentCategory) {
        this.currentCategory = storedCategory;
        // Refetch the media based on the new category.
        if (this.currentCategory === "tvShows") {
          this.fetchRandomTvShow();
        } else {
          this.fetchRandomComedy();
        }
      }
    },
  },
  mounted() {
    // Initial fetch based on the stored category.
    this.currentCategory = localStorage.getItem("selectedCategory") || "movies";
    if (this.currentCategory === "tvShows") {
      this.fetchRandomTvShow();
    } else {
      this.fetchRandomComedy();
    }
    // Set up an interval to check for category changes every 1 second.
    this.categoryInterval = setInterval(this.checkCategory, 1000);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed.
    if (this.categoryInterval) {
      clearInterval(this.categoryInterval);
    }
  },
};
</script>

<style scoped>
/* -----------------------
   GENERAL LAYOUT
------------------------ */
.random-comedy-container {
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  color: #fff;
  min-height: 60vh;
  padding: 1rem;
  box-sizing: border-box;
}

.media-container,
.info-container {
  flex: 1 1 500px;
  min-width: 300px;
  box-sizing: border-box;
  margin-bottom: 1rem;
}

/* -----------------------
   LEFT SIDE (VIDEO or IMAGE)
------------------------ */
.media-container {
  position: relative;
  background: black;
  overflow: hidden;
}

.video-iframe-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}
.video-iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.fallback-image {
  width: 100%;
  height: 100%;
}

/* -----------------------
   RIGHT SIDE (INFO)
------------------------ */
.info-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-and-logo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.text-content {
  flex: 1;
  margin-right: 1rem;
}

.movie-title {
  font-size: 2em;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #fff;
}

/* Logo container */
.logo-container {
  max-width: 400px;
  max-height: 400px;
  display: flex;
  align-items: center;
}
.logo-container ::v-deep .v-img__img {
  object-fit: contain;
  height: 80px;
  width: auto;
}

/* Sub-info row */
.movie-subinfo {
  margin: 0.5rem 0 1rem;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  color: #bbb;
}
.separator {
  margin: 0 0.5em;
}
.certification {
  background-color: #23ffe5;
  color: #000;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  font-weight: bold;
}

/* Overview text */
.overview {
  font-size: 1.1em;
  color: #ddd;
  line-height: 1.4;
  margin-bottom: 2rem;
  max-width: 90%;
}

/* Buttons */
.button-group {
  display: flex;
  align-items: center;
}
</style>
