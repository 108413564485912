<template>
  <v-container  fluid class="pa-10">
    <v-row>
      <h3 class="styled-heading" style="margin-left:100px;">More Like This</h3>
    </v-row>

    <div class="carousel-container">
      <!-- Left Arrow -->
      <v-btn class="arrow-btn left-arrow" icon @click="scrollLeft">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- Carousel Row -->
      <div ref="carousel" class="carousel-row">
        <div
          v-for="(item, index) in recommendations"
          :key="getUniqueKey(item, index)"
          class="card-wrapper"
        >
          <div
            class="custom-hover-card"
            :class="{ hovered: activeHoverCard === getUniqueKey(item, index) }"
            @mouseenter="setActiveHoverCard(getUniqueKey(item, index))"
            @mouseleave="setActiveHoverCard(null)"
          >
            <!-- Default view: Poster image -->
            <v-img
              v-if="activeHoverCard !== getUniqueKey(item, index)"
              :src="getImageUrl(item.poster_path)"
              class="poster-image"
              height="240px"
              cover
              @click="handleCardClick(item)"
            ></v-img>
            <!-- Hover view: Backdrop, logo (or title fallback), and action buttons -->
            <div
              v-else
              class="hover-container"
              @click="handleCardClick(item)"
            >
              <div class="backdrop-wrapper">
                <v-img
                  :src="getImageUrl(item.poster_path)"
                  class="backdrop-image"
                  cover
                ></v-img>
                <div class="backdrop-gradient"></div>
                <div class="backdrop-title">
                  <template v-if="item.logo">
                    <v-img
                      :src="item.logo"
                      class="logo-image"
                      contain
                      height="80px"
                    ></v-img>
                  </template>
                  <template v-else>
                    <div class="white--text">
                      {{ item.title || item.name || "Unknown Title" }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="hover-description" @click.stop>
                <p class="meta">{{ item.release_date | formatDate }}</p>
                <p class="desc">{{ truncatedText(item.overview, 120) }}</p>
                <div class="info-actions">
                  <v-btn
                    class="watch-now-btn"
                    @click.stop="handleCardClick(item)"
                  >
                    <v-icon left>mdi-play</v-icon>
                    Watch Now
                  </v-btn>
                  <v-btn
                    icon
                    class="plus-btn"
                    v-if="isUserLoggedIn"
                    @click.stop="addToWatchlist(item)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <!-- Ranking number overlay (optional – remove if not needed) -->
        </div>
      </div>

      <!-- Right Arrow -->
      <v-btn class="arrow-btn right-arrow" icon @click="scrollRight">
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firebaseApp } from "../firebaseConfig";

const db = getFirestore(firebaseApp);
const API_KEY =
  process.env.VUE_APP_TMDB_API_KEY || "a6a07bdb1ae12672ae2d301063d83c40";

export default {
  data() {
    return {
      recommendations: [],
      isLoading: false,
      isUserLoggedIn: !!localStorage.getItem("authId"),
      activeHoverCard: null,
      fanartApiKey: "77f845990d4d0b6bfe8472508b664c3b",
    };
  },
  mounted() {
    this.fetchRecommendations();
  },
  methods: {
    async fetchRecommendations() {
      const imdbId = localStorage.getItem("imdbId");
      if (!imdbId) {
        console.error("No IMDb ID found in localStorage");
        return;
      }
      try {
        let response = await axios.get(
          `https://api.themoviedb.org/3/find/${imdbId}?api_key=${API_KEY}&language=en-US&external_source=imdb_id`
        );
        // Determine whether to use movie or TV recommendations
        const tmdbId =
          response.data.movie_results[0]?.id ||
          response.data.tv_results[0]?.id;
        const isTVShow =
          response.data.tv_results && response.data.tv_results.length > 0;
        if (isTVShow) {
          response = await axios.get(
            `https://api.themoviedb.org/3/tv/${tmdbId}/recommendations?api_key=${API_KEY}&language=en-US`
          );
        } else {
          response = await axios.get(
            `https://api.themoviedb.org/3/movie/${tmdbId}/recommendations?api_key=${API_KEY}&language=en-US`
          );
        }
        this.recommendations = response.data.results.filter(
          (item) => item.poster_path !== null
        );
        // Once recommendations are fetched, process logos via Fanart API.
        this.processLogos();
      } catch (error) {
        console.error("Failed to fetch recommendations:", error);
      }
    },
    async processLogos() {
      // Loop through each recommendation and attempt to fetch its logo.
      for (let item of this.recommendations) {
        if (item.release_date) {
          // Assume movie if release_date exists
          const details = await this.fetchMovieDetails(item.id);
          if (details.external_ids && details.external_ids.imdb_id) {
            const logoUrl = await this.fetchMovieLogo(details.external_ids.imdb_id);
            this.$set(item, "logo", logoUrl);
          }
        } else if (item.first_air_date) {
          // Otherwise, assume TV show
          const details = await this.fetchTVShowDetails(item.id);
          if (details.external_ids && details.external_ids.tvdb_id) {
            const logoUrl = await this.fetchTVShowLogo(details.external_ids.tvdb_id);
            this.$set(item, "logo", logoUrl);
          }
        }
      }
    },
    async fetchMovieDetails(movieId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/${movieId}?api_key=${API_KEY}&language=en-US&append_to_response=external_ids`
        );
        return response.data;
      } catch (error) {
        console.error(`Failed to fetch details for movie ID ${movieId}:`, error);
        return {};
      }
    },
    async fetchTVShowDetails(tvId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/tv/${tvId}?api_key=${API_KEY}&language=en-US&append_to_response=external_ids`
        );
        return response.data;
      } catch (error) {
        console.error(`Failed to fetch details for TV show ID ${tvId}:`, error);
        return {};
      }
    },
    async fetchMovieLogo(imdbId) {
      if (!imdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/movies/${imdbId}?api_key=${this.fanartApiKey}`
        );
        const logos = response.data.hdmovielogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.error("Failed to fetch movie logo:", error);
        return null;
      }
    },
    async fetchTVShowLogo(tvdbId) {
      if (!tvdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/tv/${tvdbId}?api_key=${this.fanartApiKey}`
        );
        const logos = response.data.hdtvlogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.error("Failed to fetch TV show logo:", error);
        return null;
      }
    },
    getImageUrl(path) {
      return path ? `https://image.tmdb.org/t/p/w500${path}` : "default_image.jpg";
    },
    async handleCardClick(item) {
      this.isLoading = true;
      try {
        let detailsUrl;
        let response;
        if (item.media_type === "tv" || item.first_air_date) {
          detailsUrl = `https://api.themoviedb.org/3/tv/${item.id}/external_ids?api_key=${API_KEY}`;
        } else {
          detailsUrl = `https://api.themoviedb.org/3/movie/${item.id}?api_key=${API_KEY}&language=en-US`;
        }
        response = await axios.get(detailsUrl);
        const imdbId = response.data.imdb_id;
        if (imdbId) {
          localStorage.setItem("imdbId", imdbId);
          // Reload page or navigate as needed
          location.reload();
        } else {
          console.error("IMDb ID not found for the selected item.");
        }
      } catch (error) {
        console.error("Failed to fetch IMDb ID:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async addToWatchlist(item) {
      const authId = localStorage.getItem("authId");
      if (!authId) {
        console.error("No authId found in localStorage");
        return;
      }
      try {
        let detailsUrl, response;
        if (item.media_type === "tv" || item.first_air_date) {
          detailsUrl = `https://api.themoviedb.org/3/tv/${item.id}/external_ids?api_key=${API_KEY}`;
          response = await axios.get(detailsUrl);
        } else {
          detailsUrl = `https://api.themoviedb.org/3/movie/${item.id}?api_key=${API_KEY}&language=en-US`;
          response = await axios.get(detailsUrl);
        }
        const imdbId = response.data.imdb_id;
        if (!imdbId) {
          console.error("IMDb ID not found for the item.");
          return;
        }
        const watchlistRef = collection(db, "watchlist");
        const q = query(
          watchlistRef,
          where("authId", "==", authId),
          where("imdbId", "==", imdbId)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          await addDoc(watchlistRef, {
            authId: authId,
            imdbId: imdbId,
            title: item.title || item.name || "Unknown Title",
            rating: item.vote_average !== undefined ? item.vote_average : "",
            imageUrl: this.getImageUrl(item.poster_path),
            release_date: item.release_date || item.first_air_date || "Unknown",
            description: item.overview || "No description available",
            addedAt: new Date(),
          });
          this.$toast.success("Added to watchlist successfully", {
            position: "bottom-right",
            timeout: 5000,
          });
        } else {
          this.$toast.warning("Already added to watchlist", {
            position: "bottom-right",
            timeout: 5000,
          });
        }
      } catch (error) {
        console.error("Error adding item to watchlist:", error);
      }
    },
    truncatedText(text, maxLength = 120) {
      return text && text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    scrollLeft() {
      this.$refs.carousel.scrollBy({ left: -300, behavior: "smooth" });
    },
    scrollRight() {
      this.$refs.carousel.scrollBy({ left: 300, behavior: "smooth" });
    },
    setActiveHoverCard(key) {
      this.activeHoverCard = key;
    },
    getUniqueKey(item, index) {
      return `${item.id}-${index}`;
    },
  },
  filters: {
    formatDate(dateString) {
      if (!dateString) return "";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
/* -------- Fixed Y-axis Carousel Container -------- */
.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: 300px;
  overflow: hidden;
}

.carousel-row {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding: 1rem 3rem;
  height: 300px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel-row::-webkit-scrollbar {
  display: none;
}

.card-wrapper {
  position: relative;
  flex: 0 0 auto;
  width: 160px;
  margin: 0 0.5rem;
}

.arrow-btn {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: #fff;
}
.left-arrow {
  left: 10px;
}
.right-arrow {
  right: 10px;
}

/* ----- Hover Swap: Poster -> Backdrop with Logo & Description ----- */
.custom-hover-card {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.custom-hover-card.hovered {
  position: absolute;
  top: -50px;
  left: 0;
  width: 300px;
  height: 350px;
  z-index: 999;
}
.poster-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.backdrop-wrapper {
  position: relative;
  width: 100%;
  height: 60%;
}
.backdrop-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.backdrop-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 5;
}
.backdrop-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  padding: 5px;
}
.logo-image {
  max-width: 200px;
  max-height: 80px;
}

.hover-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  box-sizing: border-box;
}
.hover-description .meta {
  font-size: 0.85rem;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}
.hover-description .desc {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}
.info-actions {
  display: flex;
  gap: 0.5rem;
}
.watch-now-btn {
  background-color: #0CDFE5 !important;
  width: 200px;
  text-transform: none;
  font-weight: bold;
}
.plus-btn {
  background-color: rgba(255,255,255,0.2) !important;
  color: #fff !important;
}

/* ------------------
   Ranking Number
   ------------------ */
.ranking-number {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 3rem;
  font-weight: 900;
  color: #0cdfe5;
  z-index: 81;
  pointer-events: none;
  text-shadow:
    1px 0 0 white, -1px 0 0 white, 0 1px 0 white, 0 -1px 0 white,
    1px 1px 0 white, 1px -1px 0 white, -1px 1px 0 white, -1px -1px 0 white,
    2px 0 0 white, -2px 0 0 white, 0 2px 0 white, 0 -2px 0 white,
    2px 2px 0 white, 2px -2px 0 white, -2px 2px 0 white, -2px -2px 0 white,
    3px 0 0 white, -3px 0 0 white, 0 3px 0 white, 0 -3px 0 white,
    3px 3px 0 white, 3px -3px 0 white, -3px 3px 0 white, -3px -3px 0 white;
}

/* Styled Heading with Left Bar */
.styled-heading {
  position: relative;
  color: white;
  font-weight: 600;
  margin-left: 80px;
  padding-left: 12px;
}
.styled-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #0CDFE5;
}

.fade-in-left {
  animation: fade-in-left 1.5s cubic-bezier(.39, .575, .565, 1.000) both;
}
@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
