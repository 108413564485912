<template>
  <v-container fluid class="pa-10" style="margin-top:-2%">
    <v-row>
      <h3 class="styled-heading" style="margin-left:100px;">{{ topHeading }}</h3>
    </v-row>

    <div class="carousel-container">
      <!-- Left Arrow -->
      <v-btn class="arrow-btn left-arrow" icon @click="scrollLeft">
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>

      <!-- Carousel Row -->
      <div ref="carousel" class="carousel-row">
        <div
          v-for="(item, index) in actionItems"
          :key="getUniqueKey(item, index)"
          class="card-wrapper"
        >
          <div
            class="custom-hover-card"
            :class="{ hovered: activeHoverCard === getUniqueKey(item, index) }"
            @mouseenter="setActiveHoverCard(getUniqueKey(item, index))"
            @mouseleave="setActiveHoverCard(null)"
          >
            <!-- Default view: Poster image -->
            <v-img
              v-if="activeHoverCard !== getUniqueKey(item, index)"
              :src="getImageUrl(item.poster_path)"
              class="poster-image"
              height="240px"
              cover
              @click="redirectToViewInfo(item)"
            ></v-img>
            <!-- Hover view: Backdrop with logo/title and description -->
            <div v-else class="hover-container" @click="redirectToViewInfo(item)">
              <div class="backdrop-wrapper">
                <v-img
                  :src="getImageUrl(item.poster_path)"
                  class="backdrop-image"
                  cover
                ></v-img>
                <div class="backdrop-gradient"></div>
                <div class="backdrop-title">
                  <template v-if="item.logo">
                    <v-img
                      :src="item.logo"
                      class="logo-image"
                      contain
                      height="80px"
                    ></v-img>
                  </template>
                  <template v-else>
                    <div class="white--text">{{ item.title }}</div>
                  </template>
                </div>
              </div>
              <div class="hover-description" @click.stop>
                <p class="meta">{{ item.release_date | formatDate }}</p>
                <p class="desc">{{ truncatedText(item.overview, 120) }}</p>
                <div class="info-actions">
                  <v-btn class="watch-now-btn" @click.stop="redirectToViewInfo(item)">
                    <v-icon left>mdi-play</v-icon>
                    Watch Now
                  </v-btn>
                  <v-btn
                    icon
                    class="plus-btn"
                    v-if="isUserLoggedIn"
                    @click.stop="addToWatchlist(item)"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <!-- Removed ranking number overlay -->
        </div>
      </div>

      <!-- Right Arrow -->
      <v-btn class="arrow-btn right-arrow" icon @click="scrollRight">
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { debounce } from "lodash";
// Import any Firebase modules if needed for watchlist functionality
export default {
  data() {
    return {
      actionItems: [], // Array of Action movies or TV shows
      isLoading: false,
      isUserLoggedIn: !!localStorage.getItem("authId"),
      // Category stored as either "movies" or "tvShows"
      currentCategory: localStorage.getItem("selectedCategory") || "movies",
      activeHoverCard: null,
      categoryInterval: null,
      fanartApiKey: "77f845990d4d0b6bfe8472508b664c3b", // for optional logo fetching
    };
  },
  computed: {
    topHeading() {
      return this.currentCategory === "tvShows"
        ? "ACTION TV SHOWS"
        : "ACTION MOVIES";
    },
  },
  created() {
    this.fetchActionItems();
    // Watch for changes in the stored category
    this.categoryInterval = setInterval(this.checkCategory, 1000);
  },
  beforeDestroy() {
    if (this.categoryInterval) {
      clearInterval(this.categoryInterval);
    }
  },
  methods: {
    async fetchActionItems() {
      this.isLoading = true;
      try {
        if (this.currentCategory === "tvShows") {
          await this.fetchActionTvShows();
        } else {
          await this.fetchActionMovies();
        }
      } catch (error) {
        console.error("Error fetching action items:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchActionMovies() {
      try {
        // Fetch two pages and merge then slice for 20 items.
        const page1 = await axios.get(
          `https://api.themoviedb.org/3/discover/movie?api_key=${
            process.env.VUE_APP_TMDB_API_KEY ||
            "a6a07bdb1ae12672ae2d301063d83c40"
          }&with_genres=28&language=en-US&sort_by=popularity.desc&page=${Math.floor(Math.random() * 50) + 1}`
        );
        const page2 = await axios.get(
          `https://api.themoviedb.org/3/discover/movie?api_key=${
            process.env.VUE_APP_TMDB_API_KEY ||
            "a6a07bdb1ae12672ae2d301063d83c40"
          }&with_genres=28&language=en-US&sort_by=popularity.desc&page=${Math.floor(Math.random() * 50) + 1}`
        );
        this.actionItems = [...page1.data.results, ...page2.data.results]
          .slice(0, 20)
          .map(movie => ({
            id: movie.id,
            title: movie.title,
            poster_path: movie.poster_path,
            release_date: movie.release_date,
            overview: movie.overview,
            type: "movie",
            logo: null,
          }));
        // Optionally, process logos using the Fanart API.
        this.processLogos();
      } catch (error) {
        console.error("Error fetching action movies:", error);
      }
    },
    async fetchActionTvShows() {
      try {
        // For Action & Adventure TV shows, using genre id 10759.
        const page1 = await axios.get(
          `https://api.themoviedb.org/3/discover/tv?api_key=${
            process.env.VUE_APP_TMDB_API_KEY ||
            "a6a07bdb1ae12672ae2d301063d83c40"
          }&with_genres=10759&language=en-US&sort_by=popularity.desc&page=${Math.floor(Math.random() * 50) + 1}&vote_average.gte=8&vote_count.gte=50&with_origin_country=US`
        );
        const page2 = await axios.get(
          `https://api.themoviedb.org/3/discover/tv?api_key=${
            process.env.VUE_APP_TMDB_API_KEY ||
            "a6a07bdb1ae12672ae2d301063d83c40"
          }&with_genres=10759&language=en-US&sort_by=popularity.desc&page=${Math.floor(Math.random() * 50) + 1}&vote_average.gte=8&vote_count.gte=50&with_origin_country=US`
        );
        this.actionItems = [...page1.data.results, ...page2.data.results]
          .slice(0, 20)
          .map(show => ({
            id: show.id,
            title: show.name,
            poster_path: show.poster_path,
            release_date: show.first_air_date,
            overview: show.overview,
            type: "tv",
            logo: null,
          }));
        // Optionally, process logos using the Fanart API.
        this.processLogos();
      } catch (error) {
        console.error("Error fetching action TV shows:", error);
      }
    },
    async processLogos() {
      // For each item, attempt to fetch its logo using the Fanart API.
      for (let item of this.actionItems) {
        if (item.type === "movie") {
          const details = await this.fetchMovieDetails(item.id);
          if (details.imdb_id) {
            const logoUrl = await this.fetchMovieLogo(details.imdb_id);
            this.$set(item, "logo", logoUrl);
          }
        } else if (item.type === "tv") {
          const details = await this.fetchTVShowDetails(item.id);
          if (details.external_ids && details.external_ids.tvdb_id) {
            const logoUrl = await this.fetchTVShowLogo(details.external_ids.tvdb_id);
            this.$set(item, "logo", logoUrl);
          }
        }
      }
    },
    async fetchMovieDetails(movieId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/movie/${movieId}?api_key=${
            process.env.VUE_APP_TMDB_API_KEY ||
            "a6a07bdb1ae12672ae2d301063d83c40"
          }&language=en-US&append_to_response=external_ids`
        );
        return response.data;
      } catch (error) {
        console.error(`Failed to fetch details for movie ID ${movieId}:`, error);
        return {};
      }
    },
    async fetchTVShowDetails(tvId) {
      try {
        const response = await axios.get(
          `https://api.themoviedb.org/3/tv/${tvId}?api_key=${
            process.env.VUE_APP_TMDB_API_KEY ||
            "a6a07bdb1ae12672ae2d301063d83c40"
          }&language=en-US&append_to_response=external_ids`
        );
        return response.data;
      } catch (error) {
        console.error(`Failed to fetch details for TV show ID ${tvId}:`, error);
        return {};
      }
    },
    async fetchMovieLogo(imdbId) {
      if (!imdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/movies/${imdbId}?api_key=${this.fanartApiKey}`
        );
        const logos = response.data.hdmovielogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.error("Failed to fetch movie logo:", error);
        return null;
      }
    },
    async fetchTVShowLogo(tvdbId) {
      if (!tvdbId) return null;
      try {
        const response = await axios.get(
          `https://webservice.fanart.tv/v3/tv/${tvdbId}?api_key=${this.fanartApiKey}`
        );
        const logos = response.data.hdtvlogo || [];
        return logos.length > 0 ? logos[0].url : null;
      } catch (error) {
        console.error("Failed to fetch TV show logo:", error);
        return null;
      }
    },
    getImageUrl(path) {
      return path ? `https://image.tmdb.org/t/p/w500${path}` : "default_image.jpg";
    },
    async redirectToViewInfo(item) {
      this.isLoading = true;
      try {
        if (this.currentCategory === "tvShows") {
          const detailsUrl = `https://api.themoviedb.org/3/tv/${item.id}?api_key=${
            process.env.VUE_APP_TMDB_API_KEY || "a6a07bdb1ae12672ae2d301063d83c40"
          }&append_to_response=external_ids`;
          const response = await axios.get(detailsUrl);
          const imdbId =
            response.data.external_ids && response.data.external_ids.imdb_id
              ? response.data.external_ids.imdb_id
              : response.data.id;
          if (imdbId) {
            localStorage.setItem("imdbId", imdbId);
            localStorage.setItem("mediaType", "tv");
            this.$router.push(`/viewInfo`);
          } else {
            console.error("IMDb ID not found for the TV show.");
          }
        } else {
          const detailsUrl = `https://api.themoviedb.org/3/movie/${item.id}?api_key=${
            process.env.VUE_APP_TMDB_API_KEY || "a6a07bdb1ae12672ae2d301063d83c40"
          }`;
          const response = await axios.get(detailsUrl);
          const imdbId = response.data.imdb_id;
          if (imdbId) {
            localStorage.setItem("imdbId", imdbId);
            localStorage.setItem("mediaType", "movie");
            this.$router.push(`/viewInfo`);
          } else {
            console.error("IMDb ID not found for the movie.");
          }
        }
      } catch (error) {
        console.error("Error redirecting to view info:", error);
      } finally {
        this.isLoading = false;
      }
    },
    truncatedText(text, maxLength = 120) {
      return text && text.length > maxLength
        ? text.substring(0, maxLength) + "..."
        : text;
    },
    scrollLeft() {
      this.$refs.carousel.scrollBy({ left: -300, behavior: "smooth" });
    },
    scrollRight() {
      this.$refs.carousel.scrollBy({ left: 300, behavior: "smooth" });
    },
    checkCategory() {
      const storedCategory = localStorage.getItem("selectedCategory") || "movies";
      if (storedCategory !== this.currentCategory) {
        this.currentCategory = storedCategory;
        this.fetchActionItems();
      }
    },
    setActiveHoverCard(key) {
      this.activeHoverCard = key;
    },
    getUniqueKey(item, index) {
      return `${item.id}-${index}`;
    },
    async addToWatchlist(item) {
      // Add your Firebase watchlist logic here (or reuse your previous implementation)
      console.log("Adding item to watchlist", item);
    },
  },
  filters: {
    formatDate(dateString) {
      if (!dateString) return "";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style scoped>
/* -------- Fixed Y-axis Carousel Container -------- */
.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: 300px;
  overflow: hidden;
}

.carousel-row {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  padding: 1rem 3rem;
  height: 300px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel-row::-webkit-scrollbar {
  display: none;
}

.card-wrapper {
  position: relative;
  flex: 0 0 auto;
  width: 160px;
  margin: 0 0.5rem;
}

/* Arrow Button Styling */
.arrow-btn {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  color: #fff;
}
.left-arrow {
  left: 10px;
}
.right-arrow {
  right: 10px;
}

/* Hover Card Styles */
.custom-hover-card {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.custom-hover-card.hovered {
  position: absolute;
  top: -50px;
  left: 0;
  width: 300px;
  height: 350px;
  z-index: 999;
}
.poster-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hover-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.backdrop-wrapper {
  position: relative;
  width: 100%;
  height: 60%;
}
.backdrop-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.backdrop-gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 5;
}
.backdrop-title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 10;
  padding: 5px;
}
.logo-image {
  max-width: 200px;
  max-height: 80px;
}

.hover-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  box-sizing: border-box;
}
.hover-description .meta {
  font-size: 0.85rem;
  opacity: 0.9;
  margin-bottom: 0.5rem;
}
.hover-description .desc {
  font-size: 0.8rem;
  line-height: 1.2;
  margin-bottom: 0.8rem;
}
.info-actions {
  display: flex;
  gap: 0.5rem;
}
.watch-now-btn {
  background-color: #0CDFE5 !important;
  width: 200px;
  text-transform: none;
  font-weight: bold;
}
.plus-btn {
  background-color: rgba(255,255,255,0.2) !important;
  color: #fff !important;
}

/* Styled Heading with Left Bar */
.styled-heading {
  position: relative;
  color: white;
  font-weight: 600;
  margin-left: 80px;
  padding-left: 12px;
}
.styled-heading::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #0CDFE5;
}
</style>
