// import {initializeApp, auth} from "firebase/compat/app"
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyCs2WEHeHK7NdfBidjEKCICrdkFurtC6Og",
  authDomain: "unlocked-50e85.firebaseapp.com",
  projectId: "unlocked-50e85",
  storageBucket: "unlocked-50e85.firebasestorage.app",
  messagingSenderId: "116141068801",
  appId: "1:116141068801:web:e7fb73bb19c0959703e600"
};
export const firebaseApp = initializeApp(firebaseConfig);
// export const auth = firebaseApp.auth();

export const auth = getAuth();
