<template>
  <div class="dark-app">
    <!-- LEFT SIDEBAR as Nav Drawer -->
    <v-navigation-drawer
      v-model="drawer"
      width="20%"
      color="#131313"
      app
      class="sidebar-drawer"
    >
      <!-- Toolbar containing the search field -->
      <v-toolbar flat color="transparent">
        <v-text-field
          v-model="search"
          label="Search channels"
          solo
          dark
          hide-details
          append-icon="mdi-magnify"
          class="search-input"
        ></v-text-field>
      </v-toolbar>

      <!-- Category Filter Buttons -->
      <div class="category-buttons">
        <v-btn
          v-for="(cat, idx) in categories"
          :key="idx"
          small
          text
          :class="{ activeCategory: selectedCategory === cat }"
          @click="selectedCategory = cat"
        >
          {{ cat }}
        </v-btn>
      </div>

      <!-- Scrollable list container -->
      <div class="channel-list-container">
        <v-list dense nav class="channel-list">
          <v-list-item
            v-for="(channel, index) in filteredChannels"
            :key="index"
            dark
            class="channel-list-item"
            :class="{ activeChannel: selectedChannel && selectedChannel.name === channel.name }"
            @click="selectChannel(channel)"
          >
            <v-list-item-title>{{ channel.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>

    <!-- RIGHT SIDE: Main Content -->
    <v-container fluid class="main-content-col">
      <!-- Hamburger Icon above the player -->
      <v-btn dark icon @click="drawer = !drawer">
        <v-icon dark>mdi-menu</v-icon>
      </v-btn>

      <!-- Player Area -->
      <div class="player-container" v-if="selectedChannel">
        <div class="player-wrapper">
          <iframe
            :src="activeUrl"
            class="player-iframe"
            frameborder="0"
            scrolling="no"
            allowfullscreen
          ></iframe>
        </div>
        <!-- Info Overlay at bottom-left of the player -->
        <div class="player-info-overlay">
          <div class="channel-title">{{ selectedChannel.name }}</div>
          <div class="program-title">Current Program</div>
          <div class="program-subtitle">Details about the program...</div>
        </div>
      </div>

      <!-- Placeholder if no channel is selected -->
      <div v-else class="no-channel-selected">
        <h2>Please select a channel</h2>
      </div>

      <!-- Link-Switch Buttons (Primary / Backup 1-4) -->
      <div class="link-buttons" v-if="selectedChannel">
        <v-btn text :class="{ activeLink: activeLinkIndex === 0 }" @click="activeLinkIndex = 0">
          Primary
        </v-btn>
        <v-btn text :class="{ activeLink: activeLinkIndex === 1 }" @click="activeLinkIndex = 1">
          Backup 1
        </v-btn>
        <v-btn text :class="{ activeLink: activeLinkIndex === 2 }" @click="activeLinkIndex = 2">
          Backup 2
        </v-btn>
        <v-btn text :class="{ activeLink: activeLinkIndex === 3 }" @click="activeLinkIndex = 3">
          Backup 3
        </v-btn>
        <v-btn text :class="{ activeLink: activeLinkIndex === 4 }" @click="activeLinkIndex = 4">
          Backup 4
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: true, // Controls sidebar visibility
      search: "",
      selectedCategory: "All",
      selectedChannel: null,
      activeLinkIndex: 0, // 0=primary, 1-4=backups
      categories: ["All", "Entertainment", "Movies", "Sports", "News", "Kids", "Other"],
      rawChannels: [
  { name: "ABC USA", url: "https://thedaddy.to/embed/stream-51.php", category: "Entertainment" },
  { name: "A&E USA", url: "https://thedaddy.to/embed/stream-302.php", category: "Entertainment" },
  { name: "AMC USA", url: "https://thedaddy.to/embed/stream-303.php", category: "Movies" },
  { name: "Animal Planet", url: "https://thedaddy.to/embed/stream-304.php", category: "Kids" },
  { name: "ACC Network USA", url: "https://thedaddy.to/embed/stream-666.php", category: "Sports" },
  { name: "Astro SuperSport 1", url: "https://thedaddy.to/embed/stream-123.php", category: "Sports" },
  { name: "Astro SuperSport 2", url: "https://thedaddy.to/embed/stream-124.php", category: "Sports" },
  { name: "Astro SuperSport 3", url: "https://thedaddy.to/embed/stream-125.php", category: "Sports" },
  { name: "Astro SuperSport 4", url: "https://thedaddy.to/embed/stream-126.php", category: "Sports" },
  { name: "Arena Sport 1 Premium", url: "https://thedaddy.to/embed/stream-134.php", category: "Sports" },
  { name: "Arena Sport 2 Premium", url: "https://thedaddy.to/embed/stream-135.php", category: "Sports" },
  { name: "Arena Sport 3 Premium", url: "https://thedaddy.to/embed/stream-139.php", category: "Sports" },
  { name: "Arena Sport 1 Serbia", url: "https://thedaddy.to/embed/stream-429.php", category: "Sports" },
  { name: "Arena Sport 2 Serbia", url: "https://thedaddy.to/embed/stream-430.php", category: "Sports" },
  { name: "Arena Sport 3 Serbia", url: "https://thedaddy.to/embed/stream-431.php", category: "Sports" },
  { name: "Arena Sport 4 Serbia", url: "https://thedaddy.to/embed/stream-581.php", category: "Sports" },
  { name: "Arena Sport 1 Croatia", url: "https://thedaddy.to/embed/stream-432.php", category: "Sports" },
  { name: "Arena Sport 2 Croatia", url: "https://thedaddy.to/embed/stream-433.php", category: "Sports" },
  { name: "Arena Sport 3 Croatia", url: "https://thedaddy.to/embed/stream-434.php", category: "Sports" },
  { name: "Arena Sport 4 Croatia", url: "https://thedaddy.to/embed/stream-580.php", category: "Sports" },
  { name: "Alkass One", url: "https://thedaddy.to/embed/stream-781.php", category: "Sports" },
  { name: "Alkass Two", url: "https://thedaddy.to/embed/stream-782.php", category: "Sports" },
  { name: "Alkass Three", url: "https://thedaddy.to/embed/stream-783.php", category: "Sports" },
  { name: "Alkass Four", url: "https://thedaddy.to/embed/stream-784.php", category: "Sports" },
  { name: "ABS-CBN", url: "https://thedaddy.to/embed/stream-785.php", category: "Entertainment" },
  { name: "Arena Sport 1 BiH", url: "https://thedaddy.to/embed/stream-579.php", category: "Sports" },
  { name: "Abu Dhabi Sports 1 UAE", url: "https://thedaddy.to/embed/stream-600.php", category: "Sports" },
  { name: "Abu Dhabi Sports 2 UAE", url: "https://thedaddy.to/embed/stream-601.php", category: "Sports" },
  { name: "Abu Dhabi Sports 1 Premium", url: "https://thedaddy.to/embed/stream-609.php", category: "Sports" },
  { name: "Abu Dhabi Sports 2 Premium", url: "https://thedaddy.to/embed/stream-610.php", category: "Sports" },
  { name: "Astro Cricket", url: "https://thedaddy.to/embed/stream-370.php", category: "Sports" },
  { name: "Antena 3 Spain", url: "https://thedaddy.to/embed/stream-531.php", category: "Entertainment" },
  { name: "ACC Network USA", url: "https://thedaddy.to/embed/stream-664.php", category: "Sports" },
  { name: "Adult Swim", url: "https://thedaddy.to/embed/stream-295.php", category: "Entertainment" },
  { name: "AXN Movies Portugal", url: "https://thedaddy.to/embed/stream-717.php", category: "Movies" },
  { name: "Arte DE", url: "https://thedaddy.to/embed/stream-725.php", category: "Documentary" },
  { name: "AXS TV USA", url: "https://thedaddy.to/embed/stream-742.php", category: "Entertainment" },
  { name: "ABCNY USA", url: "https://thedaddy.to/embed/stream-766.php", category: "Entertainment" },
  { name: "beIN Sports MENA English 1", url: "https://thedaddy.to/embed/stream-61.php", category: "Sports" },
  { name: "beIN Sports MENA English 2", url: "https://thedaddy.to/embed/stream-90.php", category: "Sports" },
  { name: "beIN Sports MENA English 3", url: "https://thedaddy.to/embed/stream-46.php", category: "Sports" },
  { name: "beIN Sports MENA 1", url: "https://thedaddy.to/embed/stream-91.php", category: "Sports" },
  { name: "beIN Sports MENA 2", url: "https://thedaddy.to/embed/stream-92.php", category: "Sports" },
  { name: "beIN Sports MENA 3", url: "https://thedaddy.to/embed/stream-93.php", category: "Sports" },
  { name: "beIN Sports MENA 4", url: "https://thedaddy.to/embed/stream-94.php", category: "Sports" },
  { name: "beIN Sports MENA 5", url: "https://thedaddy.to/embed/stream-95.php", category: "Sports" },
  { name: "beIN Sports MENA 6", url: "https://thedaddy.to/embed/stream-96.php", category: "Sports" },
  { name: "beIN Sports MENA 7", url: "https://thedaddy.to/embed/stream-97.php", category: "Sports" },
  { name: "beIN Sports MENA Premium 1", url: "https://thedaddy.to/embed/stream-98.php", category: "Sports" },
  { name: "beIN Sports MENA Premium 2", url: "https://thedaddy.to/embed/stream-99.php", category: "Sports" },
  { name: "beIN Sports MENA Premium 3", url: "https://thedaddy.to/embed/stream-100.php", category: "Sports" },
  { name: "beIN Sports MAX 4 France", url: "https://thedaddy.to/embed/stream-494.php", category: "Sports" },
  { name: "beIN Sports MAX 5 France", url: "https://thedaddy.to/embed/stream-495.php", category: "Sports" },
  { name: "beIN Sports MAX 6 France", url: "https://thedaddy.to/embed/stream-496.php", category: "Sports" },
  { name: "beIN Sports MAX 7 France", url: "https://thedaddy.to/embed/stream-497.php", category: "Sports" },
  { name: "beIN Sports MAX 8 France", url: "https://thedaddy.to/embed/stream-498.php", category: "Sports" },
  { name: "beIN Sports MAX 9 France", url: "https://thedaddy.to/embed/stream-499.php", category: "Sports" },
  { name: "beIN Sports MAX 10 France", url: "https://thedaddy.to/embed/stream-500.php", category: "Sports" },
  { name: "beIN SPORTS 1 France", url: "https://thedaddy.to/embed/stream-116.php", category: "Sports" },
  { name: "beIN SPORTS 2 France", url: "https://thedaddy.to/embed/stream-117.php", category: "Sports" },
  { name: "beIN SPORTS 3 France", url: "https://thedaddy.to/embed/stream-118.php", category: "Sports" },
  { name: "beIN SPORTS 1 Turkey", url: "https://thedaddy.to/embed/stream-62.php", category: "Sports" },
  { name: "beIN SPORTS 2 Turkey", url: "https://thedaddy.to/embed/stream-63.php", category: "Sports" },
  { name: "beIN SPORTS 3 Turkey", url: "https://thedaddy.to/embed/stream-64.php", category: "Sports" },
  { name: "beIN SPORTS 4 Turkey", url: "https://thedaddy.to/embed/stream-67.php", category: "Sports" },
  { name: "BeIN Sports HD Qatar", url: "https://thedaddy.to/embed/stream-578.php", category: "Sports" },
  { name: "BeIN SPORTS USA", url: "https://thedaddy.to/embed/stream-425.php", category: "Sports" },
  { name: "beIN SPORTS en Espa単ol", url: "https://thedaddy.to/embed/stream-372.php", category: "Sports" },
  { name: "beIN SPORTS Australia 1", url: "https://thedaddy.to/embed/stream-491.php", category: "Sports" },
  { name: "beIN SPORTS Australia 2", url: "https://thedaddy.to/embed/stream-492.php", category: "Sports" },
  { name: "beIN SPORTS Australia 3", url: "https://thedaddy.to/embed/stream-493.php", category: "Sports" },
  { name: "Barca TV Spain", url: "https://thedaddy.to/embed/stream-522.php", category: "Sports" },
  { name: "Benfica TV PT", url: "https://thedaddy.to/embed/stream-380.php", category: "Sports" },
  { name: "Boomerang", url: "https://thedaddy.to/embed/stream-648.php", category: "Kids" },
  { name: "BNT 1 Bulgaria", url: "https://thedaddy.to/embed/stream-476.php", category: "Entertainment" },
  { name: "BNT 2 Bulgaria", url: "https://thedaddy.to/embed/stream-477.php", category: "Entertainment" },
  { name: "BNT 3 Bulgaria", url: "https://thedaddy.to/embed/stream-478.php", category: "Entertainment" },
  { name: "BR Fernsehen DE", url: "https://thedaddy.to/embed/stream-737.php", category: "Entertainment" },
  { name: "bTV Bulgaria", url: "https://thedaddy.to/embed/stream-479.php", category: "Entertainment" },
  { name: "bTV Action Bulgaria", url: "https://thedaddy.to/embed/stream-481.php", category: "Entertainment" },
  { name: "bTV Lady Bulgaria", url: "https://thedaddy.to/embed/stream-484.php", category: "Entertainment" },
  { name: "BBC America (BBCA)", url: "https://thedaddy.to/embed/stream-305.php", category: "Entertainment" },
  { name: "BET USA", url: "https://thedaddy.to/embed/stream-306.php", category: "Entertainment" },
  { name: "Bravo USA", url: "https://thedaddy.to/embed/stream-307.php", category: "Entertainment" },
  { name: "BBC News Channel HD", url: "https://thedaddy.to/embed/stream-349.php", category: "News" },
  { name: "BBC One UK", url: "https://thedaddy.to/embed/stream-356.php", category: "Entertainment" },
  { name: "BBC Two UK", url: "https://thedaddy.to/embed/stream-357.php", category: "Entertainment" },
  { name: "BBC Three UK", url: "https://thedaddy.to/embed/stream-358.php", category: "Entertainment" },
  { name: "BBC Four UK", url: "https://thedaddy.to/embed/stream-359.php", category: "Entertainment" },
  { name: "BIG TEN Network (BTN USA)", url: "https://thedaddy.to/embed/stream-397.php", category: "Sports" },
  { name: "Cuatro Spain", url: "https://thedaddy.to/embed/stream-535.php", category: "Entertainment" },
  { name: "Channel 4 UK", url: "https://thedaddy.to/embed/stream-354.php", category: "Entertainment" },
  { name: "Channel 5 UK", url: "https://thedaddy.to/embed/stream-355.php", category: "Entertainment" },
  { name: "CBS Sports Network (CBSSN)", url: "https://thedaddy.to/embed/stream-308.php", category: "Sports" },
  { name: "Canal+ France", url: "https://thedaddy.to/embed/stream-121.php", category: "Entertainment" },
  { name: "Canal+ Sport France", url: "https://thedaddy.to/embed/stream-122.php", category: "Sports" },
  { name: "Canal+ Foot France", url: "https://thedaddy.to/embed/stream-463.php", category: "Sports" },
  { name: "Canal+ Sport360", url: "https://thedaddy.to/embed/stream-464.php", category: "Sports" },
  { name: "Canal 11 Portugal", url: "https://thedaddy.to/embed/stream-540.php", category: "Entertainment" },
  { name: "Canal+ Sport Poland", url: "https://thedaddy.to/embed/stream-48.php", category: "Sports" },
  { name: "Canal+ Sport 2 Poland", url: "https://thedaddy.to/embed/stream-73.php", category: "Sports" },
  { name: "CANAL+ SPORT 5 Poland", url: "https://thedaddy.to/embed/stream-75.php", category: "Sports" },
  { name: "Canal+ Premium Poland", url: "https://thedaddy.to/embed/stream-566.php", category: "Sports" },
  { name: "Canal+ Family Poland", url: "https://thedaddy.to/embed/stream-567.php", category: "Entertainment" },
  { name: "Canal+ Seriale Poland", url: "https://thedaddy.to/embed/stream-570.php", category: "Entertainment" },
  { name: "Canal+ Sport 1 Afrique", url: "https://thedaddy.to/embed/stream-486.php", category: "Sports" },
  { name: "Canal+ Sport 2 Afrique", url: "https://thedaddy.to/embed/stream-487.php", category: "Sports" },
  { name: "Canal+ Sport 3 Afrique", url: "https://thedaddy.to/embed/stream-488.php", category: "Sports" },
  { name: "Canal+ Sport 4 Afrique", url: "https://thedaddy.to/embed/stream-489.php", category: "Sports" },
  { name: "Canal+ Sport 5 Afrique", url: "https://thedaddy.to/embed/stream-490.php", category: "Sports" },
  { name: "CANAL9 Denmark", url: "https://thedaddy.to/embed/stream-805.php", category: "Entertainment" },
  { name: "Combate Brasil", url: "https://thedaddy.to/embed/stream-89.php", category: "Sports" },
  { name: "C More Football Sweden", url: "https://thedaddy.to/embed/stream-747.php", category: "Sports" },
  { name: "Cosmote Sport 1 HD", url: "https://thedaddy.to/embed/stream-622.php", category: "Sports" },
  { name: "Cosmote Sport 2 HD", url: "https://thedaddy.to/embed/stream-623.php", category: "Sports" },
  { name: "Cosmote Sport 3 HD", url: "https://thedaddy.to/embed/stream-624.php", category: "Sports" },
  { name: "Cosmote Sport 4 HD", url: "https://thedaddy.to/embed/stream-625.php", category: "Sports" },
  { name: "Cosmote Sport 5 HD", url: "https://thedaddy.to/embed/stream-626.php", category: "Sports" },
  { name: "Cosmote Sport 6 HD", url: "https://thedaddy.to/embed/stream-627.php", category: "Sports" },
  { name: "Cosmote Sport 7 HD", url: "https://thedaddy.to/embed/stream-628.php", category: "Sports" },
  { name: "Cosmote Sport 8 HD", url: "https://thedaddy.to/embed/stream-629.php", category: "Sports" },
  { name: "Cosmote Sport 9 HD", url: "https://thedaddy.to/embed/stream-630.php", category: "Sports" },
  { name: "Channel 9 Israel", url: "https://thedaddy.to/embed/stream-546.php", category: "Entertainment" },
  { name: "Channel 10 Israe", url: "https://thedaddy.to/embed/stream-547.php", category: "Entertainment" },
  { name: "Channel 11 Israel", url: "https://thedaddy.to/embed/stream-548.php", category: "Entertainment" },
  { name: "Channel 12 Israel", url: "https://thedaddy.to/embed/stream-549.php", category: "Entertainment" },
  { name: "Channel 13 Israel", url: "https://thedaddy.to/embed/stream-551.php", category: "Entertainment" },
  { name: "Channel 14 Israel", url: "https://thedaddy.to/embed/stream-552.php", category: "Entertainment" },
  { name: "C More Stars Sweden", url: "https://thedaddy.to/embed/stream-8111.php", category: "Entertainment" },
  { name: "C More First Sweden", url: "https://thedaddy.to/embed/stream-812.php", category: "Entertainment" },
  { name: "C More Hits Sweden", url: "https://thedaddy.to/embed/stream-813.php", category: "Entertainment" },
  { name: "C More Series Sweden", url: "https://thedaddy.to/embed/stream-814.php", category: "Entertainment" },
  { name: "COZI TV USA", url: "https://thedaddy.to/embed/stream-748.php", category: "Entertainment" },
  { name: "CMT USA", url: "https://thedaddy.to/embed/stream-647.php", category: "Entertainment" },
  { name: "CBS USA", url: "https://thedaddy.to/embed/stream-52.php", category: "Entertainment" },
  { name: "CW USA", url: "https://thedaddy.to/embed/stream-300.php", category: "Entertainment" },
  { name: "CW PIX 11 USA", url: "https://thedaddy.to/embed/stream-280.php", category: "Entertainment" },
  { name: "CNBC USA", url: "https://thedaddy.to/embed/stream-309.php", category: "Business" },
  { name: "Comedy Central", url: "https://thedaddy.to/embed/stream-310.php", category: "Entertainment" },
  { name: "Cartoon Network", url: "https://thedaddy.to/embed/stream-339.php", category: "Kids" },
  { name: "CNN USA", url: "https://thedaddy.to/embed/stream-345.php", category: "News" },
  { name: "Cinemax USA", url: "https://thedaddy.to/embed/stream-374.php", category: "Movies" },
  { name: "CTV Canada", url: "https://thedaddy.to/embed/stream-602.php", category: "Entertainment" },
  { name: "CTV 2 Canada", url: "https://thedaddy.to/embed/stream-838.php", category: "Entertainment" },
  { name: "Crime+ Investigation USA", url: "https://thedaddy.to/embed/stream-669.php", category: "Documentary" },
  { name: "Comet USA", url: "https://thedaddy.to/embed/stream-696.php", category: "Entertainment" },
  { name: "Cooking Channel USA", url: "https://thedaddy.to/embed/stream-697.php", category: "Lifestyle" },
  { name: "Cleo TV", url: "https://thedaddy.to/embed/stream-715.php", category: "Entertainment" },
  { name: "C SPAN 1", url: "https://thedaddy.to/embed/stream-750.php", category: "News" },
  { name: "CBSNY USA", url: "https://thedaddy.to/embed/stream-767.php", category: "Entertainment" },
  { name: "Citytv", url: "https://thedaddy.to/embed/stream-831.php", category: "Entertainment" },
  { name: "CBC CA", url: "https://thedaddy.to/embed/stream-832.php", category: "News" },
  { name: "DAZN 1 Bar DE", url: "https://thedaddy.to/embed/stream-426.php", category: "Sports" },
  { name: "DAZN 2 Bar DE", url: "https://thedaddy.to/embed/stream-427.php", category: "Sports" },
  { name: "DAZN 1 Spain", url: "https://thedaddy.to/embed/stream-445.php", category: "Sports" },
  { name: "DAZN 2 Spain", url: "https://thedaddy.to/embed/stream-446.php", category: "Sports" },
  { name: "DAZN 3 Spain", url: "https://thedaddy.to/embed/stream-447.php", category: "Sports" },
  { name: "DAZN 4 Spain", url: "https://thedaddy.to/embed/stream-448.php", category: "Sports" },
  { name: "DAZN F1 ES", url: "https://thedaddy.to/embed/stream-537.php", category: "Sports" },
  { name: "DAZN LaLiga", url: "https://thedaddy.to/embed/stream-538.php", category: "Sports" },
  { name: "DAZN LaLiga 2", url: "https://thedaddy.to/embed/stream-43.php", category: "Sports" },
  { name: "DR1 Denmark", url: "https://thedaddy.to/embed/stream-801.php", category: "Entertainment" },
  { name: "DR2 Denmark", url: "https://thedaddy.to/embed/stream-802.php", category: "Entertainment" },
  { name: "Digi Sport 1 Romania", url: "https://thedaddy.to/embed/stream-400.php", category: "Sports" },
  { name: "Digi Sport 2 Romania", url: "https://thedaddy.to/embed/stream-401.php", category: "Sports" },
  { name: "Digi Sport 3 Romania", url: "https://thedaddy.to/embed/stream-402.php", category: "Sports" },
  { name: "Digi Sport 4 Romania", url: "https://thedaddy.to/embed/stream-403.php", category: "Sports" },
  { name: "Diema Sport Bulgaria", url: "https://thedaddy.to/embed/stream-465.php", category: "Sports" },
  { name: "Diema Sport 2 Bulgaria", url: "https://thedaddy.to/embed/stream-466.php", category: "Sports" },
  { name: "Diema Sport 3 Bulgaria", url: "https://thedaddy.to/embed/stream-467.php", category: "Sports" },
  { name: "Diema Bulgaria", url: "https://thedaddy.to/embed/stream-482.php", category: "Entertainment" },
  { name: "Diema Family Bulgaria", url: "https://thedaddy.to/embed/stream-485.php", category: "Entertainment" },
  { name: "Dubai Sports 1 UAE", url: "https://thedaddy.to/embed/stream-604.php", category: "Sports" },
  { name: "Dubai Sports 2 UAE", url: "https://thedaddy.to/embed/stream-605.php", category: "Sports" },
  { name: "Dubai Sports 3 UAE", url: "https://thedaddy.to/embed/stream-606.php", category: "Sports" },
  { name: "Dubai Racing 1 UAE", url: "https://thedaddy.to/embed/stream-607.php", category: "Sports" },
  { name: "Dubai Racing 2 UAE", url: "https://thedaddy.to/embed/stream-608.php", category: "Sports" },
  { name: "DSTV Mzansi Magic", url: "https://thedaddy.to/embed/stream-786.php", category: "Entertainment" },
  { name: "DSTV M-Net", url: "https://thedaddy.to/embed/stream-827.php", category: "Entertainment" },
  { name: "DSTV kykNET & kie", url: "https://thedaddy.to/embed/stream-828.php", category: "Entertainment" },
  { name: "Discovery Life Channel", url: "https://thedaddy.to/embed/stream-311.php", category: "Entertainment" },
  { name: "Disney Channel", url: "https://thedaddy.to/embed/stream-312.php", category: "Kids" },
  { name: "Discovery Channel", url: "https://thedaddy.to/embed/stream-313.php", category: "Documentary" },
  { name: "Discovery Family", url: "https://thedaddy.to/embed/stream-657.php", category: "Entertainment" },
  { name: "Disney XD", url: "https://thedaddy.to/embed/stream-314.php", category: "Kids" },
  { name: "Discovery Velocity CA", url: "https://thedaddy.to/embed/stream-285.php", category: "Sports" },
  { name: "Destination America", url: "https://thedaddy.to/embed/stream-651.php", category: "Entertainment" },
  { name: "Disney JR", url: "https://thedaddy.to/embed/stream-652.php", category: "Kids" },
  { name: "Dave", url: "https://thedaddy.to/embed/stream-348.php", category: "Entertainment" },
  { name: "ESPN USA", url: "https://thedaddy.to/embed/stream-44.php", category: "Sports" },
  { name: "ESPN2 USA", url: "https://thedaddy.to/embed/stream-45.php", category: "Sports" },
  { name: "ESPNU USA", url: "https://thedaddy.to/embed/stream-316.php", category: "Sports" },
  { name: "ESPN 1 NL", url: "https://thedaddy.to/embed/stream-379.php", category: "Sports" },
  { name: "ESPN 2 NL", url: "https://thedaddy.to/embed/stream-386.php", category: "Sports" },
  { name: "Eleven Sports 1 Poland", url: "https://thedaddy.to/embed/stream-71.php", category: "Sports" },
  { name: "Eleven Sports 2 Poland", url: "https://thedaddy.to/embed/stream-72.php", category: "Sports" },
  { name: "Eleven Sports 3 Poland", url: "https://thedaddy.to/embed/stream-428.php", category: "Sports" },
  { name: "Eleven Sports 1 Portugal", url: "https://thedaddy.to/embed/stream-455.php", category: "Sports" },
  { name: "Eleven Sports 2 Portugal", url: "https://thedaddy.to/embed/stream-456.php", category: "Sports" },
  { name: "Eleven Sports 3 Portugal", url: "https://thedaddy.to/embed/stream-457.php", category: "Sports" },
  { name: "Eleven Sports 4 Portugal", url: "https://thedaddy.to/embed/stream-458.php", category: "Sports" },
  { name: "Eleven Sports 5 Portugal", url: "https://thedaddy.to/embed/stream-459.php", category: "Sports" },
  { name: "EuroSport 1 UK", url: "https://thedaddy.to/embed/stream-41.php", category: "Sports" },
  { name: "EuroSport 2 UK", url: "https://thedaddy.to/embed/stream-42.php", category: "Sports" },
  { name: "EuroSport 1 Poland", url: "https://thedaddy.to/embed/stream-57.php", category: "Sports" },
  { name: "EuroSport 2 Poland", url: "https://thedaddy.to/embed/stream-58.php", category: "Sports" },
  { name: "EuroSport 1 Spain", url: "https://thedaddy.to/embed/stream-524.php", category: "Sports" },
  { name: "EuroSport 2 Spain", url: "https://thedaddy.to/embed/stream-525.php", category: "Sports" },
  { name: "EuroSport 1 Italy", url: "https://thedaddy.to/embed/stream-878.php", category: "Sports" },
  { name: "EuroSport 2 Italy", url: "https://thedaddy.to/embed/stream-879.php", category: "Sports" },
  { name: "Eurosport 1 Bulgaria", url: "https://thedaddy.to/embed/stream-469.php", category: "Sports" },
  { name: "Eurosport 2 Bulgaria", url: "https://thedaddy.to/embed/stream-470.php", category: "Sports" },
  { name: "ESPN Premium Argentina", url: "https://thedaddy.to/embed/stream-387.php", category: "Sports" },
  { name: "ESPN Brasil", url: "https://thedaddy.to/embed/stream-81.php", category: "Sports" },
  { name: "ESPN2 Brasil", url: "https://thedaddy.to/embed/stream-82.php", category: "Sports" },
  { name: "ESPN3 Brasil", url: "https://thedaddy.to/embed/stream-83.php", category: "Sports" },
  { name: "ESPN4 Brasil", url: "https://thedaddy.to/embed/stream-85.php", category: "Sports" },
  { name: "ESPN SUR", url: "https://thedaddy.to/embed/stream-149.php", category: "Sports" },
  { name: "ESPN2 SUR", url: "https://thedaddy.to/embed/stream-150.php", category: "Sports" },
  { name: "ESPN Deportes", url: "https://thedaddy.to/embed/stream-375.php", category: "Sports" },
  { name: "ESPNews", url: "https://thedaddy.to/embed/stream-288.php", category: "News" },
  { name: "E! Entertainment Television", url: "https://thedaddy.to/embed/stream-315.php", category: "Entertainment" },
  { name: "E4 Channel", url: "https://thedaddy.to/embed/stream-363.php", category: "Entertainment" },
  { name: "Fox Sports 1 USA", url: "https://thedaddy.to/embed/stream-39.php", category: "Sports" },
  { name: "Fox Sports 2 USA", url: "https://thedaddy.to/embed/stream-758.php", category: "Sports" },
  { name: "FOX Soccer Plus", url: "https://thedaddy.to/embed/stream-756.php", category: "Sports" },
  { name: "Fox Cricket", url: "https://thedaddy.to/embed/stream-369.php", category: "Sports" },
  { name: "FOX Deportes USA", url: "https://thedaddy.to/embed/stream-643.php", category: "Sports" },
  { name: "FOX Sports 502 AU", url: "https://thedaddy.to/embed/stream-820.php", category: "Sports" },
  { name: "FOX Sports 503 AU", url: "https://thedaddy.to/embed/stream-821.php", category: "Sports" },
  { name: "FOX Sports 504 AU", url: "https://thedaddy.to/embed/stream-822.php", category: "Sports" },
  { name: "FOX Sports 505 AU", url: "https://thedaddy.to/embed/stream-823.php", category: "Sports" },
  { name: "FOX Sports 506 AU", url: "https://thedaddy.to/embed/stream-824.php", category: "Sports" },
  { name: "FOX Sports 507 AU", url: "https://thedaddy.to/embed/stream-825.php", category: "Sports" },
  { name: "Fox Sports Argentina", url: "https://thedaddy.to/embed/stream-767.php", category: "Sports" },
  { name: "Fox Sports 2 Argentina", url: "https://thedaddy.to/embed/stream-788.php", category: "Sports" },
  { name: "Fox Sports 3 Argentina", url: "https://thedaddy.to/embed/stream-789.php", category: "Sports" },
  { name: "Fox Sports Premium MX", url: "https://thedaddy.to/embed/stream-830.php", category: "Sports" },
  { name: "FilmBox Premium Poland", url: "https://thedaddy.to/embed/stream-568.php", category: "Movies" },
  { name: "Fight Network", url: "https://thedaddy.to/embed/stream-757.php", category: "Sports" },
  { name: "Fox Business", url: "https://thedaddy.to/embed/stream-297.php", category: "Business" },
  { name: "FOX HD Bulgaria", url: "https://thedaddy.to/embed/stream-483.php", category: "Entertainment" },
  { name: "FOX USA", url: "https://thedaddy.to/embed/stream-54.php", category: "Entertainment" },
  { name: "FX USA", url: "https://thedaddy.to/embed/stream-317.php", category: "Entertainment" },
  { name: "FXX USA", url: "https://thedaddy.to/embed/stream-298.php", category: "Entertainment" },
  { name: "Freeform", url: "https://thedaddy.to/embed/stream-301.php", category: "Entertainment" },
  { name: "Fox News", url: "https://thedaddy.to/embed/stream-347.php", category: "News" },
  { name: "FX Movie Channel", url: "https://thedaddy.to/embed/stream-381.php", category: "Movies" },
  { name: "FYI", url: "https://thedaddy.to/embed/stream-665.php", category: "Lifestyle" },
  { name: "Film4 UK", url: "https://thedaddy.to/embed/stream-688.php", category: "Movies" },
  { name: "Fashion TV", url: "https://thedaddy.to/embed/stream-744.php", category: "Entertainment" },
  { name: "FETV - Family Entertainment Television", url: "https://thedaddy.to/embed/stream-751.php", category: "Entertainment" },
  { name: "FOXNY USA", url: "https://thedaddy.to/embed/stream-768.php", category: "Entertainment" },
  { name: "Fox Weather Channel", url: "https://thedaddy.to/embed/stream-775.php", category: "News" },
  { name: "FUSE TV USA", url: "https://thedaddy.to/embed/stream-279.php", category: "Entertainment" },
  { name: "GOL PLAY Spain", url: "https://thedaddy.to/embed/stream-530.php", category: "Sports" },
  { name: "GOLF Channel USA", url: "https://thedaddy.to/embed/stream-318.php", category: "Sports" },
  { name: "Game Show Network", url: "https://thedaddy.to/embed/stream-319.php", category: "Entertainment" },
  { name: "Gol Mundial 1", url: "https://thedaddy.to/embed/stream-292.php", category: "Sports" },
  { name: "Gold UK", url: "https://thedaddy.to/embed/stream-687.php", category: "Entertainment" },
  { name: "Galavisión USA", url: "https://thedaddy.to/embed/stream-743.php", category: "Entertainment" },
  { name: "CBC CA", url: "https://thedaddy.to/embed/stream-699.php", category: "Entertainment" },
  { name: "Grit Channel", url: "https://thedaddy.to/embed/stream-752.php", category: "Entertainment" },
  { name: "Globo SP", url: "https://thedaddy.to/embed/stream-760.php", category: "Entertainment" },
  { name: "Globo RIO", url: "https://thedaddy.to/embed/stream-761.php", category: "Entertainment" },
  { name: "Global CA", url: "https://thedaddy.to/embed/stream-836.php", category: "Entertainment" },
  { name: "The Hallmark Channel", url: "https://thedaddy.to/embed/stream-320.php", category: "Entertainment" },
  { name: "Hallmark Movies & Mysterie", url: "https://thedaddy.to/embed/stream-296.php", category: "Movies" },
  { name: "HBO USA", url: "https://thedaddy.to/embed/stream-321.php", category: "Entertainment" },
  { name: "HBO2 USA", url: "https://thedaddy.to/embed/stream-689.php", category: "Entertainment" },
  { name: "HBO Comedy USA", url: "https://thedaddy.to/embed/stream-690.php", category: "Entertainment" },
  { name: "HBO Family USA", url: "https://thedaddy.to/embed/stream-691.php", category: "Entertainment" },
  { name: "HBO Latino USA", url: "https://thedaddy.to/embed/stream-692.php", category: "Entertainment" },
  { name: "HBO Signature USA", url: "https://thedaddy.to/embed/stream-693.php", category: "Entertainment" },
  { name: "HBO Zone USA", url: "https://thedaddy.to/embed/stream-694.php", category: "Entertainment" },
  { name: "HBO Poland", url: "https://thedaddy.to/embed/stream-569.php", category: "Entertainment" },
  { name: "History USA", url: "https://thedaddy.to/embed/stream-322.php", category: "Documentary" },
  { name: "Headline News", url: "https://thedaddy.to/embed/stream-323.php", category: "News" },
  { name: "HGTV", url: "https://thedaddy.to/embed/stream-382.php", category: "Lifestyle" },
  { name: "HOT3 Israel", url: "https://thedaddy.to/embed/stream-553.php", category: "Entertainment" },
  { name: "HR Fernsehen DE", url: "https://thedaddy.to/embed/stream-740.php", category: "Entertainment" },
  { name: "ITV 1 UK", url: "https://thedaddy.to/embed/stream-350.php", category: "Entertainment" },
  { name: "ITV 2 UK", url: "https://thedaddy.to/embed/stream-351.php", category: "Entertainment" },
  { name: "ITV 3 UK", url: "https://thedaddy.to/embed/stream-352.php", category: "Entertainment" },
  { name: "ITV 4 UK", url: "https://thedaddy.to/embed/stream-353.php", category: "Entertainment" },
  { name: "Italia 1 Italy", url: "https://thedaddy.to/embed/stream-854.php", category: "Entertainment" },
  { name: "Investigation Discovery (ID USA)", url: "https://thedaddy.to/embed/stream-324.php", category: "Documentary" },
  { name: "ION USA", url: "https://thedaddy.to/embed/stream-325.php", category: "Entertainment" },
  { name: "IFC TV USA", url: "https://thedaddy.to/embed/stream-656.php", category: "Entertainment" },
  { name: "Kanal 4 Denmark", url: "https://thedaddy.to/embed/stream-803.php", category: "Entertainment" },
  { name: "Kanal 5 Denmark", url: "https://thedaddy.to/embed/stream-804.php", category: "Entertainment" },
  { name: "Kabel Eins (Kabel 1) DE", url: "https://thedaddy.to/embed/stream-731.php", category: "Entertainment" },
  { name: "LaLiga SmartBank TV", url: "https://thedaddy.to/embed/stream-539.php", category: "Sports" },
  { name: "L'Equipe France", url: "https://thedaddy.to/embed/stream-645.php", category: "Sports" },
  { name: "La Sexta Spain", url: "https://thedaddy.to/embed/stream-534.php", category: "Entertainment" },
  { name: "Liverpool TV (LFC TV)", url: "https://thedaddy.to/embed/stream-826.php", category: "Sports" },
  { name: "Lifetime Network", url: "https://thedaddy.to/embed/stream-326.php", category: "Entertainment" },
  { name: "Lifetime Movies Network", url: "https://thedaddy.to/embed/stream-389.php", category: "Movies" },
  { name: "Longhorn Network USA", url: "https://thedaddy.to/embed/stream-667.php", category: "Sports" },
  { name: "La7 Italy", url: "https://thedaddy.to/embed/stream-855.php", category: "Entertainment" },
  { name: "LA7d HD+ Italy", url: "https://thedaddy.to/embed/stream-856.php", category: "Entertainment" },
  { name: "Law & Crime Network", url: "https://thedaddy.to/embed/stream-278.php", category: "True Crime" },
  { name: "Match Football 1 Russia", url: "https://thedaddy.to/embed/stream-136.php", category: "Sports" },
  { name: "Match Football 2 Russia", url: "https://thedaddy.to/embed/stream-137.php", category: "Sports" },
  { name: "Match Football 3 Russia", url: "https://thedaddy.to/embed/stream-138.php", category: "Sports" },
  { name: "Match Premier Russia", url: "https://thedaddy.to/embed/stream-573.php", category: "Sports" },
  { name: "Match TV Russia", url: "https://thedaddy.to/embed/stream-127.php", category: "Sports" },
  { name: "МАТЧ! БОЕЦ Russia", url: "https://thedaddy.to/embed/stream-395.php", category: "Sports" },
  { name: "Movistar Laliga", url: "https://thedaddy.to/embed/stream-84.php", category: "Sports" },
  { name: "Movistar Liga de Campeones", url: "https://thedaddy.to/embed/stream-435.php", category: "Sports" },
  { name: "Movistar Deportes Spain", url: "https://thedaddy.to/embed/stream-436.php", category: "Sports" },
  { name: "Movistar Deportes 2 Spain", url: "https://thedaddy.to/embed/stream-438.php", category: "Sports" },
  { name: "Movistar Deportes 3 Spain", url: "https://thedaddy.to/embed/stream-526.php", category: "Sports" },
  { name: "Movistar Deportes 4 Spain", url: "https://thedaddy.to/embed/stream-527.php", category: "Sports" },
  { name: "Movistar Golf Spain", url: "https://thedaddy.to/embed/stream-528.php", category: "Sports" },
  { name: "Motowizja Poland", url: "https://thedaddy.to/embed/stream-563.php", category: "Sports" },
  { name: "MSG USA", url: "https://thedaddy.to/embed/stream-765.php", category: "Sports" },
  { name: "MSNBC", url: "https://thedaddy.to/embed/stream-327.php", category: "News" },
  { name: "Magnolia Network", url: "https://thedaddy.to/embed/stream-299.php", category: "Entertainment" },
  { name: "MTV UK", url: "https://thedaddy.to/embed/stream-367.php", category: "Entertainment" },
  { name: "MTV USA", url: "https://thedaddy.to/embed/stream-371.php", category: "Entertainment" },
  { name: "MUTV UK", url: "https://thedaddy.to/embed/stream-377.php", category: "Sports" },
  { name: "MAVTV USA", url: "https://thedaddy.to/embed/stream-646.php", category: "Sports" },
  { name: "Max Sport 1 Croatia", url: "https://thedaddy.to/embed/stream-779.php", category: "Sports" },
  { name: "Max Sport 2 Croatia", url: "https://thedaddy.to/embed/stream-780.php", category: "Sports" },
  { name: "Marquee Sports Network", url: "https://thedaddy.to/embed/stream-770.php", category: "Sports" },
  { name: "Max Sport 1 Bulgaria", url: "https://thedaddy.to/embed/stream-472.php", category: "Sports" },
  { name: "Max Sport 2 Bulgaria", url: "https://thedaddy.to/embed/stream-473.php", category: "Sports" },
  { name: "Max Sport 3 Bulgaria", url: "https://thedaddy.to/embed/stream-474.php", category: "Sports" },
  { name: "Max Sport 4 Bulgaria", url: "https://thedaddy.to/embed/stream-475.php", category: "Sports" },
  { name: "MLB Network USA", url: "https://thedaddy.to/embed/stream-399.php", category: "Sports" },
  { name: "MASN USA", url: "https://thedaddy.to/embed/stream-829.php", category: "Sports" },
  { name: "MY9TV USA", url: "https://thedaddy.to/embed/stream-654.php", category: "Entertainment" },
  { name: "Motor Trend", url: "https://thedaddy.to/embed/stream-661.php", category: "Lifestyle" },
  { name: "METV USA", url: "https://thedaddy.to/embed/stream-662.php", category: "Entertainment" },
  { name: "MDR DE", url: "https://thedaddy.to/embed/stream-733.php", category: "Entertainment" },
  { name: "Mundotoro TV Spain", url: "https://thedaddy.to/embed/stream-749.php", category: "Entertainment" },
  { name: "MTV Denmark", url: "https://thedaddy.to/embed/stream-806.php", category: "Entertainment" },
  { name: "NHL Network USA", url: "https://thedaddy.to/embed/stream-663.php", category: "Sports" },
  { name: "Nova Sport Bulgaria", url: "https://thedaddy.to/embed/stream-468.php", category: "Sports" },
  { name: "Nova Sport Serbia", url: "https://thedaddy.to/embed/stream-582.php", category: "Sports" },
  { name: "Nova Sports 1 Greece", url: "https://thedaddy.to/embed/stream-631.php", category: "Sports" },
  { name: "Nova Sports 2 Greece", url: "https://thedaddy.to/embed/stream-632.php", category: "Sports" },
  { name: "Nova Sports 3 Greece", url: "https://thedaddy.to/embed/stream-633.php", category: "Sports" },
  { name: "Nova Sports 4 Greece", url: "https://thedaddy.to/embed/stream-634.php", category: "Sports" },
  { name: "Nova Sports 5 Greece", url: "https://thedaddy.to/embed/stream-635.php", category: "Sports" },
  { name: "Nova Sports 6 Greece", url: "https://thedaddy.to/embed/stream-636.php", category: "Sports" },
  { name: "Nova Sports Premier League Greece", url: "https://thedaddy.to/embed/stream-599.php", category: "Sports" },
  { name: "Nova Sports Start Greece", url: "https://thedaddy.to/embed/stream-637.php", category: "Sports" },
  { name: "Nova Sports Prime Greece", url: "https://thedaddy.to/embed/stream-638.php", category: "Sports" },
  { name: "Nova Sports News Greece", url: "https://thedaddy.to/embed/stream-639.php", category: "News" },
  { name: "NESN USA", url: "https://thedaddy.to/embed/stream-762.php", category: "Sports" },
  { name: "NBC USA", url: "https://thedaddy.to/embed/stream-53.php", category: "Entertainment" },
  { name: "NBA TV USA", url: "https://thedaddy.to/embed/stream-404.php", category: "Sports" },
  { name: "NBC Sports Chicago", url: "https://thedaddy.to/embed/stream-776.php", category: "Sports" },
  { name: "NBC Sports Philadelphia", url: "https://thedaddy.to/embed/stream-777.php", category: "Sports" },
  { name: "NBC Sports Washington", url: "https://thedaddy.to/embed/stream-778.php", category: "Sports" },
  { name: "NBC10 Philadelphia", url: "https://thedaddy.to/embed/stream-277.php", category: "News" },
  { name: "NFL Network", url: "https://thedaddy.to/embed/stream-405.php", category: "Sports" },
  { name: "NBC Sports Bay Area", url: "https://thedaddy.to/embed/stream-753.php", category: "Sports" },
  { name: "NBC Sports Boston", url: "https://thedaddy.to/embed/stream-754.php", category: "Sports" },
  { name: "NBC Sports California", url: "https://thedaddy.to/embed/stream-755.php", category: "Sports" },
  { name: "NBCNY USA", url: "https://thedaddy.to/embed/stream-769.php", category: "Entertainment" },
  { name: "Nova TV Bulgaria", url: "https://thedaddy.to/embed/stream-480.php", category: "Entertainment" },
  { name: "National Geographic (NGC)", url: "https://thedaddy.to/embed/stream-328.php", category: "Documentary" },
  { name: "NICK JR", url: "https://thedaddy.to/embed/stream-329.php", category: "Kids" },
  { name: "NICK", url: "https://thedaddy.to/embed/stream-330.php", category: "Kids" },
  { name: "Nick Music", url: "https://thedaddy.to/embed/stream-666.php", category: "Kids" },
  { name: "Nicktoons", url: "https://thedaddy.to/embed/stream-649.php", category: "Kids" },
  { name: "NDR DE", url: "https://thedaddy.to/embed/stream-736.php", category: "Entertainment" },
  { name: "NewsNation USA", url: "https://thedaddy.to/embed/stream-292.php", category: "News" },
  { name: "Newsmax USA", url: "https://thedaddy.to/embed/stream-613.php", category: "News" },
  { name: "Nat Geo Wild USA", url: "https://thedaddy.to/embed/stream-745.php", category: "Documentary" },
  { name: "Noovo CA", url: "https://thedaddy.to/embed/stream-835.php", category: "Entertainment" },
  { name: "New! CWPIX 11", url: "https://thedaddy.to/embed/stream-771.php", category: "Entertainment" },
  { name: "OnTime Sports", url: "https://thedaddy.to/embed/stream-611.php", category: "Sports" },
  { name: "OnTime Sports 2", url: "https://thedaddy.to/embed/stream-612.php", category: "Sports" },
  { name: "ONE 1 HD Israel", url: "https://thedaddy.to/embed/stream-541.php", category: "Entertainment" },
  { name: "ONE 2 HD Israel", url: "https://thedaddy.to/embed/stream-542.php", category: "Entertainment" },
  { name: "Orange Sport 1 Romania", url: "https://thedaddy.to/embed/stream-439.php", category: "Sports" },
  { name: "Orange Sport 2 Romania", url: "https://thedaddy.to/embed/stream-440.php", category: "Sports" },
  { name: "Orange Sport 3 Romania", url: "https://thedaddy.to/embed/stream-441.php", category: "Sports" },
  { name: "Orange Sport 4 Romania", url: "https://thedaddy.to/embed/stream-442.php", category: "Sports" },
  { name: "Oprah Winfrey Network (OWN)", url: "https://thedaddy.to/embed/stream-331.php", category: "Entertainment" },
  { name: "Oxygen True Crime", url: "https://thedaddy.to/embed/stream-332.php", category: "True Crime" },
  { name: "Premier Sports Ireland 1", url: "https://thedaddy.to/embed/stream-771.php", category: "Sports" },
  { name: "Premier Sports Ireland 2", url: "https://thedaddy.to/embed/stream-799.php", category: "Sports" },
  { name: "Polsat Poland", url: "https://thedaddy.to/embed/stream-562.php", category: "Entertainment" },
  { name: "Polsat Sport Poland", url: "https://thedaddy.to/embed/stream-47.php", category: "Sports" },
  { name: "Polsat Sport Extra Poland", url: "https://thedaddy.to/embed/stream-50.php", category: "Sports" },
  { name: "Polsat Sport News Poland", url: "https://thedaddy.to/embed/stream-129.php", category: "News" },
  { name: "Polsat News Poland", url: "https://thedaddy.to/embed/stream-443.php", category: "News" },
  { name: "Polsat Film Poland", url: "https://thedaddy.to/embed/stream-564.php", category: "Movies" },
  { name: "Porto Canal Portugal", url: "https://thedaddy.to/embed/stream-718.php", category: "Entertainment" },
  { name: "ProSieben (PRO7) DE", url: "https://thedaddy.to/embed/stream-730.php", category: "Entertainment" },
  { name: "Prima TV RO", url: "https://thedaddy.to/embed/stream-843.php", category: "Entertainment" },
  { name: "PTV Sports", url: "https://thedaddy.to/embed/stream-450.php", category: "Sports" },
  { name: "Pac-12 Network USA", url: "https://thedaddy.to/embed/stream-287.php", category: "Sports" },
  { name: "Premier Brasil", url: "https://thedaddy.to/embed/stream-88.php", category: "Entertainment" },
  { name: "Prima Sport 1", url: "https://thedaddy.to/embed/stream-583.php", category: "Sports" },
  { name: "Prima Sport 2", url: "https://thedaddy.to/embed/stream-584.php", category: "Sports" },
  { name: "Prima Sport 3", url: "https://thedaddy.to/embed/stream-585.php", category: "Sports" },
  { name: "Prima Sport 4", url: "https://thedaddy.to/embed/stream-586.php", category: "Sports" },
  { name: "Paramount Network", url: "https://thedaddy.to/embed/stream-334.php", category: "Entertainment" },
  { name: "POP TV USA", url: "https://thedaddy.to/embed/stream-653.php", category: "Entertainment" },
  { name: "PBS USA USA", url: "https://thedaddy.to/embed/stream-210.php", category: "Education" },
  { name: "PDC TV", url: "https://thedaddy.to/embed/stream-43.php", category: "Sports" },
  { name: "RTE 1", url: "https://thedaddy.to/embed/stream-364.php", category: "Entertainment" },
  { name: "RTE 2", url: "https://thedaddy.to/embed/stream-365.php", category: "Entertainment" },
  { name: "RMC Sport 1 France", url: "https://thedaddy.to/embed/stream-119.php", category: "Sports" },
  { name: "RMC Sport 2 France", url: "https://thedaddy.to/embed/stream-120.php", category: "Sports" },
  { name: "RTP 1 Portugal", url: "https://thedaddy.to/embed/stream-719.php", category: "Entertainment" },
  { name: "RTP 2 Portugal", url: "https://thedaddy.to/embed/stream-720.php", category: "Entertainment" },
  { name: "RTP 3 Portugal", url: "https://thedaddy.to/embed/stream-721.php", category: "Entertainment" },
  { name: "Rai 1 Italy", url: "https://thedaddy.to/embed/stream-850.php", category: "Entertainment" },
  { name: "Rai 2 Italy", url: "https://thedaddy.to/embed/stream-851.php", category: "Entertainment" },
  { name: "Rai 3 Italy", url: "https://thedaddy.to/embed/stream-852.php", category: "Entertainment" },
  { name: "Rai 3 Italy", url: "https://thedaddy.to/embed/stream-853.php", category: "Entertainment" },
  { name: "Rai Sport Italy", url: "https://thedaddy.to/embed/stream-882.php", category: "Sports" },
  { name: "Rai Premium Italy", url: "https://thedaddy.to/embed/stream-858.php", category: "Entertainment" },
  { name: "Real Madrid TV Spain", url: "https://thedaddy.to/embed/stream-523.php", category: "Sports" },
  { name: "RDS CA", url: "https://thedaddy.to/embed/stream-839.php", category: "Sports" },
  { name: "RDS 2 CA", url: "https://thedaddy.to/embed/stream-840.php", category: "Sports" },
  { name: "RDS Info CA", url: "https://thedaddy.to/embed/stream-841.php", category: "Sports" },
  { name: "Ring Bulgaria", url: "https://thedaddy.to/embed/stream-471.php", category: "Entertainment" },
  { name: "RTL7 Netherland", url: "https://thedaddy.to/embed/stream-390.php", category: "Entertainment" },
  { name: "RTL DE", url: "https://thedaddy.to/embed/stream-740.php", category: "Entertainment" },
  { name: "Racing Tv UK", url: "https://thedaddy.to/embed/stream-555.php", category: "Sports" },
  { name: "Reelz Channel", url: "https://thedaddy.to/embed/stream-293.php", category: "Entertainment" },
  { name: "Root Sports Northwest", url: "https://thedaddy.to/embed/stream-920.php", category: "Sports" },
  { name: "Sky Sports Football UK", url: "https://thedaddy.to/embed/stream-35.php", category: "Sports" },
  { name: "Sky Sports Arena UK", url: "https://thedaddy.to/embed/stream-36.php", category: "Sports" },
  { name: "Sky Sports Action UK", url: "https://thedaddy.to/embed/stream-37.php", category: "Sports" },
  { name: "Sky Sports Main Event", url: "https://thedaddy.to/embed/stream-38.php", category: "Sports" },
  { name: "Sky sports Premier League", url: "https://thedaddy.to/embed/stream-130.php", category: "Sports" },
  { name: "Sky Sports F1 UK", url: "https://thedaddy.to/embed/stream-60.php", category: "Sports" },
  { name: "Sky Sports F1 DE", url: "https://thedaddy.to/embed/stream-274.php", category: "Sports" },
  { name: "Sky Sports Cricket", url: "https://thedaddy.to/embed/stream-65.php", category: "Sports" },
  { name: "Sky Sports Golf UK", url: "https://thedaddy.to/embed/stream-70.php", category: "Sports" },
  { name: "Sky Sports Golf Italy", url: "https://thedaddy.to/embed/stream-574.php", category: "Sports" },
  { name: "Sky Sport MotoGP Italy", url: "https://thedaddy.to/embed/stream-575.php", category: "Sports" },
  { name: "Sky Sport Tennis Italy", url: "https://thedaddy.to/embed/stream-576.php", category: "Sports" },
  { name: "Sky Sports Tennis UK", url: "https://thedaddy.to/embed/stream-46.php", category: "Sports" },
  { name: "Sky Sports Tennis DE", url: "https://thedaddy.to/embed/stream-884.php", category: "Sports" },
  { name: "Sky Sport F1 Italy", url: "https://thedaddy.to/embed/stream-577.php", category: "Sports" },
  { name: "Sky Sports News UK", url: "https://thedaddy.to/embed/stream-366.php", category: "News" },
  { name: "Sky Sports MIX UK", url: "https://thedaddy.to/embed/stream-449.php", category: "Entertainment" },
  { name: "Sky Sport Top Event DE", url: "https://thedaddy.to/embed/stream-556.php", category: "Sports" },
  { name: "Sky Sport Mix DE", url: "https://thedaddy.to/embed/stream-557.php", category: "Sports" },
  { name: "Sky Sport Bundesliga 1 HD", url: "https://thedaddy.to/embed/stream-558.php", category: "Sports" },
  { name: "Sky Sport Austria 1 HD", url: "https://thedaddy.to/embed/stream-559.php", category: "Sports" },
  { name: "SportsNet New York (SNY)", url: "https://thedaddy.to/embed/stream-759.php", category: "Sports" },
  { name: "Sky Sport Football Italy", url: "https://thedaddy.to/embed/stream-460.php", category: "Sports" },
  { name: "Sky Sport UNO Italy", url: "https://thedaddy.to/embed/stream-461.php", category: "Sports" },
  { name: "Sky Sport Arena Italy", url: "https://thedaddy.to/embed/stream-462.php", category: "Sports" },
  { name: "Sky Sports Racing UK", url: "https://thedaddy.to/embed/stream-554.php", category: "Sports" },
  { name: "Sky UNO Italy", url: "https://thedaddy.to/embed/stream-881.php", category: "Sports" },
  { name: "Sky Sport 1 NZ", url: "https://thedaddy.to/embed/stream-588.php", category: "Sports" },
  { name: "Sky Sport 2 NZ", url: "https://thedaddy.to/embed/stream-589.php", category: "Sports" },
  { name: "Sky Sport 3 NZ", url: "https://thedaddy.to/embed/stream-590.php", category: "Sports" },
  { name: "Sky Sport 4 NZ", url: "https://thedaddy.to/embed/stream-591.php", category: "Sports" },
  { name: "Sky Sport 5 NZ", url: "https://thedaddy.to/embed/stream-592.php", category: "Sports" },
  { name: "Sky Sport 6 NZ", url: "https://thedaddy.to/embed/stream-593.php", category: "Sports" },
  { name: "Sky Sport 7 NZ", url: "https://thedaddy.to/embed/stream-594.php", category: "Sports" },
  { name: "Sky Sport 8 NZ", url: "https://thedaddy.to/embed/stream-595.php", category: "Sports" },
  { name: "Sky Sport 9 NZ", url: "https://thedaddy.to/embed/stream-596.php", category: "Sports" },
  { name: "Sky Sport Select NZ", url: "https://thedaddy.to/embed/stream-587.php", category: "Sports" },
  { name: "SBS6 NL", url: "https://thedaddy.to/embed/stream-883.php", category: "Entertainment" },
  { name: "Sport TV1 Portugal", url: "https://thedaddy.to/embed/stream-49.php", category: "Sports" },
  { name: "Sport TV2 Portugal", url: "https://thedaddy.to/embed/stream-74.php", category: "Sports" },
  { name: "Sport TV4 Portugal", url: "https://thedaddy.to/embed/stream-289.php", category: "Sports" },
  { name: "Sport TV3 Portugal", url: "https://thedaddy.to/embed/stream-454.php", category: "Sports" },
  { name: "Sport TV5 Portugal", url: "https://thedaddy.to/embed/stream-290.php", category: "Sports" },
  { name: "Sport TV6 Portugal", url: "https://thedaddy.to/embed/stream-291.php", category: "Sports" },
  { name: "SIC Portugal", url: "https://thedaddy.to/embed/stream-722.php", category: "Entertainment" },
  { name: "SEE Denmark", url: "https://thedaddy.to/embed/stream-811.php", category: "Entertainment" },
  { name: "SEC Network USA", url: "https://thedaddy.to/embed/stream-385.php", category: "Sports" },
  { name: "SporTV Brasil", url: "https://thedaddy.to/embed/stream-78.php", category: "Sports" },
  { name: "SporTV2 Brasil", url: "https://thedaddy.to/embed/stream-79.php", category: "Sports" },
  { name: "SporTV3 Brasil", url: "https://thedaddy.to/embed/stream-80.php", category: "Sports" },
  { name: "Sport Klub 1 Serbia", url: "https://thedaddy.to/embed/stream-101.php", category: "Sports" },
  { name: "Sport Klub 2 Serbia", url: "https://thedaddy.to/embed/stream-102.php", category: "Sports" },
  { name: "Sport Klub 3 Serbia", url: "https://thedaddy.to/embed/stream-103.php", category: "Sports" },
  { name: "Sport Klub 4 Serbia", url: "https://thedaddy.to/embed/stream-104.php", category: "Sports" },
  { name: "Sport Klub HD Serbia", url: "https://thedaddy.to/embed/stream-453.php", category: "Sports" },
  { name: "Sportsnet Ontario", url: "https://thedaddy.to/embed/stream-406.php", category: "Sports" },
  { name: "Sportsnet One", url: "https://thedaddy.to/embed/stream-411.php", category: "Sports" },
  { name: "Sportsnet West", url: "https://thedaddy.to/embed/stream-407.php", category: "Sports" },
  { name: "Sportsnet East", url: "https://thedaddy.to/embed/stream-408.php", category: "Sports" },
  { name: "Sportsnet 360", url: "https://thedaddy.to/embed/stream-409.php", category: "Sports" },
  { name: "Sportsnet World", url: "https://thedaddy.to/embed/stream-410.php", category: "Sports" },
  { name: "SuperSport Grandstand", url: "https://thedaddy.to/embed/stream-412.php", category: "Sports" },
  { name: "SuperSport PSL", url: "https://thedaddy.to/embed/stream-413.php", category: "Sports" },
  { name: "SuperSport Premier league", url: "https://thedaddy.to/embed/stream-414.php", category: "Sports" },
  { name: "SuperSport LaLiga", url: "https://thedaddy.to/embed/stream-415.php", category: "Sports" },
  { name: "SuperSport Variety 1", url: "https://thedaddy.to/embed/stream-416.php", category: "Entertainment" },
  { name: "SuperSport Variety 2", url: "https://thedaddy.to/embed/stream-417.php", category: "Entertainment" },
  { name: "SuperSport Variety 3", url: "https://thedaddy.to/embed/stream-418.php", category: "Entertainment" },
  { name: "SuperSport Variety 4", url: "https://thedaddy.to/embed/stream-419.php", category: "Entertainment" },
  { name: "SuperSport Action", url: "https://thedaddy.to/embed/stream-420.php", category: "Sports" },
  { name: "SuperSport Rugby", url: "https://thedaddy.to/embed/stream-421.php", category: "Sports" },
  { name: "SuperSport Golf", url: "https://thedaddy.to/embed/stream-422.php", category: "Sports" },
  { name: "SuperSport Tennis", url: "https://thedaddy.to/embed/stream-423.php", category: "Sports" },
  { name: "SuperSport Motorsport", url: "https://thedaddy.to/embed/stream-424.php", category: "Sports" },
  { name: "Supersport Football", url: "https://thedaddy.to/embed/stream-56.php", category: "Sports" },
  { name: "SuperSport Cricket", url: "https://thedaddy.to/embed/stream-368.php", category: "Sports" },
  { name: "SuperSport MaXimo 1", url: "https://thedaddy.to/embed/stream-572.php", category: "Sports" },
  { name: "Sporting TV Portugal", url: "https://thedaddy.to/embed/stream-716.php", category: "Sports" },
  { name: "SportDigital Fussball", url: "https://thedaddy.to/embed/stream-571.php", category: "Sports" },
  { name: "Spectrum Sportsnet LA", url: "https://thedaddy.to/embed/stream-764.php", category: "Sports" },
  { name: "Sport1+ Germany", url: "https://thedaddy.to/embed/stream-640.php", category: "Sports" },
  { name: "Sport1 Germany", url: "https://thedaddy.to/embed/stream-641.php", category: "Sports" },
  { name: "S4C UK", url: "https://thedaddy.to/embed/stream-670.php", category: "Entertainment" },
  { name: "SAT.1 DE", url: "https://thedaddy.to/embed/stream-729.php", category: "Entertainment" },
  { name: "Sky Cinema Premiere UK", url: "https://thedaddy.to/embed/stream-671.php", category: "Entertainment" },
  { name: "Sky Cinema Select UK", url: "https://thedaddy.to/embed/stream-672.php", category: "Entertainment" },
  { name: "Sky Cinema Hits UK", url: "https://thedaddy.to/embed/stream-673.php", category: "Entertainment" },
  { name: "Sky Cinema Greats UK", url: "https://thedaddy.to/embed/stream-674.php", category: "Entertainment" },
  { name: "Sky Cinema Animation UK", url: "https://thedaddy.to/embed/stream-675.php", category: "Entertainment" },
  { name: "Sky Cinema Family UK", url: "https://thedaddy.to/embed/stream-676.php", category: "Entertainment" },
  { name: "Sky Cinema Action UK", url: "https://thedaddy.to/embed/stream-677.php", category: "Entertainment" },
  { name: "Sky Cinema Comedy UK", url: "https://thedaddy.to/embed/stream-678.php", category: "Entertainment" },
  { name: "Sky Cinema Thriller UK", url: "https://thedaddy.to/embed/stream-679.php", category: "Entertainment" },
  { name: "Sky Cinema Drama UK", url: "https://thedaddy.to/embed/stream-680.php", category: "Entertainment" },
  { name: "Sky Cinema Sci-Fi Horror UK", url: "https://thedaddy.to/embed/stream-681.php", category: "Entertainment" },
  { name: "Sky Cinema Collection Italy", url: "https://thedaddy.to/embed/stream-859.php", category: "Movies" },
  { name: "Sky Cinema Uno Italy", url: "https://thedaddy.to/embed/stream-860.php", category: "Entertainment" },
  { name: "Sky Cinema Action Italy", url: "https://thedaddy.to/embed/stream-861.php", category: "Entertainment" },
  { name: "8Sky Cinema Comedy Italy", url: "https://thedaddy.to/embed/stream-862.php", category: "Entertainment" },
  { name: "Sky Cinema Uno +24 Italy", url: "https://thedaddy.to/embed/stream-863.php", category: "Entertainment" },
  { name: "Sky Cinema Romance Italy", url: "https://thedaddy.to/embed/stream-864.php", category: "Entertainment" },
  { name: "Sky Cinema Family Italy", url: "https://thedaddy.to/embed/stream-865.php", category: "Entertainment" },
  { name: "Sky Cinema Due +24 Italy", url: "https://thedaddy.to/embed/stream-866.php", category: "Entertainment" },
  { name: "Sky Cinema Drama Italy", url: "https://thedaddy.to/embed/stream-867.php", category: "Entertainment" },
  { name: "8Sky Cinema Suspense Italy", url: "https://thedaddy.to/embed/stream-868.php", category: "Entertainment" },
  { name: "Sky Sport 24 Italy", url: "https://thedaddy.to/embed/stream-869.php", category: "Sports" },
  { name: "Sky Sport Calcio Italy", url: "https://thedaddy.to/embed/stream-870.php", category: "Sports" },
  { name: "Sky Calcio 1 (251) Italy", url: "https://thedaddy.to/embed/stream-871.php", category: "Sports" },
  { name: "Sky Calcio 2 (252) Italy", url: "https://thedaddy.to/embed/stream-872.php", category: "Sports" },
  { name: "Sky Calcio 3 (253) Italy", url: "https://thedaddy.to/embed/stream-873.php", category: "Sports" },
  { name: "Sky Calcio 4 (254) Italy", url: "https://thedaddy.to/embed/stream-874.php", category: "Sports" },
  { name: "Sky Calcio 5 (255) Italy", url: "https://thedaddy.to/embed/stream-875.php", category: "Sports" },
  { name: "Sky Calcio 6 (256) Italy", url: "https://thedaddy.to/embed/stream-876.php", category: "Sports" },
  { name: "Sky Calcio 7 (257) Italy", url: "https://thedaddy.to/embed/stream-877.php", category: "Sports" },
  { name: "Sky Serie Italy", url: "https://thedaddy.to/embed/stream-880.php", category: "Entertainment" },
  { name: "StarzPlay CricLife 1 HD", url: "https://thedaddy.to/embed/stream-284.php", category: "Sports" },
  { name: "StarzPlay CricLife 2 HD", url: "https://thedaddy.to/embed/stream-283.php", category: "Sports" },
  { name: "StarzPlay CricLife 3 HD", url: "https://thedaddy.to/embed/stream-282.php", category: "Sports" },
  { name: "Sky Showcase UK", url: "https://thedaddy.to/embed/stream-682.php", category: "Entertainment" },
  { name: "Sky Arts UK", url: "https://thedaddy.to/embed/stream-683.php", category: "Entertainment" },
  { name: "Sky Comedy UK", url: "https://thedaddy.to/embed/stream-684.php", category: "Entertainment" },
  { name: "Sky Crime", url: "https://thedaddy.to/embed/stream-685.php", category: "Entertainment" },
  { name: "Sky History", url: "https://thedaddy.to/embed/stream-686.php", category: "Entertainment" },
  { name: "SSC Sport 1", url: "https://thedaddy.to/embed/stream-614.php", category: "Sports" },
  { name: "SSC Sport 2", url: "https://thedaddy.to/embed/stream-615.php", category: "Sports" },
  { name: "SSC Sport 3", url: "https://thedaddy.to/embed/stream-616.php", category: "Sports" },
  { name: "SSC Sport 4", url: "https://thedaddy.to/embed/stream-617.php", category: "Sports" },
  { name: "SSC Sport 5", url: "https://thedaddy.to/embed/stream-618.php", category: "Sports" },
  { name: "SSC Sport Extra 1", url: "https://thedaddy.to/embed/stream-619.php", category: "Sports" },
  { name: "SSC Sport Extra 2", url: "https://thedaddy.to/embed/stream-620.php", category: "Sports" },
  { name: "SSC Sport Extra 3", url: "https://thedaddy.to/embed/stream-621.php", category: "Sports" },
  { name: "Sport 1 Israel", url: "https://thedaddy.to/embed/stream-140.php", category: "Sports" },
  { name: "Sport 2 Israel", url: "https://thedaddy.to/embed/stream-141.php", category: "Sports" },
  { name: "Sport 3 Israel", url: "https://thedaddy.to/embed/stream-142.php", category: "Sports" },
  { name: "Sport 4 Israel", url: "https://thedaddy.to/embed/stream-143.php", category: "Sports" },
  { name: "Sport 5 Israel", url: "https://thedaddy.to/embed/stream-144.php", category: "Sports" },
  { name: "Sport 5 PLUS Israel", url: "https://thedaddy.to/embed/stream-145.php", category: "Sports" },
  { name: "Sport 5 Live Israel", url: "https://thedaddy.to/embed/stream-146.php", category: "Sports" },
  { name: "Sport 5 Star Israel", url: "https://thedaddy.to/embed/stream-147.php", category: "Sports" },
  { name: "Sport 5 Gold Israel", url: "https://thedaddy.to/embed/stream-148.php", category: "Sports" },
  { name: "Science Channel", url: "https://thedaddy.to/embed/stream-294.php", category: "Documentary" },
  { name: "Showtime USA", url: "https://thedaddy.to/embed/stream-333.php", category: "Entertainment" },
  { name: "Showtime SHOxBET USA", url: "https://thedaddy.to/embed/stream-685.php", category: "Entertainment" },
  { name: "Showtime 2 USA (SHO2) USA", url: "https://thedaddy.to/embed/stream-792.php", category: "Entertainment" },
  { name: "Showtime Showcase USA", url: "https://thedaddy.to/embed/stream-793.php", category: "Entertainment" },
  { name: "Showtime Extreme USA", url: "https://thedaddy.to/embed/stream-794.php", category: "Entertainment" },
  { name: "Showtime Family Zone (SHO Family Zone) USA", url: "https://thedaddy.to/embed/stream-795.php", category: "Entertainment" },
  { name: "Showtime Next (SHO Next) USA", url: "https://thedaddy.to/embed/stream-796.php", category: "Entertainment" },
  { name: "Showtime Women USA", url: "https://thedaddy.to/embed/stream-797.php", category: "Entertainment" },
  { name: "Starz", url: "https://thedaddy.to/embed/stream-335.php", category: "Entertainment" },
  { name: "Sky Witness HD", url: "https://thedaddy.to/embed/stream-361.php", category: "Entertainment" },
  { name: "Sixx DE", url: "https://thedaddy.to/embed/stream-732.php", category: "Entertainment" },
  { name: "Sky Atlantic", url: "https://thedaddy.to/embed/stream-362.php", category: "Entertainment" },
  { name: "SYFY USA", url: "https://thedaddy.to/embed/stream-373.php", category: "Entertainment" },
  { name: "Sundance TV", url: "https://thedaddy.to/embed/stream-658.php", category: "Entertainment" },
  { name: "SWR DE", url: "https://thedaddy.to/embed/stream-735.php", category: "Entertainment" },
  { name: "SUPER RTL DE", url: "https://thedaddy.to/embed/stream-738.php", category: "Entertainment" },
  { name: "SR Fernsehen DE", url: "https://thedaddy.to/embed/stream-739.php", category: "Entertainment" },
  { name: "Smithsonian Channel", url: "https://thedaddy.to/embed/stream-601.php", category: "Documentary" },
  { name: "Space City Home Network", url: "https://thedaddy.to/embed/stream-921.php", category: "Entertainment" },
  { name: "SportsNet Pittsburgh", url: "https://thedaddy.to/embed/stream-922.php", category: "Sports" },
  { name: "TNT Sports 1 UK", url: "https://thedaddy.to/embed/stream-31.php", category: "Sports" },
  { name: "TNT Sports 2 UK", url: "https://thedaddy.to/embed/stream-32.php", category: "Sports" },
  { name: "TNT Sports 3 UK", url: "https://thedaddy.to/embed/stream-33.php", category: "Sports" },
  { name: "TNT Sports 4 UK", url: "https://thedaddy.to/embed/stream-34.php", category: "Sports" },
  { name: "TF1 France", url: "https://thedaddy.to/embed/stream-469.php", category: "Entertainment" },
  { name: "TSN1", url: "https://thedaddy.to/embed/stream-111.php", category: "Sports" },
  { name: "TSN2", url: "https://thedaddy.to/embed/stream-112.php", category: "Sports" },
  { name: "TSN3", url: "https://thedaddy.to/embed/stream-113.php", category: "Sports" },
  { name: "TSN4", url: "https://thedaddy.to/embed/stream-114.php", category: "Sports" },
  { name: "TSN5", url: "https://thedaddy.to/embed/stream-115.php", category: "Sports" },
  { name: "TVN HD Poland", url: "https://thedaddy.to/embed/stream-565.php", category: "Entertainment" },
  { name: "TVN24 Poland", url: "https://thedaddy.to/embed/stream-444.php", category: "News" },
  { name: "TVP1 Poland", url: "https://thedaddy.to/embed/stream-560.php", category: "Entertainment" },
  { name: "TVP2 Poland", url: "https://thedaddy.to/embed/stream-561.php", category: "Entertainment" },
  { name: "Telecinco Spain", url: "https://thedaddy.to/embed/stream-532.php", category: "Entertainment" },
  { name: "TVE La 1 Spain", url: "https://thedaddy.to/embed/stream-533.php", category: "Entertainment" },
  { name: "TVE La 2 Spain", url: "https://thedaddy.to/embed/stream-536.php", category: "Entertainment" },
  { name: "TVI Portugal", url: "https://thedaddy.to/embed/stream-723.php", category: "Entertainment" },
  { name: "TVI Reality Portugal", url: "https://thedaddy.to/embed/stream-724.php", category: "Entertainment" },
  { name: "Teledeporte Spain (TDP)", url: "https://thedaddy.to/embed/stream-529.php", category: "Sports" },
  { name: "TYC Sports Argentina", url: "https://thedaddy.to/embed/stream-746.php", category: "Sports" },
  { name: "TVP Sport Poland", url: "https://thedaddy.to/embed/stream-128.php", category: "Sports" },
  { name: "TNT Brasil", url: "https://thedaddy.to/embed/stream-87.php", category: "Entertainment" },
  { name: "TNT Sports Argentina", url: "https://thedaddy.to/embed/stream-388.php", category: "Sports" },
  { name: "TNT Sports HD Chile", url: "https://thedaddy.to/embed/stream-642.php", category: "Sports" },
  { name: "Tennis Channel", url: "https://thedaddy.to/embed/stream-40.php", category: "Sports" },
  { name: "Ten Sports PK", url: "https://thedaddy.to/embed/stream-741.php", category: "Sports" },
  { name: "TUDN USA", url: "https://thedaddy.to/embed/stream-66.php", category: "Sports" },
  { name: "Telemundo", url: "https://thedaddy.to/embed/stream-131.php", category: "Entertainment" },
  { name: "TBS USA", url: "https://thedaddy.to/embed/stream-336.php", category: "Entertainment" },
  { name: "TLC", url: "https://thedaddy.to/embed/stream-337.php", category: "Entertainment" },
  { name: "TNT USA", url: "https://thedaddy.to/embed/stream-338.php", category: "Entertainment" },
  { name: "TVA Sports", url: "https://thedaddy.to/embed/stream-833.php", category: "Sports" },
  { name: "TVA Sports 2", url: "https://thedaddy.to/embed/stream-834.php", category: "Sports" },
  { name: "Travel Channel", url: "https://thedaddy.to/embed/stream-340.php", category: "Entertainment" },
  { name: "TruTV USA", url: "https://thedaddy.to/embed/stream-341.php", category: "Entertainment" },
  { name: "TVLAND", url: "https://thedaddy.to/embed/stream-342.php", category: "Entertainment" },
  { name: "TCM USA", url: "https://thedaddy.to/embed/stream-644.php", category: "Movies" },
  { name: "TMC Channel USA", url: "https://thedaddy.to/embed/stream-698.php", category: "Entertainment" },
  { name: "The Food Network", url: "https://thedaddy.to/embed/stream-384.php", category: "Lifestyle" },
  { name: "The Weather Channel", url: "https://thedaddy.to/embed/stream-394.php", category: "News" },
  { name: "TVP INFO", url: "https://thedaddy.to/embed/stream-452.php", category: "News" },
  { name: "TeenNick", url: "https://thedaddy.to/embed/stream-650.php", category: "Kids" },
  { name: "TV ONE USA", url: "https://thedaddy.to/embed/stream-660.php", category: "Entertainment" },
  { name: "TV2 Bornholm Denmark", url: "https://thedaddy.to/embed/stream-807.php", category: "Entertainment" },
  { name: "TV2 Sport X Denmark", url: "https://thedaddy.to/embed/stream-808.php", category: "Sports" },
  { name: "TV3 Sport Denmark", url: "https://thedaddy.to/embed/stream-809.php", category: "Sports" },
  { name: "TV2 Sport Denmark", url: "https://thedaddy.to/embed/stream-810.php", category: "Sports" },
  { name: "TV2 Denmark", url: "https://thedaddy.to/embed/stream-817.php", category: "Entertainment" },
  { name: "TV2 Zulu", url: "https://thedaddy.to/embed/stream-818.php", category: "Entertainment" },
  { name: "TV3+ Denmark", url: "https://thedaddy.to/embed/stream-819.php", category: "Entertainment" },
  { name: "TV3 Max Denmark", url: "https://thedaddy.to/embed/stream-223.php", category: "Entertainment" },
  { name: "TVO CA", url: "https://thedaddy.to/embed/stream-842.php", category: "Entertainment" },
  { name: "T Sports BD", url: "https://thedaddy.to/embed/stream-270.php", category: "Sports" },
  { name: "Tennis+ 1", url: "https://thedaddy.to/embed/stream-700.php", category: "Sports" },
  { name: "Tennis+ 2", url: "https://thedaddy.to/embed/stream-701.php", category: "Sports" },
  { name: "Tennis+ 3", url: "https://thedaddy.to/embed/stream-702.php", category: "Sports" },
  { name: "Tennis+ 4", url: "https://thedaddy.to/embed/stream-703.php", category: "Sports" },
  { name: "Tennis+ 5", url: "https://thedaddy.to/embed/stream-704.php", category: "Sports" },
  { name: "Tennis+ 6", url: "https://thedaddy.to/embed/stream-705.php", category: "Sports" },
  { name: "Tennis+ 7", url: "https://thedaddy.to/embed/stream-706.php", category: "Sports" },
  { name: "Tennis+ 8", url: "https://thedaddy.to/embed/stream-707.php", category: "Sports" },
  { name: "Tennis+ 9", url: "https://thedaddy.to/embed/stream-708.php", category: "Sports" },
  { name: "Tennis+ 10", url: "https://thedaddy.to/embed/stream-709.php", category: "Sports" },
  { name: "Tennis+ 11", url: "https://thedaddy.to/embed/stream-710.php", category: "Sports" },
  { name: "Tennis+ 12", url: "https://thedaddy.to/embed/stream-711.php", category: "Sports" },
  { name: "Tennis+ 13", url: "https://thedaddy.to/embed/stream-712.php", category: "Sports" },
  { name: "Tennis+ 14", url: "https://thedaddy.to/embed/stream-713.php", category: "Sports" },
  { name: "Tennis+ 15", url: "https://thedaddy.to/embed/stream-714.php", category: "Sports" },
  { name: "USA Network", url: "https://thedaddy.to/embed/stream-343.php", category: "Entertainment" },
  { name: "Universal Kids USA", url: "https://thedaddy.to/embed/stream-668.php", category: "Kids" },
  { name: "Univision", url: "https://thedaddy.to/embed/stream-132.php", category: "Entertainment" },
  { name: "Unimas", url: "https://thedaddy.to/embed/stream-133.php", category: "Entertainment" },
  { name: "Viaplay Sports 1 UK", url: "https://thedaddy.to/embed/stream-451.php", category: "Sports" },
  { name: "Viaplay Sports 2 UK", url: "https://thedaddy.to/embed/stream-550.php", category: "Sports" },
  { name: "Viaplay Xtra UK", url: "https://thedaddy.to/embed/stream-597.php", category: "Sports" },
  { name: "Vodafone Sport", url: "https://thedaddy.to/embed/stream-260.php", category: "Sports" },
  { name: "#Vamos Spain", url: "https://thedaddy.to/embed/stream-521.php", category: "Entertainment" },
  { name: "V Sport Motor Sweden", url: "https://thedaddy.to/embed/stream-272.php", category: "Sports" },
  { name: "V Film Premiere", url: "https://thedaddy.to/embed/stream-815.php", category: "Movies" },
  { name: "V Film Family", url: "https://thedaddy.to/embed/stream-816.php", category: "Movies" },
  { name: "VH1 USA", url: "https://thedaddy.to/embed/stream-344.php", category: "Entertainment" },
  { name: "Veronica NL Netherland", url: "https://thedaddy.to/embed/stream-378.php", category: "Entertainment" },
  { name: "VTV+ Uruguay", url: "https://thedaddy.to/embed/stream-391.php", category: "Entertainment" },
  { name: "VICE TV", url: "https://thedaddy.to/embed/stream-659.php", category: "Entertainment" },
  { name: "Willow Cricket", url: "https://thedaddy.to/embed/stream-346.php", category: "Sports" },
  { name: "Willow XTRA", url: "https://thedaddy.to/embed/stream-598.php", category: "Sports" },
  { name: "WWE Network", url: "https://thedaddy.to/embed/stream-376.php", category: "Sports" },
  { name: "Win Sports+ Columbia", url: "https://thedaddy.to/embed/stream-392.php", category: "Sports" },
  { name: "WETV USA", url: "https://thedaddy.to/embed/stream-655.php", category: "Entertainment" },
  { name: "WDR DE", url: "https://thedaddy.to/embed/stream-734.php", category: "Entertainment" },
  { name: "YES Network USA", url: "https://thedaddy.to/embed/stream-763.php", category: "Sports" },
  { name: "Yes Movies Action Israel", url: "https://thedaddy.to/embed/stream-543.php", category: "Movies" },
  { name: "Yes Movies Kids Israel", url: "https://thedaddy.to/embed/stream-544.php", category: "Kids" },
  { name: "Yes Movies Comedy Israel", url: "https://thedaddy.to/embed/stream-545.php", category: "Entertainment" },
  { name: "Yas TV UAE", url: "https://thedaddy.to/embed/stream-609.php", category: "Entertainment" },
  { name: "Yes TV CA", url: "https://thedaddy.to/embed/stream-837.php", category: "Entertainment" },
  { name: "YTV CA", url: "https://thedaddy.to/embed/stream-296.php", category: "Kids" },
  { name: "Ziggo Sport Docu NL", url: "https://thedaddy.to/embed/stream-383.php", category: "Documentary" },
  { name: "Ziggo Sport Select NL", url: "https://thedaddy.to/embed/stream-393.php", category: "Sports" },
  { name: "Ziggo Sport Racing NL", url: "https://thedaddy.to/embed/stream-396.php", category: "Sports" },
  { name: "Ziggo Sport Voetbal NL", url: "https://thedaddy.to/embed/stream-398.php", category: "Sports" },
  { name: "BBC 1 DE", url: "https://thedaddy.to/embed/stream-727.php", category: "Entertainment" },
  { name: "ZDF Info DE", url: "https://thedaddy.to/embed/stream-728.php", category: "News" },
  { name: "20 Mediaset Italy", url: "https://thedaddy.to/embed/stream-857.php", category: "Entertainment" },
  { name: "6'eren Denmark", url: "https://thedaddy.to/embed/stream-800.php", category: "Entertainment" },
  { name: "#0 Spain", url: "https://thedaddy.to/embed/stream-437.php", category: "Entertainment" },
  { name: "5 USA", url: "https://thedaddy.to/embed/stream-360.php", category: "Entertainment" },
  { name: "3sat DE", url: "https://thedaddy.to/embed/stream-726.php", category: "Entertainment" },
  { name: "18+ (Player-01)", url: "https://thedaddy.to/embed/stream-501.php", category: "Adult" },
  { name: "18+ (Player-02)", url: "https://thedaddy.to/embed/stream-502.php", category: "Adult" },
  { name: "18+ (Player-03)", url: "https://thedaddy.to/embed/stream-503.php", category: "Adult" },
  { name: "18+ (Player-04)", url: "https://thedaddy.to/embed/stream-504.php", category: "Adult" },
  { name: "18+ (Player-05)", url: "https://thedaddy.to/embed/stream-505.php", category: "Adult" },
  { name: "18+ (Player-06)", url: "https://thedaddy.to/embed/stream-506.php", category: "Adult" },
  { name: "18+ (Player-07)", url: "https://thedaddy.to/embed/stream-507.php", category: "Adult" },
  { name: "18+ (Player-08)", url: "https://thedaddy.to/embed/stream-508.php", category: "Adult" },
  { name: "18+ (Player-09)", url: "https://thedaddy.to/embed/stream-509.php", category: "Adult" },
  { name: "18+ (Player-10)", url: "https://thedaddy.to/embed/stream-510.php", category: "Adult" },
  { name: "18+ (Player-11)", url: "https://thedaddy.to/embed/stream-511.php", category: "Adult" },
  { name: "18+ (Player-12)", url: "https://thedaddy.to/embed/stream-512.php", category: "Adult" },
  { name: "18+ (Player-13)", url: "https://thedaddy.to/embed/stream-513.php", category: "Adult" },
  { name: "18+ (Player-14)", url: "https://thedaddy.to/embed/stream-514.php", category: "Adult" },
  { name: "18+ (Player-15)", url: "https://thedaddy.to/embed/stream-515.php", category: "Adult" },
  { name: "18+ (Player-16)", url: "https://thedaddy.to/embed/stream-516.php", category: "Adult" },
  { name: "18+ (Player-17)", url: "https://thedaddy.to/embed/stream-517.php", category: "Adult" },
  { name: "18+ (Player-18)", url: "https://thedaddy.to/embed/stream-518.php", category: "Adult" },
  { name: "18+ (Player-19)", url: "https://thedaddy.to/embed/stream-519.php", category: "Adult" },
  { name: "18+ (Player-20)", url: "https://thedaddy.to/embed/stream-520.php", category: "Adult" }
],

    };
  },
  computed: {
    channels() {
      return this.rawChannels.map(channel => {
        const parts = channel.url.split("/");
        const fileName = parts[parts.length - 1];
        return {
          ...channel,
          category: channel.category || "Other",
          backup1: `https://d.daddylivehd.sx/embed/${fileName}`,
          backup2: `https://d.daddylivehd.sx/embed/${fileName}`,
          backup3: `https://d.daddylivehd.sx/embed/${fileName}`,
          backup4: `https://d.daddylivehd.sx/embed/${fileName}`,
        };
      });
    },
    filteredChannels() {
      return this.channels.filter(ch =>
        ch.name.toLowerCase().includes(this.search.toLowerCase()) &&
        (this.selectedCategory === "All" || ch.category === this.selectedCategory)
      );
    },
    activeUrl() {
      if (!this.selectedChannel) return "";
      switch (this.activeLinkIndex) {
        case 0: return this.selectedChannel.url;
        case 1: return this.selectedChannel.backup1;
        case 2: return this.selectedChannel.backup2;
        case 3: return this.selectedChannel.backup3;
        case 4: return this.selectedChannel.backup4;
        default: return this.selectedChannel.url;
      }
    },
  },
  methods: {
    selectChannel(channel) {
      this.selectedChannel = channel;
      this.activeLinkIndex = 0;
    },
  },
  mounted() {
    if (this.channels.length > 0) {
      this.selectChannel(this.channels[0]);
    }
  },
};
</script>

<style scoped>
.dark-app {
  background: linear-gradient(135deg, #000 0%, #1a1a1a 100%);
  color: #fff;
  min-height: 100vh;
}

/* Nav Drawer styling */
.sidebar-drawer {
  background-color: #101010;
  margin-left: 8%;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.5);
}

/* Toolbar Search Field styling */
.search-input .v-input__control {
  background-color: #000;
  border: 1px solid #0CDFE5;
  color: #fff;
  border-radius: 4px;
}
.search-input input {
  color: #fff;
}
.search-input .v-label {
  color: #0CDFE5;
}@media (max-width: 600px) {
  .sidebar-drawer {
    width: 60% !important;
    margin-left: 0 !important;
  }
}

/* Category Filter Buttons */
.category-buttons {
  display: flex;
  gap: 6px;
  padding: 8px 12px;
  flex-wrap: wrap;
}
.category-buttons .v-btn {
  font-size: 0.8rem;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}
.category-buttons .activeCategory {
  border-color: #0CDFE5;
  color: #0CDFE5;
}

/* Channel List styling */
.channel-list-container {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  padding-right: 4px;
}
.channel-list-container::-webkit-scrollbar {
  display: none;
}
.channel-list-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.channel-list {
  background-color: transparent;
  margin-top: 16px;
}
.channel-list-item {
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.2s ease;
  padding: 6px 10px;
  border-radius: 4px;
  margin-bottom: 4px;
}
.channel-list-item:hover {
  background-color: #222;
  transform: scale(1.02);
}
.activeChannel {
  color: #0CDFE5 !important;
  font-weight: bold;
}

/* Main Content styling */
.main-content-col {
  padding: 16px;
  display: flex;
  flex-direction: column;
}

/* Player Area styling */
.player-container {
  position: relative;
  flex: 1;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  background-color: #000;
}
.player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.player-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

/* Player Info Overlay */
.player-info-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 280px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 12px;
  border-top-right-radius: 8px;
}
.channel-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 4px;
  color: #0CDFE5;
}
.program-title {
  font-size: 1rem;
  margin-bottom: 2px;
}
.program-subtitle {
  font-size: 0.9rem;
  color: #ccc;
}
.no-channel-selected {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-style: italic;
}
.link-buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
}
.v-btn {
  color: #fff;
  transition: color 0.2s ease;
}
.activeLink {
  color: #0CDFE5 !important;
  font-weight: bold;
  text-decoration: underline;
}
</style>
