<template>
    <v-container fluid>
      <h3 style="margin-left:5% !important" class="styled-heading">Explore by Categories</h3>
      <v-slide-group dark show-arrows>
        <v-slide-item
          v-for="(category, index) in categories"
          :key="index"
        >
          <v-card
            class="category-card"
            :style="{ backgroundImage: `url(${category.imageUrl})` }"
            @click="selectCategory(category)"
          >
            <div class="overlay">
              <span class="category-name">{{ category.name }}</span>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'CategoryCards',
    data() {
      return {
        categories: [
          { name: "Action", imageUrl: "https://wallpapers.com/images/high/action-movie-1920-x-1080-wallpaper-fls2s9dt31di4u3w.webp", id: 28 },
          { name: "Comedy", imageUrl: "https://c4.wallpaperflare.com/wallpaper/303/951/603/comedy-how-i-met-your-mother-met-mother-wallpaper-preview.jpg", id: 35 },
          { name: "Drama", imageUrl: "https://c4.wallpaperflare.com/wallpaper/298/840/54/battle-blood-crime-dark-wallpaper-preview.jpg", id: 18 },
          { name: "Animation", imageUrl: "https://c4.wallpaperflare.com/wallpaper/673/107/786/up-movie-pixar-animation-studios-movies-sky-wallpaper-preview.jpg", id: 16 },
          { name: "Family", imageUrl: "https://c4.wallpaperflare.com/wallpaper/328/563/763/tv-show-modern-family-wallpaper-preview.jpg", id:  10751 },
          { name: "Romance", imageUrl: "https://c4.wallpaperflare.com/wallpaper/725/522/574/silhouettes-couple-guitar-sunset-wallpaper-preview.jpg", id: 10749 },
        ],
      };
    },
    methods: {
      selectCategory(category) {
        // Set the selected genre in localStorage
        localStorage.setItem('selectedGenre', JSON.stringify({
          id: category.id,
          name: category.name
        }));
  
        // Navigate to the /search route
        this.$router.push('/search');
      },
    },
  };
  </script>
  
  <style scoped>
  .category-card {
    width: 300px;
    height: 180px;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
    margin: 10px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  .category-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
    color: white;
    padding: 10px;
    text-align: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .styled-heading {
  position: relative;
  color: white;
  font-weight: 600;
  margin-left: 10px !important;
  padding-left: 5%;
}
.styled-heading::before {
  content: "";
  position: absolute;
  left: 4%;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #0CDFE5;
}
  
  .category-name {
    font-size: 18px;
    font-weight: bold;
  }
  </style>
  