import { render, staticRenderFns } from "./Viewinfos.vue?vue&type=template&id=8c31e0a8&scoped=true"
import script from "./Viewinfos.vue?vue&type=script&lang=js"
export * from "./Viewinfos.vue?vue&type=script&lang=js"
import style0 from "./Viewinfos.vue?vue&type=style&index=0&id=8c31e0a8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c31e0a8",
  null
  
)

export default component.exports